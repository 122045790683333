import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';

class DatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),

  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    if (this.props.minDate || this.props.currentDate) {
      let date = this.props.currentDate ? this.props.currentDate : this.props.minDate;
      this.handleChange(date);
    }
  }

  handleChange(date) {
    const { onChange } = this.props.input;
    if (date) {
      this.setState({
        startDate: moment(date).toDate(),
      });
      onChange(moment(date).format('YYYY-MM-DD'));
    }
  }


  render() {
    const { startDate } = this.state;
    const { meta, } = this.props;
    const { name, onChange, value } = this.props.input;
    if ((name === 'startDate') && this.props.minDate && !(startDate && value)) {
      onChange(moment(this.props.minDate).format('YYYY-MM-DD'));
    }

    const date = startDate ? startDate : value ? moment(value).toDate() : name === 'endDate' ? null : this.props.minDate ? this.props.minDate : null;

    return (
      <div className="date-picker ">
        <DatePicker
          className="form__form-group-datepicker "
          selected={date}
          onChange={this.handleChange}
          dateFormat="dd-MM-yyyy"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={20}
          dropDownMode="select"
          disabled={this.props.disabled}
          maxDate={this.props.maxDate}
          minDate={this.props.minDate}
          ref={'fromdate'}
          customInput={(<CalendarIcon />)}
        />
        {/* <div className="iconstyle form__form-group-icon" >
          <CalendarBlankIcon htmlFor='fromdate' />
        </div> */}
        {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
      </div>
    );
  }
}

class CalendarIcon extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string
  }
  render() {
    const { value, onClick } = this.props;

    return (
      <div className="form__form-group">
        <div className="form__form-group-field">
          <input type="text" value={value} onClick={onClick}
          />
          <div className="iconstyle form__form-group-icon">
            <CalendarBlankIcon className='calendarIcon' onClick={onClick}></CalendarBlankIcon>
          </div>

        </div>
      </div>
    );
  }
}



const renderDatePickerField = (props) => {
  const { input } = props;
  return <DatePickerField  {...input} {...props} />;
};

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default renderDatePickerField;
