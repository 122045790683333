import React from "react";
import TableRender from '../../Tables/CommonDataTable/TableRender';

class MatchedPropsTable extends React.Component {

    getTableFields = async () => {
        let data = [
            { textAlign: 'center', width: 7, selectionMode: 'multiple', field: 'Sno' },
            { textAlign: 'center', width: 7, field: 'Sno', header: 'sno' },
            { textAlign: 'center', width: 17, field: 'criteria', header: 'criteria',showInMoblie: true , fieldType : 'Object' , objectKey : 'searchName' },
            { textAlign: 'center', width: 8, field: 'result', header: 'result',showInMoblie: true ,fieldType : 'Array' },
            { textAlign: 'center', width: 17, field: 'clientName', header: 'clientName',showInMoblie: true },
            { textAlign: 'center', width: 14, field: 'created', header: 'createdOn', showInMoblie: true , fieldType : 'Date' },
            { textAlign: 'center', width: 10, field: 'Actions', header: 'actions', },
        ];
        return data;
    };

    render() {
        const { tl } = this.props;
        return (
            <TableRender
                type='savedSearches'
                tableFields={() => this.getTableFields()}
                tl={tl}
            />
        );
    }
}
export default (MatchedPropsTable);