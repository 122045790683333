import React, { PureComponent } from 'react';
import LogInForm from './components/LogInForm';
import config from '../../config/config';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

class LogIn extends PureComponent {

  componentDidMount = async () => {
    // Clearing LocalStorage
    localStorage.removeItem('myPropsSelected')
    localStorage.removeItem('searchPropsSelected')
    localStorage.removeItem('matchedPropsSelected')

    localStorage.removeItem('myIsGlobalChecked')
    localStorage.removeItem('searchIsGlobalChecked')
    localStorage.removeItem('matchedIsGlobalChecked')

    localStorage.removeItem('myPropsDeselected');
    localStorage.removeItem('searchPropsDeselected');
    localStorage.removeItem('matchedPropsDeselected');

    localStorage.removeItem('myClientCriteria')
    localStorage.removeItem('myFilterCriteria')
    localStorage.removeItem('fromScreen')
    localStorage.removeItem('loginCredentials')
  }

  render() {
    const { t, tl } = this.props;
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="text-center mb-2">
              <h3 className="account__title">
                {tl && tl['welcomeTo'] ? tl['welcomeTo'] + ' ' : 'Welcome to '}
                <span className="account__logo">
                  <span className="account__logo-accent">
                    {t(config.projectName) ? t(config.projectName.toUpperCase()) : 'Project Name'}
                  </span>
                </span>
              </h3>
            </div>
            <LogInForm onSubmit />
          </div>
        </div>
      </div >
    )
  }
}

export default withTranslation('common')(LogIn);
