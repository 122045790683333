import React from 'react';
import {
    Badge, Button, Col, Row, ButtonToolbar,
    CardBody,
    CardHeader,
    Card,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

// Loader
import Loader from '../../App/Loader';

//modals
import FormModal from '../../Form/FormModal';
import ConfirmActionModal from './ConfirmActionModal';
import ViewPhotosModal from './ViewPhotosModal';
import ClientsFormModal from '../../Clients/components/ClientsFormModal';
import ShareLimitModal from '../../Properties/components/ShareLimitModal';
import SharePropsModal from '../../Properties/components/SharePropsModal';
import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

// fetch method for server call
import fetch from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';

// toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Format date
import dateFormats from '../../UI/FormatDate/formatDate';

//Render Date picker
import AspectRatio from 'mdi-react/AspectRatioIcon'
import StarIcon from 'mdi-react/StarIcon';
import NotStarIcon from 'mdi-react/StarOutlineIcon'

// i18 Translation Provider
import { withTranslation } from 'react-i18next';
import Scrollbar from 'react-smooth-scrollbar';

//input 
import CheckBox from '../../../shared/components/form/CheckBox';

//icons
import ShareIcon from 'mdi-react/ShareVariantIcon';


//tooltip styles
const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: config.themeColor,
        color: config.themeWhite,
        boxShadow: theme.shadows[10],
        fontSize: 13,
        fontWeight: '400',
        // whiteSpace: "pre-warp",

    },
}))(Tooltip);

// Criteria conversion 
const convertedItem = (item) => {
    let tempCriteria = item.criteria;
    if (tempCriteria) {
        if (tempCriteria.totalPriceMaxDigits) {
            let tempCommaString = (tempCriteria.totalPriceMaxDigits).toLocaleString('en-IN')
            tempCriteria['totalPriceMax'] = tempCommaString;
        }
        if (tempCriteria.totalPriceMinDigits) {
            let tempCommaString = (tempCriteria.totalPriceMinDigits).toLocaleString('en-IN')
            tempCriteria['totalPriceMin'] = tempCommaString;
        }
        if (tempCriteria.builtUpAreaMaxDigits) {
            let tempCommaString = (tempCriteria.builtUpAreaMaxDigits).toLocaleString('en-IN')
            tempCriteria['builtUpAreaMax'] = tempCommaString;
        }
        if (tempCriteria.builtUpAreaMinDigits) {
            let tempCommaString = (tempCriteria.builtUpAreaMinDigits).toLocaleString('en-IN')
            tempCriteria['builtUpAreaMin'] = tempCommaString;
        }
    } else {
        tempCriteria = {}
    }

    item['criteria'] = tempCriteria;

    return item;
}

class DataTables extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allUsersData: [],
            deleteRowDataId: null,
            tablefields: [{}],
            first: 0,
            rows: 10,
            direction: 'asc',
            filterCriteria: {
                limit: 10,
                page: 1,
                criteria: [],
                sortfield: '',
                direction: 'desc'
            },
            selectedRows: [],
            selectedRowsId: [],
            totalRecords: [],
            exportData: [],
            isOpenFormModal: false,
            isOpenLeaveBalanceModal: false,
            openBulkUploadModal: false,
            openViewModal: false,
            isLoading: true,
            isOpenRolesModal: false,
            isOpenTasksModal: false,
            screenPermissions: '',
            showLeaveOptions: false,
            userId: '',
            userReportingTo: '',
            daily: '',
            issueStatus: '',
            loginRole: '',
            startDate: '',
            endDate: '',
            apiUrl: '',
            isViewPhotosModalOpen: false,
            propertyPhotos: null,
            favButtonTitle: 'favourite',
        }
    }

    componentDidMount = async () => {
        localStorage.removeItem('myPropsSelected')
        localStorage.removeItem('searchPropsSelected')
        localStorage.removeItem('matchedPropsSelected')

        localStorage.removeItem('myIsGlobalChecked')
        localStorage.removeItem('searchIsGlobalChecked')
        localStorage.removeItem('matchedIsGlobalChecked')

        localStorage.removeItem('myPropsDeselected');
        localStorage.removeItem('searchPropsDeselected');
        localStorage.removeItem('matchedPropsDeselected');

        await this.props.onRef(this);
        let agent = JSON.parse(localStorage.getItem('loginCredentials'));
        let tablefields = [];
        let formfields = [];
        if (this.props.tableFields) {
            tablefields = await this.props.tableFields();
        }
        if (this.props.formFields) {
            formfields = await this.props.formFields();
        }
        await this.setState({
            tablefields: tablefields,
            formfields: formfields,
            agent: agent
        });
        await this.getTableListDataFromServer(this.state.filterCriteria);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    //get Properties list data from server with filters if any
    getTableListDataFromServer = async (filterCriteria, type) => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let url, apiUrl;
        this.setState({ isLoading: true, selectedRows: '' });
        if (!filterCriteria || filterCriteria == []) {
            filterCriteria = this.state.filterCriteria;
        }

        url = await this.getApiUrl();

        this.setState({ apiUrl: url });

        if (!filterCriteria || !filterCriteria['criteria']) {
            filterCriteria = this.state.filterCriteria;
        }
        if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
            delete filterCriteria['sortfield'];
            delete filterCriteria['direction'];
        }

        //when clicked on refresh icon
        if (type === 'refresh' && filterCriteria) {
            await this.setState({ filters: {} });
            let tableItems = this.state.tablefields;
            tableItems.forEach(async (element, i) => {
                if (document.getElementsByClassName("p-inputtext")[i] && document.getElementsByClassName("p-inputtext")[i].value) {
                    document.getElementsByClassName("p-inputtext")[i].value = '';
                }
                if (element && element.filterElement) {
                    await this.setState({ [element.field]: '' });
                }
            });

            filterCriteria = this.state.filterCriteria;
            filterCriteria['criteria'] = [];
        }

        //applyng filter when a row item is deleted 
        if (filterCriteria === undefined) {
            let filterCriteria = {};
            filterCriteria['limit'] = 20;
            filterCriteria['page'] = 1;
            filterCriteria['criteria'] = [];
            filterCriteria['direction'] = 'desc';
        }

        // To filter with defalut status values when rendered first time/ refreshed  || CodeByTej
        if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length === 0) {
            let arr = await this.props.tableFields();
            if (arr.length > 0) {
                let obj;
                if (this.props.type === 'Timesheet') {
                    obj = arr.find(el => el.field === 'projectName')
                } else {
                    obj = arr.find(el => el.field === 'status')
                }
                if (obj && obj.defaultValues) {
                    filterCriteria.criteria.push(
                        {
                            key: obj.field,
                            value: obj.defaultValues,
                            type: "in"
                        }
                    )
                    if (obj.field === 'status') {
                        this.setState({ haveStatus: true, haveProject: false });
                    }
                    if (obj.field === 'projectName') {
                        this.setState({ haveStatus: false, haveProject: true });
                    }
                }
            }
        }

        //Appending the filter to url if any 
        if (filterCriteria) {
            if (filterCriteria) {
                if (this.props.type === 'Clients') {
                    if (filterCriteria['criteria']) {
                        let ind = filterCriteria['criteria'].indexOf(item => item.key === 'agentEmail')
                        if (ind >= 0) {

                        } else {
                            filterCriteria['criteria'].push({ key: 'agentEmail', value: user.email, type: 'eq' });
                        }
                    } else {
                        filterCriteria['criteria'] = [{ key: 'agentEmail', value: user.email, type: 'eq' }]
                    }
                    let uniq = {}
                    filterCriteria['criteria'] = filterCriteria['criteria'].filter(obj => !uniq[obj.key] && (uniq[obj.key] = true));
                }

                if (this.props.type === 'savedSearches') {
                    if (filterCriteria['criteria']) {
                        let ind = filterCriteria['criteria'].indexOf(item => item.key === 'agentEmail')
                        if (ind >= 0) {

                        } else {
                            filterCriteria['criteria'].push({ key: 'agentEmail', value: user.email, type: 'eq' });
                        }
                    } else {
                        filterCriteria['criteria'] = [{ key: 'agentEmail', value: user.email, type: 'eq' }]
                    }
                    let uniq = {}
                    filterCriteria['criteria'] = filterCriteria['criteria'].filter(obj => !uniq[obj.key] && (uniq[obj.key] = true));
                }



                let filterString = JSON.stringify(filterCriteria);
                filterString = filterString.replace('&', '%26');
                apiUrl = `${url}?filter=${filterString}`;
                if (this.props.type === 'matchedProps') {
                    apiUrl = `${apiUrl}&agentEmail=${user.email}`
                }
            }
        }

        return fetch('GET', apiUrl)
            .then(async (response) => {
                let AllEmployeesData = [];
                if (this.props.type === 'matchedProps') {
                    url = 'properties'
                }

                if (this.props.type === 'savedSearches') {
                    url = 'clientCriterias'
                }
                if (this.props.type === 'recentMatches') {
                    url = 'properties'
                }
                if (response && response.respCode) {
                    if (response && response[url] && response[url].length && response[url].length > 0) {
                        if (response.pagination && response.pagination.totalCount) {
                            let totalRecordsLength = response.pagination.totalCount;
                            let totalRecords = response[url];
                            await this.setState({
                                totalRecordsLength: totalRecordsLength,
                                totalRecords: totalRecords
                            })
                        }

                        let index = 0;
                        for (let employee of response[url]) {
                            if (this.state.first) {
                                employee.Sno = (index + 1) + this.state.first;
                            } else {
                                employee.Sno = index + 1;
                            }
                            index++;
                            AllEmployeesData.push(employee);
                        }
                    }
                } else {
                    if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }

                await this.setState({
                    allUsersData: AllEmployeesData,
                    currentFilterCriteria: filterCriteria,
                    isLoading: false
                })
                return AllEmployeesData;
            }).catch((err) => {
                return err;
            });
    }

    //get url based on type
    getApiUrl = () => {
        let apiUrl = '';
        if (this.props.type === 'Properties') {
            apiUrl = 'properties';
        }
        if (this.props.type === 'Clients') {
            apiUrl = 'clients';
        }
        if (this.props.type === 'matchedProps') {
            apiUrl = 'criteria/getProperties';
        }
        if (this.props.type === 'savedSearches') {
            apiUrl = 'criteria';
        }
        if (this.props.type === 'recentMatches') {
            apiUrl = 'criteria/getRecentProperties'
        }
        return apiUrl;
    }

    //open Form modal
    openFormModal = async (type, rowData) => {
        if (this.props.type === 'Clients') {
            if (type === 'add') {
                this.setState({
                    isOpenClientsFormModal: true,
                    formType: type
                })
            } else if (type === 'edit') {
                await this.setState({
                    isOpenClientsFormModal: true,
                    formType: type,
                    clientRowData: rowData
                })
            }
        } else {
            await this.setState({
                isOpenFormModal: true,
                formType: type,
                selectedRows: '',
            });
            if (type === 'edit') {
                if (this.state.isOpenFormModal && this.formModalRef) {
                    this.formModalRef.getrowData(rowData);
                }
            }
        }
    }

    //Close Client Form Modal
    closeClientsFormModal = () => {
        this.setState({
            isOpenClientsFormModal: false,
        })
    }

    //close form modal
    closeFormModal = async () => {
        await this.setState({
            isOpenFormModal: false,
        })
    }

    // delete selected row
    deleteSelectedRow = () => {
        let apiUrl = this.getApiUrl();
        let method, url, body = {}

        if ((this.props.type === 'Clients') &&
            this.state.selectedRowsId && this.state.selectedRowsId.length &&
            this.state.selectedRowsId.length > 0) {
            method = 'POST';
            if (this.props.type === 'Clients') {
                url = `${apiUrl}/multipleUpdate?type=Delete`;
                body['clients'] = [...this.state.selectedRowsId]
            }
        } else if (this.props.type === 'savedSearches') {
            url = 'criteria/delete';
            method = 'PUT';
            if (this.state.deleteRowDataId) {
                let ids = []
                ids.push(this.state.deleteRowDataId);
                body['criteria'] = ids;
            } else {
                body['criteria'] = this.state.selectedRowsId;
            }
        } else {
            url = `${apiUrl}/${this.state.deleteRowDataId}`;
            method = 'DELETE';
            body = ''
        }
        return fetch(method, url, body)
            .then(async (response) => {
                this.setState({
                    openConfirmActionModal: false,
                    deleteRowDataId: null,
                    selectedRowsId: null,
                });
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, 'success');
                    await this.getTableListDataFromServer();
                } else {
                    if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }
            }).catch((err) => {
                return err;
            });
    }

    favSelectedClients = async (type) => {
        let apiUrl = this.getApiUrl();
        let method, url, body = {}
        method = 'POST';
        url = `${apiUrl}/multipleUpdate?type=Update`;

        if (this.state.deleteRowDataId) {
            let ids = []
            ids.push(this.state.deleteRowDataId);
            body['clients'] = ids;

        } else {
            body['clients'] = [...this.state.selectedRowsId];
            body['isFavourite'] = true;

        }

        if (type === 'fav') {
            body['isFavourite'] = true;
        } else if (type === 'unFav') {
            body['isFavourite'] = false;
        }

        return fetch(method, url, body)
            .then(async (response) => {
                this.setState({
                    openConfirmActionModal: false,
                    deleteRowDataId: null,
                    selectedRowsId: null,
                    actionTitle: null,
                    actionType: null,
                    infoTitle: null,
                });
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, 'success');
                    await this.getTableListDataFromServer();
                } else {
                    if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }
            }).catch((err) => {
                return err;
            });
    }

    //close delete modal
    closeConfirmActionModal = async () => {
        await this.setState({
            openConfirmActionModal: false,
            deleteRowDataId: null,
            selectedRowsId: null,
            actionTitle: null,
            actionType: null,
            infoTitle: null,
        })
    }

    // conformation for delete employee
    deleteConfirmAction = async (rowData, value) => {
        let selectedRowsId = [];
        let selectedRows = [...this.state.selectedRows]
        let actionTitle;
        if (this.props.type === 'savedSearches') {
            if ((rowData) || (selectedRows && selectedRows.length === 1)) {
                actionTitle = 'deleteSearchConfirmationText'
            } else {
                actionTitle = 'deleteMultipleSearchConfirmationText'
            }
        } else if (this.props.type === 'Clients') {
            if ((rowData && rowData !== '') || (selectedRows && selectedRows.length === 1)) {
                actionTitle = 'deleteClientConfirmationText'
            } else {
                actionTitle = 'deleteMultipleClientConfirmationText'
            }
        } else {
            actionTitle = 'deleteConfirmatonText'
        }

        if (rowData) {
            this.setState({
                deleteRowDataId: rowData,
                openConfirmActionModal: true,
                actionTitle: actionTitle
            });
        } else if (selectedRows && selectedRows.length && selectedRows.length > 0 && value === 'delete') {
            selectedRows.forEach((item, index) => {
                selectedRowsId.push(item._id)
            })
            await this.setState({
                selectedRowsId: selectedRowsId,
                openConfirmActionModal: true,
                actionTitle: actionTitle
            })
        }
    }

    // CLients Favourite or UnFavourite Action
    favConfirmAction = async (rowData, value) => {
        let selectedRowsId = [];
        let selectedRows = [...this.state.selectedRows];
        let actionTitle;
        let actionType;
        let noOfFavs = 0;
        let noOfUnFavs = 0;
        let infoTitle;
        if (this.props.type === 'Clients') {
            if (rowData) {
                if (value) {
                    if (value && value === 'fav') {
                        actionTitle = 'clientFavConfirmationText';
                    } else if (value && value === 'unFav') {
                        actionTitle = 'clientUnFavConfirmationText';
                    }
                    actionType = value;
                }
            } else if (selectedRows && selectedRows.length === 1) {
                if (selectedRows[0] && selectedRows[0].isFavourite) {
                    actionTitle = 'clientUnFavConfirmationText';
                    actionType = 'unFav';
                } else {
                    actionTitle = 'clientFavConfirmationText';
                    actionType = 'fav';
                }
            } else if (selectedRows && selectedRows.length > 1) {
                selectedRows.map(item => {
                    if (item.isFavourite) {
                        return noOfFavs = noOfFavs + 1;
                    } else {
                        return noOfUnFavs = noOfUnFavs + 1;
                    }
                })
                if (noOfFavs === 0) {
                    actionTitle = 'multipleClientFavConfirmationText';
                    actionType = 'fav';
                } else if (noOfUnFavs === 0) {
                    actionTitle = 'multipleClientUnFavConfirmationText';
                    actionType = 'unFav';
                } else if (noOfFavs >= noOfUnFavs) {
                    actionTitle = 'multipleClientFavConfirmationText';
                    actionType = 'fav';
                    infoTitle = 'multiClientFavMoreInfoText';
                } else if (noOfUnFavs > noOfFavs) {
                    actionTitle = 'multipleClientUnFavConfirmationText';
                    actionType = 'unFav';
                    infoTitle = 'multiClientUnFavMoreInfoText';
                }
            }
        }

        if (rowData) {
            actionType = value;
            this.setState({
                deleteRowDataId: rowData,
                openConfirmActionModal: true,
                actionTitle: actionTitle,
                actionType: actionType,
                infoTitle: infoTitle,
            });
        } else if (selectedRows && selectedRows.length && selectedRows.length > 0) {
            selectedRows.forEach((item, index) => {
                selectedRowsId.push(item._id)
            })
            await this.setState({
                selectedRowsId: selectedRowsId,
                openConfirmActionModal: true,
                actionType: actionType,
                actionTitle: actionTitle,
                infoTitle: infoTitle,
            })
        }
    }


    openViewModal = async (rowData) => {
        let keys = Object.keys(rowData);
        let formFields = this.props.formFields();
        if (this.props.type === 'Leaves') {
            formFields.push(
                {
                    required: true,
                    value: '',
                    type: 'date',
                    name: 'halfDayType',
                    label: 'Half Date Type',
                    id: 'fromDate',
                    placeholder: 'dd-mm-yyyy'
                },
            )
        }
        if (this.props.type === 'Attendance') {
            let value = ' ';
            rowData.checkouts.forEach((item, index) => {
                let time = dateFormats.formatDate(item.time, config.timeFormat), timeCheck = '';
                if (index % 2 === 0) {
                    timeCheck = ' IN => '
                }
                else {
                    timeCheck = ' OUT => '
                }
                value = value + timeCheck + time;
            });

            formFields.push(
                {
                    required: true,
                    value: value,
                    type: 'text',
                    name: 'checkin',
                    label: 'Check In',
                    placeholder: 'dd/mm/yyyy'
                },
            )
        }

        for (let item of formFields) {
            if (keys.indexOf(item.name) !== -1) {
                if (rowData[item.name]) {
                    if (item.name === 'created' || item.name === 'startDate' || item.name === 'endDate' || item.name === 'joinDate' || item.name === 'dateOfBirth') {
                        item.value = dateFormats.formatDate(rowData[item.name], config.dateDashFormat);
                    } else if (item.name === 'reportingTo') {
                        if (rowData[item.name].displayName) {
                            item.value = rowData[item.name].displayName;
                        }
                    }
                    else {
                        item.value = rowData[item.name];
                    }

                }
            }
        }
        await this.setState({
            openViewModal: true
        });
        this.viewModalRef.getRowData(formFields)
    }

    closeViewModal = async () => {
        await this.setState({
            openViewModal: false
        });
    }

    //on changing pagination
    onPageChange = async (event, filterCriteria) => {
        if (event && event.rows) {
            let currentPage = event.page + 1;
            filterCriteria['limit'] = event.rows;
            filterCriteria['page'] = currentPage;
            await this.setState({
                rows: event.rows,
                page: event.page,
                first: event.first,
                pageCount: event.pageCount
            })
        }
        this.getTableListDataFromServer(filterCriteria)
    }

    //sorting fields
    sortChange = (event) => {

        //Empty the search fields when sorted ||CodeByTej
        let tableItems = this.state.tablefields;
        tableItems.forEach(async (element, i) => {
            if (document.getElementsByClassName("p-inputtext")[i] && document.getElementsByClassName("p-inputtext")[i].value) {
                document.getElementsByClassName("p-inputtext")[i].value = '';
            }
            if (element && element.filterElement) {
                await this.setState({ [element.field]: '' });
            }
        });
        if (document.getElementById("globalSearch") && document.getElementById('globalSearch').value) {
            document.getElementById('globalSearch').value = '';
        }

        this.setState({ selectedRows: '' })
        let sortCount = this.state.sortCount;
        if (event && event['sortField']) {
            sortCount = sortCount === 0 ? sortCount + 1 : 0;
            let sortField = event['sortField'];
            let filterCriteria = {
                direction: sortCount === 0 ? "desc" : 'asc',
                sortfield: sortField,
                limit: this.state.rows,
                page: this.state.page ? this.state.page : 1
            }
            this.setState({
                sortCount: sortCount,
                filterCriteria: filterCriteria
                // isLoading: true
            });
            this.getTableListDataFromServer(filterCriteria);
        }
    }


    // table global search
    globalSearch = (value) => {
        let filterCriteria = this.state.filterCriteria;
        //To check whether filter value has any '&' and replace with 'ampd' to escape '&' char in server call
        if (value.length >= 3) {
            filterCriteria["globalSearch"] = { "value": value, "type": "user" };
        } else {
            if (filterCriteria["globalSearch"]) {
                delete filterCriteria["globalSearch"];
            } else {

                return;
            }
        }
        this.getTableListDataFromServer(filterCriteria);
    }
    // on search get data from server
    onFilterChange = async (event, type) => {
        let isDateFilterEnabled = false;
        this.setState({ selectedRows: '' });
        let pageEvent = { first: 0, page: 0, rows: this.state.rows, pageCount: this.state.pageCount };
        if (type === 'dropdownFilter') {
            await this.setState({ [event.target.name]: event.target.value });
        }
        let filterCriteria = this.state.filterCriteria;
        if (filterCriteria.criteria === undefined) {
            filterCriteria.criteria = [];
        }
        if (type === 'dropdownFilter' && event.value === null) {
            // in  All case if the value is null then find & remove that obj from filter criteria
            if (filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length > 0) {
                let obj = filterCriteria.criteria.find(x => x.key === event.target.name);
                let indx = filterCriteria.criteria.indexOf(obj);
                filterCriteria.criteria.splice(indx, 1);
                this.onPageChange(pageEvent, filterCriteria);
                return;
            }
        } else {
            let fieldName = '', selectedFilterValue, selectedFilterType, formattedDate, invalidDate = false, invalidLength = false;
            if (event) {
                if (event && event.filters) {
                    this.setState({ filters: event.filters });
                    if (Object.keys(event.filters) && Object.keys(event.filters).length > 0) {
                        let filterObj = event.filters;
                        // let otherFilterCase = false;
                        Object.keys(filterObj).forEach(async (key) => {
                            fieldName = key;
                            if (filterCriteria && filterCriteria.criteria) {
                                if (fieldName === 'createdBy') {
                                    fieldName = 'createdBy.name';
                                }
                                //  date fields
                                let dateFields = ['dateOfBirth', 'fromDate', 'toDate', 'date', 'created', 'updated', 'purchaseDate', 'dateOfBirth', 'startDate', 'endDate', 'joinDate'];
                                if (dateFields.includes(fieldName)) {
                                    // to search
                                    if ((filterObj[key].value && filterObj[key].value.length < 10)) {
                                        invalidLength = true;
                                    } else {
                                        if (!dateFormats.dateIsValid(filterObj[key].value)) {
                                            invalidDate = true;
                                        }
                                        // to search
                                        selectedFilterValue = filterObj[key] && filterObj[key].value && filterObj[key].value.length >= 10 ? dateFormats.formatDate(filterObj[key].value, config.dateDashFormat, config.dateDmyFormat) : null;

                                        selectedFilterType = 'gte';
                                        // formatted date is given date plus one to give value for lte type
                                        let date = dateFormats.addDaysToDate(selectedFilterValue, 1);
                                        formattedDate = dateFormats.formatDate(date, config.dateDashFormat);
                                        // removing  lte prev  obj of same field in filter criteria (remove duplicates)
                                        let obj = function getIndex(fieldName) {
                                            for (var i = 0; i < filterCriteria.criteria.length; i++) {
                                                if (filterCriteria.criteria[i].key === fieldName && filterCriteria.criteria[i].type === 'lt') return i;
                                            }

                                        }
                                        let index = filterCriteria.criteria.indexOf(obj);
                                        filterCriteria['criteria'].splice(index, 1);
                                    }
                                }
                                //To check whether filter value has any '&' and replace with 'ampd' to escape '&' char in server call 
                                else {
                                    selectedFilterValue = filterObj[key] && filterObj[key].value ? filterObj[key].value : null;
                                    selectedFilterValue = selectedFilterValue.replace('&', 'ampd');
                                    selectedFilterType = 'regexOr';
                                }
                                // to check whether  key already exits in filter criteria or not 
                                let obj = filterCriteria.criteria.find(x => x.key === fieldName);
                                let index = filterCriteria.criteria.indexOf(obj);
                                if (index === -1) {
                                    await filterCriteria['criteria'].push({
                                        key: fieldName, value: selectedFilterValue,
                                        type: selectedFilterType
                                    });

                                } else {
                                    filterCriteria.criteria[index].value = selectedFilterValue;
                                    filterCriteria.criteria[index].type = selectedFilterType;

                                }

                                // if  key is date field push lte also
                                if (selectedFilterType === 'gte' && formattedDate !== 'Invalid date' && !invalidLength) {
                                    isDateFilterEnabled = true;
                                    await filterCriteria['criteria'].push({ key: fieldName, value: formattedDate, type: 'lt' });
                                }
                                if (invalidLength || invalidDate) {
                                    delete filterCriteria['criteria'][key];
                                }
                            }
                            if (filterObj[key].value && filterObj[key].value.length < 3) {
                                // to check whether  key already exits in filter criteria or not
                                let obj2 = filterCriteria.criteria.find(x => x.key === fieldName);
                                let index2 = filterCriteria.criteria.indexOf(obj2);
                                await filterCriteria.criteria.splice(index2, 1);
                            }
                        });
                        if (this.state.startDate && this.state.endDate && !isDateFilterEnabled) {

                            let startDate = (moment(this.state.startDate).format('YYYY-MM-DD'));
                            let endDate = (moment(this.state.endDate).format('YYYY-MM-DD'));
                            let startrangeObj = { 'key': 'date', 'value': startDate, 'type': 'gte' }
                            let endrangeObj = { 'key': 'date', 'value': endDate, 'type': 'lte' }
                            filterCriteria.criteria.push(startrangeObj);
                            filterCriteria.criteria.push(endrangeObj);

                            // this.getTableListDataFromServer(filterCriteria);
                        }
                        if (invalidDate) {
                            showToasterMessage('Invalid Date Format', 'error');
                            return;
                        } if (invalidLength) {
                            return;
                        }

                    }
                    else if (event.filters == {} && filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length === 0) {
                        filterCriteria['criteria'] = [];
                    }
                } else if (type === 'dropdownFilter') {
                    let fieldName = event.target.name;
                    let selectedFilterValue = event.target.value;
                    if (event && event.target && event.target.name === 'status') {
                        selectedFilterType = 'in';
                    }
                    else if (event && event.target && event.target.name === 'projectName') {
                        selectedFilterType = 'in';
                    }
                    else {
                        selectedFilterType = 'eq';
                    }
                    if (filterCriteria && filterCriteria.criteria) {

                        let obj = filterCriteria.criteria.find(x => x.key === fieldName);
                        let index = filterCriteria.criteria.indexOf(obj);
                        if (index === -1) {

                            await filterCriteria['criteria'].push({
                                key: fieldName, value: selectedFilterValue,
                                type: selectedFilterType
                            });

                        } else {
                            filterCriteria['criteria'].splice(index, 1, {
                                key: fieldName, value: selectedFilterValue,
                                type: selectedFilterType
                            });
                        }


                    }
                }


                if (filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length > 0) {
                    if (filterCriteria.criteria && filterCriteria.criteria[filterCriteria.criteria.length - 1] && filterCriteria.criteria[filterCriteria.criteria.length - 1].value && filterCriteria.criteria[filterCriteria.criteria.length - 1].value.length < 3) {
                        if (this.props.type === 'Timesheet') {
                            if (!this.state.haveProject) {
                                return;
                            }
                            if (this.state.haveProject) {
                                if (filterCriteria.criteria.length > 1) {
                                    return;
                                } else {
                                    await this.onPageChange(pageEvent, filterCriteria);
                                }
                            }
                        } else {
                            if (!this.state.haveStatus) {
                                return;
                            }
                            if (this.state.haveStatus) {
                                if (filterCriteria.criteria.length > 1) {
                                    return;
                                } else {
                                    await this.onPageChange(pageEvent, filterCriteria);
                                }
                            }
                        }
                    } else {

                        await this.onPageChange(pageEvent, filterCriteria)
                    }

                } else {
                    filterCriteria.criteria = [];
                    await this.onPageChange(pageEvent, filterCriteria);
                }

            } else {
                this.setState({
                    isLoading: false
                })
            }
        }
    }

    //open Bulk Modal
    bulkUploadMoadal = () => {
        this.setState({ openBulkUploadModal: true })
    }

    //close Bulk Modal
    closeBulkModal = () => {
        this.setState({ openBulkUploadModal: false });
        this.getTableListDataFromServer(this.state.filterCriteria, 'refresh')
    }

    //select multiple rows to delete
    selectRowsToDelete = async (event) => {
        let noOfFavs = 0;
        let noOfUnFavs = 0;
        let favButtonTitle = 'favourite';

        if (event && event.value && event.value.length === 1) {
            event.value.map(item => {
                if (item.isFavourite) {
                    return noOfFavs = noOfFavs + 1;
                } else {
                    return noOfUnFavs = noOfUnFavs + 1;
                }
            })
            if (event.value[0].isFavourite) {
                favButtonTitle = 'unFavourite'
            } else {
                favButtonTitle = 'favourite';
            }
        } else if (event && event.value && event.value.length > 1) {
            event.value.map(item => {
                if (item.isFavourite) {
                    return noOfFavs = noOfFavs + 1;
                } else {
                    return noOfUnFavs = noOfUnFavs + 1;
                }
            })
            if (noOfFavs === 0) {
                favButtonTitle = 'favourite';
            } else if (noOfUnFavs === 0) {
                favButtonTitle = 'unFavourite';
            } else if (noOfFavs >= noOfUnFavs) {
                favButtonTitle = 'favourite';
            } else {
                favButtonTitle = 'unFavourite';
            }
        }

        await this.setState({
            selectedRows: event.value,
            favButtonTitle: favButtonTitle,
        })
    }

    changeLeaveStatus = async (rowData, type, status) => {
        await this.setState({ formType: 'edit', setFormRef: true })
        if (type === 'reason') {
            if (this.formModalRef) {
                this.formModalRef.handleData(rowData, 'reason', 'changeStatus')
            }

        } else {
            let obj = {};
            if (this.props.type === 'Attendance') {
                obj.wfhApproved = status;
            }
            else {
                obj.status = status;
            }
            if (this.formModalRef) {
                this.formModalRef.getrowData(rowData);
                if (this.props.type === 'Attendance') {
                    this.formModalRef.handleData(obj, 'wfhApproved', 'changeStatus')
                }
                else {
                    this.formModalRef.handleData(obj, 'status', 'changeStatus')
                }
            }
        }
    }

    //open leave balance update modal
    openLeaveUpdateModal = async (type, data) => {
        await this.setState({ isOpenLeaveBalanceModal: true, leavedata: data })

    }
    //close leave update modal
    closeLeaveUpdateModal = () => {
        this.setState({ isOpenLeaveBalanceModal: false });
    }

    //openRejectModal
    openRejectModal = async (rowData) => {
        await this.setState({ setFormRef: true })
        this.formModalRef.getrowData(rowData);
        this.setState({ openRejectModal: true });
    }

    closeRejectModal = () => {
        this.setState({
            openRejectModal: false,
            isOpenFormModal: false
        });
    }


    //openConfirmationModal
    openConfirmationModal = async (rowData, status, type) => {
        await this.setState({ item: rowData, confirmText: type })
        if (this.props.type === 'Tasks') {
            status = 'Active'
        }
        // this.formModalRef.getrowData(rowData);
        this.setState({ openConfirmationModal: true, leaveStatus: status })
    }

    closeConfirmationModal = () => {
        this.setState({ openConfirmationModal: false })
    }

    // handle View Photos Modal
    viewPhotosModal = async (photos) => {
        if (photos && photos === 'close') {
            await this.setState({
                propertyPhotos: null,
                isViewPhotosModalOpen: false
            })
        } else if (photos) {
            await this.setState({
                propertyPhotos: photos,
                isViewPhotosModalOpen: true
            })
        }
    }

    //change table body values
    changeFieldValues = (item, column) => {

        if (item && (column.field === 'propertyDescription' || column.field === 'reason' || column.field === 'title') && item[column.field]) {
            return (

                <LightTooltip title={item[column.field]}>
                    <div>{item[column.field]}</div>
                </LightTooltip>
            )
        }
        else if (column.field === 'created' || column.field === 'addedDate') {
            return (
                moment(item[column.field]).format('DD-MM-YYYY')
            )
        }
        else if (column.field === 'criteria') {
            if (this.props.type === 'savedSearches') {
                if (item[column.field]) {
                    let cri = item[column.field];
                    delete cri['builtUpAreaMaxDigits'];
                    delete cri['builtUpAreaMinDigits'];
                    delete cri['totalPriceMaxDigits'];
                    delete cri['totalPriceMinDigits'];

                    if (cri['builtUpAreaMax']) {
                        cri['builtUpAreaMax'] = cri['builtUpAreaMax'].replaceAll(',', '')
                    }
                    if (cri['builtUpAreaMin']) {
                        cri['builtUpAreaMin'] = cri['builtUpAreaMin'].replaceAll(',', '')
                    }
                    if (cri['totalPriceMax']) {
                        cri['totalPriceMax'] = cri['totalPriceMax'].replaceAll(',', '')
                    }
                    if (cri['totalPriceMin']) {
                        cri['totalPriceMin'] = cri['totalPriceMin'].replaceAll(',', '')
                    }

                    let str = JSON.stringify(cri);
                    str = str.replace(/{|}|"|/g, "")
                    str = str.replace(',', "\n")
                    str = str.replaceAll(':', ' : ')
                    str = str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
                    let title = str.replaceAll(',', "\n")
                    return (
                        <div className="data">
                            {item[column.field]['searchName'] ? item[column.field]['searchName'] :
                                item[column.field]['propertyFor'] ? item[column.field]['propertyFor'] :
                                    item[column.field]['propertyType'] ? item[column.field]['propertyType'] :
                                        item[column.field]['location'] ? item[column.field]['location'] :
                                            item[column.field]['availability'] ? item[column.field]['availability'] :
                                                item[column.field]['furnishingType'] ? item[column.field]['furnishingType'] :
                                                    item[column.field]['facing'] ? item[column.field]['facing'] :
                                                        item[column.field]['totalPriceMin'] ? item[column.field]['totalPriceMin'] :
                                                            item[column.field]['totalPriceMax'] ? item[column.field]['totalPriceMax'] :
                                                                item[column.field]['builtUpAreaMin'] ? item[column.field]['builtUpAreaMin'] :
                                                                    item[column.field]['builtUpAreaMax'] ? item[column.field]['builtUpAreaMax'] :
                                                                        item[column.field]['bedRooms'] ? item[column.field]['bedRooms'] :
                                                                            null
                            }
                            <span className="dataText">{title}</span>
                        </div>
                    )
                }
            }
        }
        else if (column.field === 'result') {

            if (this.props.type === 'savedSearches') {
                return (
                    <Link style={{color : 'rgb(255, 123, 0)', fontWeight : 'bold' , textDecoration : 'underline'}}
                    to={{
                        pathname: `/show_matched_properties`,
                        state: {
                            state: convertedItem(item),
                            fromScreen: window.location.pathname,
                            matchedFromScreen: window.location.pathname,
                            searchName : item.criteria && item.criteria.searchName ? item.criteria.searchName : '',
                            showPropertiesList :item[column.field],
                            hideFilters : true,
                        },
                    }}
                >
                    {item[column.field].length}
                </Link>
                )
            }
        }
        else if (column.field === 'photo') {

            // onClick={() => this.viewPhotosModal(item[column.field])}
            if (this.props.type === 'Clients') {
                if (item && item[column.field]) {
                    return (
                        <img className="clientsTableImgStyles" alt='' src={`${config.imgUrl}client/${item[column.field]}`} />
                    )
                } else {
                    return (
                        <img className='clientsTableImgStyles' alt='' src={configImages.defaultImg} />
                    )
                }
            } else {
                if (item && item[column.field] && item[column.field][0]) {
                    return (
                        <div className="img__wrap" >
                            <img className="img__img" alt='' src={`${config.imgUrl}client/${item[column.field][0].name}`} />
                            <p className="img__description">
                                <AspectRatio style={{ height: '-webkit-fill-available' }} />
                            </p>
                        </div>
                    )
                } else {
                    return (
                        <img alt='' src={configImages.defaultImg} className='imgStyles' />
                    )
                }
            }
        }
        else if (column.field === 'displayName') {

            if (this.props.type === 'Clients' || this.props.type === 'ClientsFav') {
                return (
                    <Link
                        to={{ pathname: `/clientDetails/${item._id}`, state: { tl: this.props.tl } }}
                        className='textCapitalize themeColorText'
                    >
                        {item.displayName}
                    </Link>
                );
            }
        }
        else if (column.field === 'isFavourite') {
            if (this.props.type === 'Clients') {
                if (item[column.field]) {
                    return (
                        <StarIcon
                            className='cursorPointer'
                            color={config.themeColor}
                            onClick={() => this.favConfirmAction(item._id, 'unFav')}
                        />
                    )
                } else {
                    return (
                        <NotStarIcon
                            className='cursorPointer'
                            color={config.themeColor}
                            onClick={() => this.favConfirmAction(item._id, 'fav')}
                        />
                    )
                }
            }
        }
        else if (column.field === 'type') {

            if (this.props.type === 'Clients' || this.props.type === 'ClientsFav') {
                return item['type']
            }
        }
        else if (column.field === 'employeeName') {

            return <div className='textElipses'>
                <span style={{ color: 'blue' }} className='text-capitalize'
                    onClick={() => this.openViewModal(item)}>{item.employeeName}</span>
            </div>
        }
        else if (column.field === 'status') {

            if (item.status === 'Active' || item.status === 'Fixed' || item.status === 'In Stock') {
                return <Badge color='success' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.status}</Badge>
            } else if (item.status === 'Pending' || item.status === 'Rejected' || item.status === 'UAT') {
                return <Badge color='danger' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.status}</Badge>
            } else {
                return <Badge color='secondary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.status}</Badge>
            }

        }
        else if (column.field === 'Actions') {
            if (this.props.type === 'matchedProps') {
                return (
                    <Link
                        to={{
                            pathname: `/propertyDetails/${item._id}`,
                            state: {
                                tl: this.state.tl,
                                fromScreen: window.location.pathname,
                            }
                        }}
                    >
                        <div className='row' style={{ justifyContent: 'center' }}>
                            <div>
                                <FontAwesomeIcon
                                    className='genderIconAlignment cursorPointer'
                                    color='white'
                                    icon='file'
                                    data-toggle="tool-tip"
                                    title="View"
                                    style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                />
                            </div>
                        </div >
                    </Link>
                )
            } else if (this.props.type === 'Clients') {
                return (
                    <div className='row' style={{ justifyContent: 'center' }}>
                        <div>
                            <FontAwesomeIcon
                                className='genderIconAlignment cursorPointer'
                                color='white'
                                icon='edit'
                                data-toggle="tool-tip"
                                title="Edit"
                                style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                onClick={() => this.openFormModal('edit', item)} />
                        </div>
                        <div>
                            <FontAwesomeIcon className='genderIconAlignment cursorPointer'
                                color='white'
                                icon='trash-alt'
                                data-toggle="tool-tip"
                                title="Delete"
                                style={{ color: '#bf1725', width: '13', marginRight: '10px' }}
                                onClick={() => this.deleteConfirmAction(item._id)} />
                        </div>
                    </div >
                )
            } else if (this.props.type === 'savedSearches') {
                return (
                    <div className='row' style={{ justifyContent: 'center' }}>
                        <div>
                            <Link
                                to={{
                                    pathname: `/matched_properties`,
                                    state: {
                                        state: convertedItem(item),
                                        fromScreen: window.location.pathname,
                                        matchedFromScreen: window.location.pathname,
                                        searchName : item.criteria && item.criteria.searchName ? item.criteria.searchName : ''
                                    },
                                }}
                            >
                                <FontAwesomeIcon
                                    className='genderIconAlignment cursorPointer'
                                    color='white'
                                    icon='edit'
                                    data-toggle="tool-tip"
                                    title="Edit"
                                    style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                />
                            </Link>
                        </div>
                        <div>
                            <FontAwesomeIcon className='genderIconAlignment cursorPointer'
                                color='white'
                                icon='trash-alt'
                                data-toggle="tool-tip"
                                title="Delete"
                                style={{ color: '#bf1725', width: '13', marginRight: '10px' }}
                                onClick={() => this.deleteConfirmAction(item._id)} />
                        </div>
                    </div >
                )
            }
        }
        else if (column.field === 'email') {
            return item[column.field]
        }
        else if (column.field === 'price') {
            if (this.props.type === 'matchedProps') {
                return (
                    item['totalPrice']
                )
            }
        }
        else if (column.field === 'area') {
            if (this.props.type === 'matchedProps') {
                return (
                    item['builtUpArea']
                )
            }
        }
        else if (column.field === 'addedProperties' && this.props.type === 'recentMatches') {
           return item.addedProperties && item.addedProperties.length ? item.addedProperties.length : null
        }
        else {
            if (item[column.field]) {
                return <span className='text-capitalize'>{item[column.field]}</span>
            }
        }
    }

    // Handle Share Button onClick
    handleShare = async () => {
        let selectedProperties = this.state.selectedRows;

        if (selectedProperties && selectedProperties.length > config.shareLimit) {
            await this.setState({
                isOpenShareLimitModal: true
            })
        } else {
            await this.setState({
                isOpenSharePropsModal: true,
                selectedProperties: selectedProperties,
            })
        }
    }

    getTableValues = (item) => {
        const mobileFields = this.state.tablefields.filter((field) => field.showInMoblie);
        return mobileFields.map((field) => {
            return item[field.field] ? (
                <div key={field.field} className='gridField'>
                    <div className='gridFieldKey' title={field.field}>{field.header}</div>&nbsp;&nbsp;:&nbsp;&nbsp;
                    
                    <div className='gridFieldValue'>
                        {this.changeFieldValues(item, field)}
                    </div>
                </div>
            ) : null;
        });
    }

    getGridActions(item) {
            if (this.props.type === 'Clients') {
                return (
                    <div className='row' style={{ justifyContent: 'center' }}>
                        <div>
                            <FontAwesomeIcon
                                className='genderIconAlignment cursorPointer'
                                color='white'
                                icon='edit'
                                data-toggle="tool-tip"
                                title="Edit"
                                style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                onClick={() => this.openFormModal('edit', item)} />
                        </div>
                        <div>
                            <FontAwesomeIcon className='genderIconAlignment cursorPointer'
                                color='white'
                                icon='trash-alt'
                                data-toggle="tool-tip"
                                title="Delete"
                                style={{ color: '#bf1725', width: '13', marginRight: '10px' }}
                                onClick={() => this.deleteConfirmAction(item._id)} />
                        </div>
                    </div >
                )
            } else if (this.props.type === 'savedSearches') {
                return (
                    <div className='row' style={{ justifyContent: 'center' }}>
                        <div>
                            <Link
                                to={{
                                    pathname: `/matched_properties`,
                                    state: {
                                        state: convertedItem(item),
                                        fromScreen: window.location.pathname,
                                        matchedFromScreen: window.location.pathname,
                                        searchName : item.criteria && item.criteria.searchName ? item.criteria.searchName : ''
                                    },
                                }}
                            >
                                <FontAwesomeIcon
                                    className='genderIconAlignment cursorPointer'
                                    color='white'
                                    icon='edit'
                                    data-toggle="tool-tip"
                                    title="Edit"
                                    style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                />
                            </Link>
                        </div>
                        <div>
                            <FontAwesomeIcon className='genderIconAlignment cursorPointer'
                                color='white'
                                icon='trash-alt'
                                data-toggle="tool-tip"
                                title="Delete"
                                style={{ color: '#bf1725', width: '13', marginRight: '10px' }}
                                onClick={() => this.deleteConfirmAction(item._id)} />
                        </div>
                    </div >
                )
            }
    }


    handleCheckBox = async (e,item) => {
        const value = item._id;
        const isChecked = e.target.checked;

        let updatedCheckValue;
        let updatedSelectedRows;
        let selectedRowsId = [];
        let selectedRows = []; 
        if(this.state.selectedRowsId && this.state.selectedRowsId.length > 0) {
            selectedRowsId = this.state.selectedRowsId;
        }
        if(this.state.selectedRows && this.state.selectedRows.length > 0) {
            selectedRows = this.state.selectedRows;
        }
        if (isChecked) {
            updatedCheckValue = [...selectedRowsId, value];
            updatedSelectedRows = [...selectedRows, item];
        } else {
            updatedCheckValue = selectedRowsId.filter(id => id !== value);
            updatedSelectedRows = selectedRows.filter(row => row._id !== value);
        }
        if (this.props.type === 'Clients') {
            this.selectRowsToDelete({value : updatedSelectedRows})
        }
        this.setState({selectedRowsId : updatedCheckValue ,selectedRows : updatedSelectedRows })
    }
    

    render() {
        const { tl, t } = this.props;

        return (
            <Row className="mx-0" >
                <Col md={12} className='text-right mt-3'>
                    <ButtonToolbar
                        className="form__button-toolbar mb-0"
                        style={{ justifyContent: 'flex-end' }}
                    >
                        {/* Share */}
                        {this.props.type === 'matchedProps' ?
                            <Button
                                color="primary"
                                className='buttonTextWeight custom-button'
                                disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                                onClick={this.handleShare}
                            >
                                <span className='d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'>{tl['share'] ? tl['share'] : 'Share'}</span>
                                <span className='d-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none'>
                                <ShareIcon
                                    className='shareIconAlignment cursorPointer ml-auto custom-button-icon'/>
                                </span>
                            </Button>
                            : null
                        }

                        {/* Add*/}
                        {this.props.type === 'Clients' ?
                            <Button
                                color="primary"
                                className='buttonTextWeight mr-0 ml-2 custom-button'
                                title={tl['addClient'] ? tl['addClient'] : 'Add Client'}
                                onClick={() => this.openFormModal('add')}
                            >
                                <span className='d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'>{'+ '}{tl['addClient'] ? tl['addClient'] : 'Add Client'}</span>
                                <span className='d-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none'>
                                <FontAwesomeIcon className='genderIconAlignment cursorPointer custom-button-icon'
                                color='white'
                                icon='plus'
                                data-toggle="tool-tip"
                                title="Delete"
                                style={{ color: '#fff', width: '13' }} />
                                </span>
                            </Button>
                            : null
                        }

                        {/* Add Client to Favourites */}
                        {this.props.type === 'Clients' ?
                            <Button
                                color="primary"
                                onClick={() => this.favConfirmAction()}
                                className='buttonTextWeight mr-0 ml-2 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'
                                disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                            >
                                {tl[this.state.favButtonTitle] ? tl[this.state.favButtonTitle] : 'Fav/UnFav'}
                            </Button>
                            : null
                        }

                        {/* Delete button */}
                        {this.props.type === 'Clients' || this.props.type === 'savedSearches' ?
                            <Button
                                color="primary"
                                onClick={() => this.deleteConfirmAction(null, 'delete')}
                                className='buttonTextWeight mr-0 ml-2 custom-button'
                                disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                            >
                               
                                <span className='d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'> {tl['delete'] ? tl['delete'] : 'Delete'}</span>
                                <span className='d-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none align-center'>
                                <FontAwesomeIcon className='genderIconAlignment cursorPointer custom-button-icon'
                                color='white'
                                icon='trash-alt'
                                data-toggle="tool-tip"
                                title="Delete"
                                style={{ color: '#fff', width: '13' }} />
                                </span>
                            </Button>
                            : null
                        }
                    </ButtonToolbar>

                    {this.props.type === 'Clients' ?
                        <div className='d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'>
                            {'* '}
                            {tl['clientStarActionStatement'] ?
                                tl['clientStarActionStatement']
                                : 'Click on Star icon to Favourite or Un-Favourite Clients Individually'
                            }
                        </div>
                        : null
                    }
                </Col>
                <Col className='d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'>
                    <Loader loader={this.state.isLoading} />
                    <DataTable
                        ref={(el) => this.dataTableRef = el}
                        value={this.state.allUsersData}
                        totalRecords={this.state.totalRecordsLength}
                        paginator={false}
                        lazy={true}
                        resizableColumns={true}
                        columnResizeMode="expand"
                        onSort={this.sortChange}
                        globalFilter={this.state.globalFilter}
                        filters={this.state.filters}
                        onFilter={this.onFilterChange}
                        scrollable={true}
                        selection={this.props.type === 'Clients' || this.props.type === 'matchedProps' || this.props.type === 'savedSearches' ? this.state.selectedRows : false}
                        onSelectionChange={e => this.selectRowsToDelete(e)}
                        scrollHeight="1000px" style={{ marginTop: '0px', }}
                        emptyMessage={this.state.isLoading ? configMessages.loading : configMessages.noRecords}
                        sortMode="single"
                        // selectionMode={'multiple'}
                        metaKeySelection={false}
                        virtualRowHeight={40}
                    >
                        {this.state.tablefields && this.state.tablefields.length > 0 ?
                            this.state.tablefields.map((item, i) => {
                                return <Column key={item.field + i}
                                    style={{
                                        maxwidth: item.width,
                                        padding: '4px',
                                    }}
                                    bodyStyle={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textAlign: item.textAlign ? item.textAlign : 'left'
                                    }}
                                    selectionMode={item.selectionMode}
                                    field={item.field}
                                    header={tl[item.header] ? tl[item.header] : item.header}
                                    body={this.changeFieldValues}
                                    headerStyle={{
                                        padding: '8px',
                                        fontWeight: '600',
                                        width: item.width,
                                        fontSize: '15px',
                                        color: 'white',
                                        backgroundColor: config.themeColor,
                                        textTransform: 'capitalize'
                                    }}
                                    filter={item.filter ? true : false}
                                    sortable={item.sortable ? true : false}
                                    filterPlaceholder={item.placeholder ? tl[item.placeholder] ? tl[item.placeholder] : item.placeholder : 'Search'}
                                    filterElement={item.filterElement ? item.field && (item.field === 'status' || item.field === 'projectName') ?
                                        <MultiSelect
                                            style={{
                                                width: '70px',
                                                lineHeight: 1.15,
                                                height: '30px'
                                            }}
                                            appendTo={document.body}
                                            name={item.field}
                                            placeholder={tl['tableFields.ph_none']}
                                            maxSelectedLabels={2}
                                            selectedItemsLabel='Aldfdefel'
                                            value={this.state[item.field] ? this.state[item.field] : item.defaultValues ? item.defaultValues : null}
                                            options={item.filterElement}
                                            onChange={(e) => this.onFilterChange(e, 'dropdownFilter')} /> :
                                        <Dropdown
                                            style={{
                                                minWidth: '10%',
                                                lineHeight: 1.15
                                            }}
                                            appendTo={document.body}
                                            key={item.filterElement}
                                            name={item.field}
                                            value={this.state[item.field] ? this.state[item.field] : null}
                                            options={item.filterElement}
                                            onChange={(e) => this.onFilterChange(e, 'dropdownFilter')} />
                                        : null}
                                />
                            })
                            : null}
                    </DataTable>
                    {/* Table Paginator Component */}
                    {(window.location.pathname !== '/dashboard' && window.location.pathname !== '/') ?
                        <Paginator
                            totalRecords={this.state.totalRecordsLength}
                            first={this.state.first}
                            rows={this.state.rows}
                            onPageChange={(e) => this.onPageChange(e, this.state.filterCriteria)}
                            style={{
                                padding: 10,
                                backgroundColor: 'rgba(0,0,0,.03)',
                                border: 'none'
                            }}
                        >
                        </Paginator>
                        : <div className='d-flex justify-content-end pr-3'>
                            <Link to="/RecentMatchedProperties">...More</Link>
                        </div>
                    }
                  
                </Col>
                <Col className='d-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none'>
                    <Row className='ml-sm-1'>
                        <Scrollbar className='myPropertiesListScroll'>
                            {(window.location.pathname === '/dashboard' || window.location.pathname === '/') ?
                                <div className={`row mx-0 mt-1 ${!this.state.allUsersData.length > 0 ? 'justify-content-center' : ''}`}>
                                    <Card className='border'>
                                        <div className='dashboard_card'>
                                            {'Recent MatchedProperties'}
                                        </div>
                                        {this.state.allUsersData && this.state.allUsersData.length > 0 ?
                                            this.state.allUsersData.map((item, index) => {
                                                return (
                                                    <div
                                                        className="col-md-12 col-lg-4 pb-2 pr-1 pl-2"
                                                        key={index}
                                                    >
                                                        <Card className='gridCard'>
                                                            <CardHeader className='p-0'>
                                                                <CheckBox
                                                                    className='row propertyCheckBox p-2'
                                                                    key={item._id}
                                                                    name={item._id}
                                                                    id={item._id}
                                                                    checked={this.state.selectedRowsId && this.state.selectedRowsId.includes(item._id)}
                                                                    onChange={(event) => this.handleCheckBox(event, item)}
                                                                />
                                                                <div className='gridActions' style={{ justifyContent: 'center' }}>
                                                                    {this.getGridActions(item)}
                                                                </div >
                                                            </CardHeader>
                                                            <CardBody className='gridCardBodyCss cursorPointer mt-4'>
                                                                {this.getTableValues(item)}
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                            })
                                            : <div className='row mx-0 d-flex justify-content-center emptyPropsContainer'>
                                                <div className='col my-auto'>
                                                    <div>
                                                        <div className='text-center'>
                                                            <h4>
                                                                {tl['noResultsFound'] ? tl['noResultsFound'] : 'No Results Found'}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className='d-flex justify-content-end pr-3'>
                                            <Link to="/RecentMatchedProperties">...More</Link>
                                        </div>
                                    </Card>
                                </div> :
                                <div className={`row mx-0 mt-1 ${!this.state.allUsersData.length > 0 ? 'justify-content-center' : ''}`}>
                                    {this.state.allUsersData && this.state.allUsersData.length > 0 ?
                                        this.state.allUsersData.map((item, index) => {
                                            return (
                                                <div
                                                    className="col-md-12 col-lg-4 pb-2 pr-1 pl-2"
                                                    key={index}
                                                >
                                                    {this.props.type === 'matchedProps' ?
                                                        <Card className='gridCard'>
                                                            <CardHeader className='p-0'>
                                                                <CheckBox
                                                                    className='row propertyCheckBox p-2'
                                                                    key={item._id}
                                                                    name={item._id}
                                                                    id={item._id}
                                                                    checked={this.state.selectedRowsId && this.state.selectedRowsId.includes(item._id)}
                                                                    onChange={(event) => this.handleCheckBox(event, item)}
                                                                />
                                                                <div className='gridActions' style={{ justifyContent: 'center' }}>
                                                                    {this.getGridActions(item)}
                                                                </div >
                                                            </CardHeader>
                                                            <Link to={{
                                                                pathname: `/propertyDetails/${item._id}`,
                                                                state: {
                                                                    tl: this.state.tl,
                                                                    fromScreen: window.location.pathname,
                                                                }
                                                            }}>
                                                                <CardBody className='gridCardBodyCss cursorPointer mt-4'>
                                                                    {this.getTableValues(item)}
                                                                </CardBody>
                                                            </Link>
                                                        </Card>
                                                        :
                                                        <Card className='gridCard'>
                                                            <CardHeader className='p-0'>
                                                                <CheckBox
                                                                    className='row propertyCheckBox p-2'
                                                                    key={item._id}
                                                                    name={item._id}
                                                                    id={item._id}
                                                                    checked={this.state.selectedRowsId && this.state.selectedRowsId.includes(item._id)}
                                                                    onChange={(event) => this.handleCheckBox(event, item)}
                                                                />
                                                                <div className='gridActions' style={{ justifyContent: 'center' }}>
                                                                    {this.getGridActions(item)}
                                                                </div >
                                                            </CardHeader>
                                                            <CardBody className='gridCardBodyCss cursorPointer mt-4'>
                                                                {this.getTableValues(item)}
                                                            </CardBody>
                                                        </Card>
                                                    }
                                                </div>
                                            )
                                        })
                                        : <div className='row mx-0 d-flex justify-content-center emptyPropsContainer'>
                                            <div className='col my-auto'>
                                                <div>
                                                    <div className='text-center'>
                                                        <h4>
                                                            {tl['noResultsFound'] ? tl['noResultsFound'] : 'No Results Found'}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </Scrollbar>
                    </Row>
                    {(window.location.pathname !== '/dashboard' && window.location.pathname !== '/') ?
                        <Paginator
                            totalRecords={this.state.totalRecordsLength}
                            first={this.state.first}
                            rows={this.state.rows}
                            onPageChange={(e) => this.onPageChange(e, this.state.filterCriteria)}
                            style={{
                                padding: 10,
                                backgroundColor: 'rgba(0,0,0,.03)',
                                border: 'none'
                            }}
                        >
                        </Paginator>
                        : null
                    }
                </Col>

                {/* Photos Viewer */}
                {this.state.isViewPhotosModalOpen ?
                    <ViewPhotosModal
                        isViewPhotosModalOpen={this.state.isViewPhotosModalOpen}
                        propertyPhotos={this.state.propertyPhotos}
                        closeViewPhotosModal={this.viewPhotosModal}
                    />
                    : null
                }

                {/* displaying add edit form */}
                {
                    this.state.isOpenFormModal || this.state.setFormRef ?
                        <FormModal
                            onRef={(ref) => (this.formModalRef = ref)}
                            openFormModal={this.state.isOpenFormModal}
                            closeFormModal={this.closeFormModal}
                            closeRejectModal={this.closeRejectModal}
                            type={this.props.type}
                            formType={this.state.formType}
                            formFields={this.props.formFields}
                            getTableListDataFromServer={this.getTableListDataFromServer}
                            apiUrl={this.state.apiUrl}
                            role={this.state.loginRole}
                        /> : null
                }

                {/* delete Selected row modal */}
                <ConfirmActionModal
                    openConfirmActionModal={this.state.openConfirmActionModal}
                    closeConfirmActionModal={this.closeConfirmActionModal}
                    deleteSelectedRow={this.deleteSelectedRow}
                    favSelectedClients={this.favSelectedClients}
                    actionType={this.state.actionType}
                    actionTitle={this.state.actionTitle}
                    infoTitle={this.state.infoTitle}
                    tl={tl}
                />

                {/* Client Add/Edit Form Modal */}
                {
                    this.state.isOpenClientsFormModal ?
                        <ClientsFormModal
                            onRef={(ref) => (this.clientsModalRef = ref)}
                            isOpenClientsFormModal={this.state.isOpenClientsFormModal}
                            closeClientsFormModal={this.closeClientsFormModal}
                            type={this.props.type}
                            formType={this.state.formType}
                            formFields={this.props.formFields}
                            clientRowData={this.state.clientRowData}
                            getTableListDataFromServer={this.getTableListDataFromServer}
                            apiUrl={this.state.apiUrl}
                            tl={tl}
                        />
                        : null
                }

                {/* Share Limit Crossed Alert */}
                {this.state.isOpenShareLimitModal ?
                    <ShareLimitModal
                        isOpenShareLimitModal={this.state.isOpenShareLimitModal}
                        closeShareLimitModal={() => { this.setState({ isOpenShareLimitModal: false }) }}
                        tl={tl}
                    />
                    : null
                }

                {/* Share Property Modal */}
                {this.state.isOpenSharePropsModal ?
                    <SharePropsModal
                        isOpenSharePropsModal={this.state.isOpenSharePropsModal}
                        selectedProperties={this.state.selectedProperties}
                        closeSharePropsModal={() => { this.setState({ isOpenSharePropsModal: false }) }}
                        tl={tl}
                    />
                    : null
                }

                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </Row >
        );
    }
}

const dropdownOptionsStyle = {
    padding: 5, fontSize: 11, fontWeight: '500', color: 'white'
}

export default withTranslation('common')(DataTables)
