export const SoldPriceOptions =[
    {"label": "5 Lacs", "value": "500000"},
    {"label": "10 Lacs", "value": "1000000"},
    {"label": "15 Lacs", "value": "1500000"},
    {"label": "20 Lacs", "value": "2000000"},
    {"label": "25 Lacs", "value": "2500000"},
    {"label": "30 Lacs", "value": "3000000"},
    {"label": "35 Lacs", "value": "3500000"},
    {"label": "40 Lacs", "value": "4000000"},
    {"label": "45 Lacs", "value": "4500000"},
    {"label": "50 Lacs", "value": "5000000"},
    {"label": "55 Lacs", "value": "5500000"},
    {"label": "60 Lacs", "value": "6000000"},
    {"label": "65 Lacs", "value": "6500000"},
    {"label": "70 Lacs", "value": "7000000"},
    {"label": "75 Lacs", "value": "7500000"},
    {"label": "80 Lacs", "value": "8000000"},
    {"label": "85 Lacs", "value": "8500000"},
    {"label": "90 Lacs", "value": "9000000"},
    {"label": "95 Lacs", "value": "9500000"},
    {"label": "1 Cr", "value": "10000000"},
    {"label": "1.05 Crs", "value": "10500000"},
    {"label": "1.1 Crs", "value": "11000000"},
    {"label": "1.15 Crs", "value": "11500000"},
    {"label": "1.2 Crs", "value": "12000000"},
    {"label": "1.25 Crs", "value": "12500000"},
    {"label": "1.3 Crs", "value": "13000000"},
    {"label": "1.35 Crs", "value": "13500000"},
    {"label": "1.4 Crs", "value": "14000000"},
    {"label": "1.45 Crs", "value": "14500000"},
    {"label": "1.5 Crs", "value": "15000000"},
    {"label": "1.55 Crs", "value": "15500000"},
    {"label": "1.6 Crs", "value": "16000000"},
    {"label": "1.65 Crs", "value": "16500000"},
    {"label": "1.7 Crs", "value": "17000000"},
    {"label": "1.75 Crs", "value": "17500000"},
    {"label": "1.8 Crs", "value": "18000000"},
    {"label": "1.85 Crs", "value": "18500000"},
    {"label": "1.9 Crs", "value": "19000000"},
    {"label": "2 Crs", "value": "20000000"},
    {"label": "2.1 Crs", "value": "21000000"},
    {"label": "2.2 Crs", "value": "22000000"},
    {"label": "2.3 Crs", "value": "23000000"},
    {"label": "2.4 Crs", "value": "24000000"},
    {"label": "2.5 Crs", "value": "25000000"},
    {"label": "2.6 Crs", "value": "26000000"},
    {"label": "2.7 Crs", "value": "27000000"},
    {"label": "2.8 Crs", "value": "28000000"},
    {"label": "2.9 Crs", "value": "29000000"},
    {"label": "3 Crs", "value": "30000000"},
    {"label": "3.1 Crs", "value": "31000000"},
    {"label": "3.2 Crs", "value": "32000000"},
    {"label": "3.3 Crs", "value": "33000000"},
    {"label": "3.4 Crs", "value": "34000000"},
    {"label": "3.5 Crs", "value": "35000000"},
    {"label": "3.6 Crs", "value": "36000000"},
    {"label": "3.7 Crs", "value": "37000000"},
    {"label": "3.8 Crs", "value": "38000000"},
    {"label": "3.9 Crs", "value": "39000000"},
    {"label": "4 Crs", "value": "40000000"},
    {"label": "4.1 Crs", "value": "41000000"},
    {"label": "4.2 Crs", "value": "42000000"},
    {"label": "4.3 Crs", "value": "43000000"},
    {"label": "4.4 Crs", "value": "44000000"},
    {"label": "4.5 Crs", "value": "45000000"},
    {"label": "4.6 Crs", "value": "46000000"},
    {"label": "4.7 Crs", "value": "47000000"},
    {"label": "4.8 Crs", "value": "48000000"},
    {"label": "4.9 Crs", "value": "49000000"},
    {"label": "5 Crs", "value": "50000000"},
    {"label": "5.25 Crs", "value": "52500000"},
    {"label": "5.5 Crs", "value": "55000000"},
    {"label": "5.75 Crs", "value": "57500000"},
    {"label": "6 Crs", "value": "60000000"},
    {"label": "6.25 Crs", "value": "62500000"},
    {"label": "6.5 Crs", "value": "65000000"},
    {"label": "6.75 Crs", "value": "67500000"},
    {"label": "7 Crs", "value": "70000000"},
    {"label": "7.25 Crs", "value": "72500000"},
    {"label": "7.5 Crs", "value": "75000000"},
    {"label": "7.75 Crs", "value": "77500000"},
    {"label": "8 Crs", "value": "80000000"},
    {"label": "8.25 Crs", "value": "82500000"},
    {"label": "8.5 Crs", "value": "85000000"},
    {"label": "8.75 Crs", "value": "87500000"},
    {"label": "9 Crs", "value": "90000000"},
    {"label": "9.25 Crs", "value": "92500000"},
    {"label": "9.5 Crs", "value": "95000000"},
    {"label": "9.75 Crs", "value": "97500000"},
    {"label": "10 Crs", "value": "100000000"},
    {"label": "20 Crs", "value": "200000000"},
    {"label": "30 Crs", "value": "300000000"},
    {"label": "40 Crs", "value": "400000000"},
    {"label": "50 Crs", "value": "500000000"},
    {"label": "60 Crs", "value": "600000000"},
    {"label": "70 Crs", "value": "700000000"},
    {"label": "80 Crs", "value": "800000000"},
    {"label": "90 Crs", "value": "900000000"},
    {"label": "100 Crs", "value": "1000000000"},
    {"label": "100+ Crs", "value": "1000000000+"}
]

export const RentalPriceOptions = [
    {"label": "1,000", "value": "1000"},
    {"label": "2,000", "value": "2000"},
    {"label": "3,000", "value": "3000"},
    {"label": "4,000", "value": "4000"},
    {"label": "5,000", "value": "5000"},
    {"label": "6,000", "value": "6000"},
    {"label": "7,000", "value": "7000"},
    {"label": "8,000", "value": "8000"},
    {"label": "9,000", "value": "9000"},
    {"label": "10,000", "value": "10000"},
    {"label": "11,000", "value": "11000"},
    {"label": "12,000", "value": "12000"},
    {"label": "13,000", "value": "13000"},
    {"label": "14,000", "value": "14000"},
    {"label": "15,000", "value": "15000"},
    {"label": "16,000", "value": "16000"},
    {"label": "17,000", "value": "17000"},
    {"label": "18,000", "value": "18000"},
    {"label": "19,000", "value": "19000"},
    {"label": "20,000", "value": "20000"},
    {"label": "21,000", "value": "21000"},
    {"label": "22,000", "value": "22000"},
    {"label": "23,000", "value": "23000"},
    {"label": "24,000", "value": "24000"},
    {"label": "25,000", "value": "25000"},
    {"label": "26,000", "value": "26000"},
    {"label": "27,000", "value": "27000"},
    {"label": "28,000", "value": "28000"},
    {"label": "29,000", "value": "29000"},
    {"label": "30,000", "value": "30000"},
    {"label": "35,000", "value": "35000"},
    {"label": "40,000", "value": "40000"},
    {"label": "45,000", "value": "45000"},
    {"label": "50,000", "value": "50000"},
    {"label": "55,000", "value": "55000"},
    {"label": "60,000", "value": "60000"},
    {"label": "65,000", "value": "65000"},
    {"label": "70,000", "value": "70000"},
    {"label": "75,000", "value": "75000"},
    {"label": "80,000", "value": "80000"},
    {"label": "85,000", "value": "85000"},
    {"label": "90,000", "value": "90000"},
    {"label": "95,000", "value": "95000"},
    {"label": "1 Lac", "value": "100000"},
    {"label": "1.1 Lacs", "value": "110000"},
    {"label": "1.2 Lacs", "value": "120000"},
    {"label": "1.3 Lacs", "value": "130000"},
    {"label": "1.4 Lacs", "value": "140000"},
    {"label": "1.5 Lacs", "value": "150000"},
    {"label": "1.6 Lacs", "value": "160000"},
    {"label": "1.7 Lacs", "value": "170000"},
    {"label": "1.8 Lacs", "value": "180000"},
    {"label": "1.9 Lacs", "value": "190000"},
    {"label": "2 Lacs", "value": "200000"},
    {"label": "2.25 Lacs", "value": "225000"},
    {"label": "2.5 Lacs", "value": "250000"},
    {"label": "2.75 Lacs", "value": "275000"},
    {"label": "3 Lacs", "value": "300000"},
    {"label": "3.25 Lacs", "value": "325000"},
    {"label": "3.5 Lacs", "value": "350000"},
    {"label": "3.75 Lacs", "value": "375000"},
    {"label": "4 Lacs", "value": "400000"},
    {"label": "4.25 Lacs", "value": "425000"},
    {"label": "4.5 Lacs", "value": "450000"},
    {"label": "4.75 Lacs", "value": "475000"},
    {"label": "5 Lacs", "value": "500000"},
    {"label": "6 Lacs", "value": "600000"},
    {"label": "7 Lacs", "value": "700000"},
    {"label": "8 Lacs", "value": "800000"},
    {"label": "9 Lacs", "value": "900000"},
    {"label": "10 Lacs", "value": "1000000"},
    {"label": "10+ Lacs", "value": "1000000+"}
]
