/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom'

// Icons
import UsersIcon from 'mdi-react/AccountMultipleIcon';
import PropertiesIcon from 'mdi-react/HomeGroupIcon';
import PropertiesSearchIcon from 'mdi-react/SearchIcon';
import HomeSearchIcon from 'mdi-react/HomeHeartIcon'

// i18 Translation Provider
import { withTranslation } from 'react-i18next';
class CountDisplay extends PureComponent {

	render() {
		const { data, tl, t } = this.props;
		const CardData = [
			{
				pathname: 'my_properties',
				state: { tl: tl, pageLoad: true },
				icon: 'propertiesIcon',
				title: 'myProperties',
				count: data.noOfPropertiesCount,
				color: 'lightBlue'
			},
			{
				pathname: 'clients',
				state: { tl: tl },
				icon: 'usersIcon',
				title: 'myClients',
				count: data.noOfclientsCount,
				color: 'lightGreen'
			},
			{
				pathname: 'savedSearchesList',
				state: { tl: tl },
				icon: 'propertiesSearchIcon',
				title: 'mySavedSearches',
				count: data.noOfclientCriteriaCount,
				color: 'lightgrey'
			},
			{
				pathname: 'matchedPropertiesList',
				state: { tl: tl },
				icon: 'homeSearchIcon',
				title: 'matchedProps',
				count: data.noOfMatchedPropertiesCount,
				color: 'lightsteelblue'
			}
		];
		return (
			<Row className='dashboard_cards'>
				{CardData && CardData.length > 0 ?
					CardData.map((item, index) => {
						return (
							<Col xs={12} md={3} key={index}>
								<Link
									to={{
										pathname: item.pathname,
										state: item.state,
									}}
								>
									<Card>
										<CardBody
											className="dashBoardCountCardCss text-center" style={{ backgroundColor : item.color}}
										>
											{item.icon === 'propertiesIcon' ?
												<PropertiesIcon
													className='dashboardCountIcon'
												/>
												: item.icon === 'usersIcon' ?
													<UsersIcon
														className='dashboardCountIcon'
													/>
													: item.icon === 'propertiesSearchIcon' ?
														<PropertiesSearchIcon
															className='dashboardCountIcon'
														/>
														: item.icon === 'homeSearchIcon' ?
															<HomeSearchIcon
																className='dashboardCountIcon'
															/>
															: null
											}
											<div className='dashboardCountTitle'>
												{tl && tl[item.title] ? tl[item.title] : item.title}
											</div>
											<div className='dashboardCountNumber'>
												{item.count}
											</div>
										</CardBody>
									</Card>
								</Link>
							</Col>
						)
					})
					: null
				}
			</Row>
		);
	}
}

export default withTranslation('common')(CountDisplay)