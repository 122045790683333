import React from "react";
import TableRender from '../../Tables/CommonDataTable/TableRender';

class RecentMatchedProperties extends React.Component {

    getTableFields = async () => {
        let data = [
            { textAlign: 'center', width: 8, selectionMode: 'multiple', field: 'Sno', filter: false, sortable: false },
            { textAlign: 'center', width: 7, field: 'Sno', header: 'sno' },
            { textAlign: 'center', width: 15, field: 'clientName', header: 'Client Name', filter: false, showInMoblie: true},
            { textAlign: 'center', width: 15, field: 'searchName', header: 'Search Name', filter: false, showInMoblie: true },
            { textAlign: 'center', width: 15, field: 'addedProperties', header: 'Properties Count', filter: false, showInMoblie : true },
            { textAlign: 'center', width: 15, field: 'addedDate', header: 'Date', showInMoblie : true },
        ];
        return data;
    };

    render() {
        const { tl } = this.props;
        return (
            <TableRender
                type='recentMatches'
                tableFields={() => this.getTableFields()}
                tl={tl}
            />
        );
    }
}
export default RecentMatchedProperties;