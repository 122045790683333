const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const asyncValidate = (values) => {
    let priceError = { totalPriceMax: 'Max Budget must be more than Min' };
    let areaError = { builtUpAreaMax: 'Max Area must be more than Min' };
    return sleep(100).then(() => {
        // simulate server latency
        if (values.totalPriceMin && values.totalPriceMax) {
            let minP = parseInt(values.totalPriceMin.replace(/,/g, ''));
            let maxP = parseInt(values.totalPriceMax.replace(/,/g, ''));
            if (minP >= maxP) {
                throw priceError;
            }
        }

        if (values.builtUpAreaMin && values.builtUpAreaMax) {
            let minB = parseInt(values.builtUpAreaMin.replace(/,/g, ''));
            let maxB = parseInt(values.builtUpAreaMax.replace(/,/g, ''));
            if (minB >= maxB) {
                throw areaError;
            }
        }
    })
}

export default asyncValidate;
