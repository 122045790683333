const LOAD_CLIENT_DATA = 'LOAD_CLIENT_DATA';

const clientFormReducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD_CLIENT_DATA:
            return {
                data: action.data
            }
        default:
            return state
    }
}
/**
 * Simulates data loaded into this reducer from somewhere
 */
// This export : 'loadClientData' is used in Client Form for import
export const loadClientData = data => ({ type: LOAD_CLIENT_DATA, data })

/**
This export : 'clientFormReducer' is 
imported in reducers index file, from there 
it is imported into redux store 
*/
export default clientFormReducer