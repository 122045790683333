import React, { PureComponent } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

class SessionExpiredModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLogoutSuccess: false,
        };
    }
    // Logout confirmation handler
    handleLogout = () => {
        this.setState({
            isLogoutSuccess: true
        })
        localStorage.removeItem('loginCredentials');
        localStorage.removeItem('loginBody');
    }

    render() {
        const { tl, isOpenSessionExpiredModal, t } = this.props;
        return (
            <div>
                <Modal
                    isOpen={isOpenSessionExpiredModal}
                    className={`modal-dialog invalidFilterModal modal-dialog-centered modal-dialog--primary formModalBorderRadius`}
                >
                    <ModalBody>
                        <h4 className='mb-3'>
                            {tl['sessionExpiredConfirmation'] ?
                                tl['sessionExpiredConfirmation']
                                : 'Due to inactivity your session has expired. Please Login again.'
                            }
                        </h4>
                        <Button
                            color="primary"
                            onClick={this.handleLogout}
                            className='mb-0'
                        >
                            {tl['ok'] ? tl['ok'] : 'OK'}
                        </Button>
                    </ModalBody>
                </Modal>
                {/* Redirect component to Login Screen */}
                {this.state.isLogoutSuccess ?
                    <Redirect
                        to={{
                            pathname: '/log_in',
                            state: {
                                tl: tl,
                            }
                        }}
                    />
                    : null
                }
            </div>
        )
    }
}

export default withTranslation('common')(SessionExpiredModal)