import React, { PureComponent } from 'react';
import { CardBody } from 'reactstrap';
import RecentMatchedProperties from './components/RecentMatchedProperties';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

class RecentMatchedPropertiesTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tl: {},
        };
    }

    componentDidMount = async () => {
        let tl = {}
        if (this.props.location && this.props.location.state && this.props.location.state.tl) {
           tl = this.props.location.state.tl
        } else {
            tl = await JSON.parse(localStorage.getItem('langJson'));
        }
        await this.setState({
            tl: tl
        })
    }

    render() {
        const { tl } = this.state;
        const { t } = this.props;
        return (
            <CardBody className='p-0'>
                <div className='hideInMobile text-center pt-2 pageHeadingCss'>
                    {tl['recentMatchedProperties'] ? tl['recentMatchedProperties'] : 'Recent MatchedProperties'}
                </div>
                <RecentMatchedProperties tl={tl} />
            </CardBody>
        );
    }
}

export default withTranslation('common')(RecentMatchedPropertiesTable)
