import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

class ConfirmActionModal extends React.Component {

    confirmAction = async () => {
        if (this.props.actionType && this.props.actionType === 'fav') {
            this.props.favSelectedClients('fav');
        } else if (this.props.actionType && this.props.actionType === 'unFav') {
            this.props.favSelectedClients('unFav');
        } else if (this.props.actionType && this.props.actionType === 'updateProperty') {
            this.props.UpdateSelectedProperty();
        } else if (this.props.actionType && this.props.actionType === 'deleteProperty') {
            this.props.deleteSelectedRow();
        } else {
            this.props.deleteSelectedRow();
        }
    }

    render() {
        const { t, tl } = this.props;
        return (
            <div>
                <Modal
                    isOpen={this.props.openConfirmActionModal}
                    className={`modal-dialog invalidFilterModal modal-dialog-centered modal-dialog--primary formModalBorderRadius`}
                >
                    <ModalBody className='deleteModalBody'>
                        {this.props.infoTitle ?
                            <h6>
                                {tl[this.props.infoTitle] ?
                                    tl[this.props.infoTitle]
                                    : 'You have selected both Favourite and Un-Favoutite'
                                }
                            </h6>
                            : null
                        }
                        {tl[this.props.actionTitle] ?
                            <h4 className='mb-3'>
                                {tl[this.props.actionTitle] ?
                                    tl[this.props.actionTitle]
                                    : 'Are you sure, You want to confirm action on selected Item'
                                } ?
                            </h4>
                            : null
                        }
                        {this.props.actionType && this.props.actionType === 'updateProperty' ?
                            <h4 className='mb-3'>
                                {"Are you sure, You want to ReNew the Property"} ?
                            </h4>
                            : null
                        }
                        <Button
                            color="primary"
                            onClick={this.props.closeConfirmActionModal}
                            className='mx-1'
                        >
                            {tl['no'] ? tl['no'] : 'No'}
                        </Button>
                        <Button
                            color="primary"
                            className='mx-1'
                            onClick={this.confirmAction}
                        >
                            {tl['yes'] ? tl['yes'] : 'Yes'}
                        </Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
export default withTranslation('common')(ConfirmActionModal)