import Moment from 'moment';
import config from '../../../config/config'

const removeZFromDate = (specificDate) => {
    let newDate = JSON.stringify(specificDate);
    return JSON.parse(newDate.replace('Z', ''));
}
export default {
    formatDate: (specificDate, expectedformat = config.dbDateFormat, dmy) => {
        if (specificDate === 'todayDate') {
            return Moment().tz(config.ESTTimezone).format(expectedformat); // est time
        } else if (specificDate === 'UTCTimeNow') {
            return Moment.utc().format(expectedformat); // utc time
        } else {
            if (specificDate) {
                let newDate = removeZFromDate(specificDate);
                if (dmy) {
                    return Moment(newDate, dmy).format(expectedformat); // selected date time
                } else {
                    return Moment(newDate).format(expectedformat); // selected date time
                }

            } else {
                return null;
            }
        }
    },
    datesComparisionSame: (newDate, currentDate) => {
        if (newDate && currentDate) {
            let newDateModified = removeZFromDate(newDate);
            let currentDateModified = removeZFromDate(currentDate);
            return Moment(newDateModified).isSame(currentDateModified);
        } else {
            return null;
        }
    },
    addDaysToDate: (date, days) => {
        if (date) {
            let newDate = removeZFromDate(date);
            let dateAfterDays = Moment(newDate).add(days, 'days').format('YYYY-MM-DD HH:mm:ss');
            return dateAfterDays;
        } else {
            return
        }
    },
    datesComparisionSameOrBefore: (newDate, currentDate) => {

        if (newDate && currentDate) {
            let newDateModified = removeZFromDate(newDate);
            let currentDateModified = removeZFromDate(currentDate);
            return Moment(newDateModified).isSameOrBefore(currentDateModified);
        } else {
            return null;
        }
    },
    datesComparisionBefore: (newDate, currentDate) => {

        if (newDate && currentDate) {
            let newDateModified = removeZFromDate(newDate);
            let currentDateModified = removeZFromDate(currentDate);
            return Moment(newDateModified).isBefore(currentDateModified);
        } else {
            return null;
        }
    },
    getYearsFromDate: (date) => {
        let age = Moment().diff(date, 'years')
        return age.toString();
    },
    differenceBetweenDatesInYears: (date1, date2) => {
        let age = Moment(date1).diff(Moment(date2), 'years');
        return age;
    },
    dateIsValid: (date) => {
        if (Moment(date, config.dateDmyFormat).isValid()) {
            return true;
        } else {
            return false;
        }
    },
    isHowLong: (date) => {
        let howLong;
        if (date) {
            howLong = Moment().diff(date, 'minutes');
            if (howLong < 60) {
                howLong = howLong.toString() + ` ${howLong === 1 ? 'Minute' : 'Minutes'} ago`;
            } else {
                howLong = Moment().diff(date, 'hours');
                if (howLong < 24) {
                    howLong = howLong.toString() + ` ${howLong === 1 ? 'Hour' : 'Hours'} ago`;
                } else {
                    howLong = Moment().diff(date, 'days');
                    if (howLong < 31) {
                        howLong = howLong.toString() + ` ${howLong === 1 ? 'Day' : 'Days'} ago`;
                    } else {
                        howLong = Moment().diff(date, 'months');
                        if (howLong < 12) {
                            howLong = howLong.toString() + ` ${howLong === 1 ? 'Month' : 'Months'} ago`;
                        } else {
                            howLong = Moment().diff(date, 'years');
                            howLong = howLong.toString() + ` ${howLong === 1 ? 'Year' : 'Years'} ago`;
                        }
                    }
                }
            }
            return howLong;
        }
    }
};