import React from 'react';
import ResetPasswordPage from './components/ResetPassword';

const ResetPassword = (props) => (
    <div className="account">
        <div className="account__wrapper">
            <div className="account__card">
                <ResetPasswordPage {...props} onSubmit />
            </div>
        </div>
    </div>
);

export default ResetPassword;
