import React from 'react';
import { Card, CardBody } from 'reactstrap';

import TranslationTool from '../Translation/TranslationTool';
import Profile from '../Account/Profile/components/Profile';
import ChangePassword from '../Account/ChangePassword/components/ChangePassword';

import Loader from '../App/Loader';

import SessionExpireModal from '../SessionExpire/sessionExpireModal';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount = async () => {
        localStorage.removeItem('myPropsSelected')
        localStorage.removeItem('searchPropsSelected')
        localStorage.removeItem('matchedPropsSelected')

        localStorage.removeItem('myIsGlobalChecked')
        localStorage.removeItem('searchIsGlobalChecked')
        localStorage.removeItem('matchedIsGlobalChecked')

        localStorage.removeItem('myPropsDeselected');
        localStorage.removeItem('searchPropsDeselected');
        localStorage.removeItem('matchedPropsDeselected');
    }

    render() {
        const { t, tl } = this.props;
        return (
            <div>
                <Loader loader={this.state.isLoading} />
                <div className="row mx-0">
                    <div className="col-12 px-0">
                        <div className="hideInMobile col-12 my-2 text-center pageHeadingCss">
                            {tl && tl['settings'] ? tl['settings'] : 'Settings'}
                        </div>
                        <div className="col-lg-4 col-md-5 col-sm-6 ">
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <h5 className='mr-2'>
                                            {tl && tl['changeLanguage'] ? tl['changeLanguage'] : 'Change Language'}
                                        </h5>
                                        <TranslationTool />
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>

                    <div className='col-md-8 col-sm-6 px-0'>
                        <Profile />
                    </div>

                    <div className='col-md-4 col-sm-6 px-0'>
                        <ChangePassword />
                    </div>
                </div>
                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                    />
                    : null
                }
            </div >
        );
    }
}

export default withTranslation('common')(Dashboard);