import React from 'react';
import { converter, SYSTEM } from '@kushalst/numbers-to-words-converter';
import config from '../../../config/config';

// config file
export default class DefaultInput extends React.Component {

    handleChange = (event) => {
        if (this.props.handlerFromParent) {
            this.setState({ number: event.target.value })
            this.props.handlerFromParent(event.target.value, event.target.name);
        }
    }

    render() {
        const { input, placeholder, type, isDisable, meta: { asyncValidating, touched, error }, min, max, className, displayPrice } = this.props;
        //remove , in number
        let number = '';
        if (input && input.value) {
            input.value = ''+input.value
            number = input.value.replace(/,/g, '')
        }

        const parsedNumber = Number(number);
        const limit = 1e12; // 1 lakh crore

        return (
            <div className="form__form-group-input-wrap">
                <div className="form__form-group-field">
                    <div className={asyncValidating ? 'async-validating w-100' : 'w-100'}>
                        <input
                            {...input}
                            className={className ? className + ' inputfield' : 'inputfield'}
                            style={isDisable ? { background: 'lightgray', border: "none" } : null}
                            disabled={isDisable}
                            placeholder={placeholder}
                            // onChange={this.handleChange}
                            type={type}
                            min={min ? min : null}
                            max={max ? max : null}
                        />
                    </div>
                </div>
                {displayPrice && !isNaN(parsedNumber) && parsedNumber <= limit && parsedNumber > 0 ?
                    <span style={{ fontSize: '12px', color: config.themeGray, textTransform: 'capitalize' }}>
                        {converter.toWords(parsedNumber.toString(), SYSTEM.IN)}
                    </span>
                    : ''
                }
                {touched && error && <span className="form__form-group-error">{error}</span>}
            </div>
        );
    }
}