import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';
import PropTypes from 'prop-types';

// Toaster message
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

// Input render
import DefaultInput from '../../../../shared/components/form/DefaultInput';

// Validate 
import validate from '../../../Validations/validate';

// Loader
import Loader from '../../../App/Loader';

import fetch from '../../../../config/service';
import SessionExpireModal from '../../../SessionExpire/sessionExpireModal';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

const currentRequired = value => (value || typeof value === 'string' ? undefined : 'Current Password is required')
const newRequired = value => (value || typeof value === 'string' ? undefined : 'New Password is required')
const confirmRequired = value => (value || typeof value === 'string' ? undefined : 'Confirm Password is required')

class ChangePassword extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    // Handle submit onClick
    submit = async (values) => {
        this.setState({ isLoading: true });
        let userData = localStorage.getItem('loginCredentials');
        userData = JSON.parse(userData);
        let userBody = {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
            _id: userData._id,
        }
        return fetch('POST', `auth/changePassword`, userBody)
            .then(async (response) => {
                if (response && response.respCode) {
                    // display message
                    this.setState({ isLoading: false });
                    showToasterMessage(response.respMessage, 'success');
                } else {
                    if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(response.errorMessage, 'error');
                    }
                    this.props.reset();
                }
            }).catch((err) => {
                return err;
            });
    }

    resetForm = async () => {
        this.props.initialize()
    }

    render() {
        const { handleSubmit, tl, t } = this.props;
        return (
            <Col md={12} lg={12}>
                <Loader loader={this.state.isLoading} />
                <Card>
                    <CardHeader style={{ fontSize: 20 }}>
                        <div className="text-center">
                            <h5 className='blackText textUpperCase'>
                                {tl && tl['changePassword'] ? tl['changePassword'] : 'Change Password'}
                            </h5>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <form className="form row" onSubmit={handleSubmit(this.submit)}>
                            <div className="form__form-group col-md-12">
                                <span className="form__form-group-label">
                                    {tl && tl['currentPassword'] ? tl['currentPassword'] : 'Current Password'} *
                                    </span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="currentPassword"
                                        component={DefaultInput}
                                        type="password"
                                        placeholder={tl && tl['currentPassword'] ? tl['currentPassword'] : 'Current Password'}
                                        validate={currentRequired}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group col-md-12">
                                <span className="form__form-group-label">
                                    {tl && tl['newPassword'] ? tl['newPassword'] : 'New Password'} *
                                </span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="newPassword"
                                        component={DefaultInput}
                                        type="password"
                                        placeholder={tl && tl['newPassword'] ? tl['newPassword'] : 'New Password'}
                                        validate={newRequired}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group col-md-12">
                                <span className="form__form-group-label">
                                    {tl && tl['confirmPassword'] ? tl['confirmPassword'] : 'Confirm Password'} *
                                    </span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="confirmPassword"
                                        component={DefaultInput}
                                        type="password"
                                        placeholder={tl && tl['confirmPassword'] ? tl['confirmPassword'] : 'Confirm Password'}
                                        validate={confirmRequired}
                                    />
                                </div>
                            </div>


                            {/* Sign In Button*/}
                            <div className="form__form-group col-md-12 text-right mt-2 mb-0">
                                <Button
                                    className="mb-0"
                                    color='primary'
                                    type='submit'
                                >
                                    {tl && tl['submit'] ? tl['submit'] : 'Submit'}
                                </Button>
                                <Button
                                    className="mb-0"
                                    color='primary'
                                    type='reset'
                                    onClick={this.resetForm}
                                >
                                    {tl && tl['reset'] ? tl['reset'] : 'Reset'}
                                </Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </Col >
        );
    }
}

ChangePassword = reduxForm({
    form: 'ChangePasswordFormmmmm', // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(ChangePassword);


export default withTranslation('common')(ChangePassword);