import React, { PureComponent } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarProfile from './TopbarProfile';
import fetchMethodRequest from '../../../config/service';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';
class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      loginUserRole: '',
      tl: JSON.parse(localStorage.getItem('langJson')) ? JSON.parse(localStorage.getItem('langJson')) : {},
      lang: (localStorage.getItem('lang') && localStorage.getItem('lang') !== 'undefined') ? localStorage.getItem('lang') : null
    }

  }

  componentDidMount = async () => {
    await this.changeLanguage();
  }

  // Get lang JSON
  changeLanguage = async (lan) => {
    let lang;
    if (lan) {
      lang = lan;
    } else if (this.state.lang) {
      lang = this.state.lang
    } else {
      lang = 'en'
    }

    let url = `auth/languages?language=${lang}`;
    fetchMethodRequest('GET', url).then(async resp => {
      if (resp) {
        await localStorage.setItem('lang', lang);
        await localStorage.setItem('langJson', JSON.stringify(resp));
        await this.setState({ tl: resp, lang: lang })
        return;
      } else {
        return;
      }
    }).catch(err => {
      return err;
    })

  }

  handleBackClick = () => {
    this.props.history.goBack();
  };

  getPageHeading = (tl) => {
    let currentPath = window.location.pathname;
    const headings = {
      '/': tl && tl['dashboard'] || 'Dashboard',
      '/dashboard': tl && tl['dashboard'] || 'Dashboard',
      '/add_property': tl && tl['addProperty'] || 'Add Property',
      '/settings': tl && tl['settings'] || 'Settings',
      '/clients': tl && tl['clients'] || 'Clients',
      '/savedSearchesList': tl && tl['savedSearches'] || 'Saved Searches',
      '/matchedPropertiesList': tl && tl['matchedProps'] || 'Matched Properties',
      '/my_properties': tl && tl['myProperties'] || 'My Properties',
      '/matched_properties': tl && tl['matchedProps'] || 'Matched Properties',
      '/search_properties': tl && tl['searchProperties'] || 'Search Properties',
      '/RecentMatchedProperties': 'Recent MatchedProperties',
      '/show_matched_properties' : tl && tl['matchedProps'] || 'Matched Properties'
    };
    if (currentPath.match(/^\/properties\/[a-fA-F0-9]{24}$/)) {
      return tl && tl['editProperty'] || 'Edit Property';
    }
    return headings[currentPath] || null;
  };

  render() {
    const { tl } = this.state;
    const { t } = this.props;
    let currentPath = window.location.pathname;
    return (
      <div className="topbar">
        <div className="topbar__wrapper topbar_wrapper_mobile">
          <div className="topbar__left d-none d-md-block">
            <NavLink className="topbar__logo ml-3" to="dashboard" />
          </div>
          {currentPath && (currentPath !== '/dashboard') &&
            <div className='topbar_arrow d-block d-md-none' onClick={this.handleBackClick}>
              <ChevronLeftIcon className='large-icon' />
            </div>
          }
          <div className="mobileView_topbar d-flex d-md-none justify-content-center align-items-center">
            <span>{this.getPageHeading(tl)}</span>
          </div>
          <div className="topbar__right">
            <NavLink activeClassName='activeTopBar' className="hideInMobile topbar_navlink textUpperCase py-auto" to='/dashboard'>{tl['home'] ? tl['home'] : 'HOME'}</NavLink>
            <NavLink activeClassName='activeTopBar' className="hideInMobile topbar_navlink textUpperCase py-auto" to='/my_properties'>{tl['myProperties'] ? tl['myProperties'] : 'MY PROPERTIES'}</NavLink>
            <NavLink activeClassName='activeTopBar' className="hideInMobile topbar_navlink textUpperCase py-auto" to="/search_properties">{tl['search'] ? tl['search'] : 'SEARCH'}</NavLink>
            <NavLink activeClassName='activeTopBar' className="hideInMobile topbar_navlink textUpperCase py-auto" to="/clients">{tl['clients'] ? tl['clients'] : 'CLIENTS'}</NavLink>

            <TopbarProfile changeLanguage={this.changeLanguage} tl={tl} />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(withRouter(Topbar));
