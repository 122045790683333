/* eslint indent: "off" */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

//Page Layout
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';

// Account Related
import ChangePassword from '../Account/ChangePassword';
import ForgotPassword from '../Account/ForgotPassword';
import ResetPassword from '../Account/ResetPassword';
import Profile from '../Account/Profile';
import Settings from '../Settings/settings';

// Landing_Login
import Login from '../LogIn';

// DashBoard
import Dashboard from '../Dashboard/index';

//Registration
import Registration from '../Registration';

// Agent Screens
import MyProperties from '../Properties/components/commonProperties';
import AddProperty from '../Properties/components/addProperty';
import Clients from '../Clients/index';
import ClientDetails from '../Clients/components/ClientDetails';
import PropertyDetails from '../Properties/components/PropertyDetails';
import RecentMatchedProperties from '../RecentMatchedPropertiesList/index';

// Error : Page Not Found
import ErrorNotFound from './404'
import MatchedPropsList from '../MatchedProperties/index';
import SavedSearchesList from '../SavedSearches/index';

const wrappedRoutes = () => {
  if (localStorage.getItem('loginCredentials')) {
    return <div>
      <Layout />
      <div className="container__wrap">
        <Route exact path="/dashboard" component={Dashboard} />
        <Route path="/my_properties" component={MyProperties} />
        <Route path="/show_matched_properties" component={MyProperties} />
        <Route path="/search_properties" component={MyProperties} />
        <Route path="/matched_properties" component={MyProperties} />
        <Route path="/add_property" component={AddProperty} />
        <Route path="/properties/:id" component={AddProperty} />
        <Route path="/propertyDetails/:id" component={PropertyDetails} />
        <Route path="/clientDetails/:id" component={ClientDetails} />
        <Route path="/clients" component={Clients} />
        <Route path="/profile" component={Profile} />
        <Route path="/changepassword" component={ChangePassword} />
        <Route path="/settings" component={Settings} />
        <Route path="/matchedPropertiesList" component={MatchedPropsList} />
        <Route path="/savedSearchesList" component={SavedSearchesList} />
        <Route path="/RecentMatchedProperties" component={RecentMatchedProperties} />
        <Route exact path="/" component={Dashboard} />
      </div>
    </div>
  } else {
    return <Route path="*" component={ErrorNotFound} />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/changerecoverpassword/:encodedEmailId/:active" component={ResetPassword} />
        <Route path="/changerecoverpassword/:encodedEmailId" component={ResetPassword} />
        <Route path="/register" component={Registration} />
        <Route path="/forgot_password" component={ForgotPassword} />
        {(localStorage.getItem('loginCredentials') && localStorage.getItem('loginCredentials') !== 'undefined') ?
          null :
          <Route exact path="/" component={Login} />
        }
        <Route path="/log_in" component={Login} />
        <Route path="/" component={wrappedRoutes} />
        <Route path="*" component={ErrorNotFound} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
