import React, { PureComponent } from 'react';
import ForgotPasswordPage from './components/ForgotPassword';

class ForgotPassword extends PureComponent {

    render() {
        return (
            <div className="account">
                <div className="account__wrapper">
                    <div className="account__card">
                        <ForgotPasswordPage onSubmit />
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword;
