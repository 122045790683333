import React from 'react';
import {
    Button, Modal, ModalHeader, ModalBody,
    Card, CardBody, ButtonToolbar
} from 'reactstrap';
import fetch from '../../config/service';
import classNames from 'classnames';

//components
import AutoComplete from './components/AutoComplete';

import Select from '../../shared/components/form/Select';
import RadioButton from '../../shared/components/form/RadioButton';
import DefaultInput from '../../shared/components/form/DefaultInput';
import TimePicker from '../../shared/components/form/TimePicker';
import DefaultTextArea from '../../shared/components/form/DefaultTextArea';
import Editor from '../../containers/Form/components/Editor';
import config from '../../config/config';
import configMessages from '../../config/configMessages';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import dateFormats from '../UI/FormatDate/formatDate';
import validate from '../Validations/validate';
import DatePicker from '../../shared/components/form/DatePicker';
import CheckBox from '../../shared/components/form/CheckBox';
import RenderFileInputField from '../Form/components/FileUpload';
import { MultiSelect } from 'primereact/multiselect';
import { load as loadAccount } from './../../redux/reducers/commonReducer';

import CrossCircleIcon from 'mdi-react/RemoveCircleIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Loader from '../App/Loader';

import SessionExpireModal from '../SessionExpire/sessionExpireModal';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)

//tooltip styles
const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: config.themeColor,
        color: '#fff',
        boxShadow: theme.shadows[10],
        fontSize: 13,
        fontWeight: '400',
        margin: 0
    },

}))(Tooltip);

class FormModal extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            formFields: [],
            formValues: {},
            selectedValue: '',
            DOB: '',
            checkedValues: [],
            colored: false,
            header: true,
            assignedEmps: [],
            isLoading: false,
            checked: false,
            currentDate: '',
            inventoryDetails: {},
            inventoryHistory: [],
            inventoryComments: [],
            inventoryAttachments: [],
            activeTab: '2',
            isClientAdded: false,
            aadharError: false,
            taskError: false,
        };
        this.props.load({});
    }
    toggle = (tab) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };
    componentDidMount = async () => {
        await this.props.onRef(this);
        await this.props.load({});
        await this.getFormFields();
    }

    getFormFields = async (type) => {
        if (this.props.formFields) {
            let formFields = this.props.formFields();

            await this.setState({ formFields: formFields });
        }
    }
    save = (type) => {
        if (type === 'save') {
            this.setState({ isSaveAndAdd: false });
        } else {
            this.setState({ isSaveAndAdd: true });
        }
    }

    //get leaves form data
    formattedLeavesFormData = () => {
        let formFields = [...this.state.formFields]
        let halfDayType = [
            { label: 'First Half', value: 'First half' },
            { label: 'Second Half', value: 'Second half' },
        ];
        let index = formFields.findIndex(field => field.name === 'toDate');
        formFields.splice(index, 1);
        formFields.splice(3, 0,
            { required: true, value: '', type: 'dropDown', name: 'halfDayType', label: 'Half Day Type', options: halfDayType, id: 'halfDayType', placeholder: 'Select' },
        );
        this.setState({ formFields: formFields })
    }

    //getSingleRowData
    getrowData = async (rowData) => {
        let pic = null;
        let typeValue = [];
        if (this.props.type === 'Clients') {
            if (rowData && rowData.photo) {
                pic = rowData.photo;
            }
        } else {
            if (rowData && rowData.photo && rowData.photo[0] && rowData.photo[0].name) {
                pic = rowData.photo[0].name
            }
        }


        await this.setState({ rowData: rowData, role: rowData.role, pic: pic, rowId: rowData._id });

        if (this.props.formType === 'edit' && rowData.role === 'Client') {
            this.getFormFields('addClient');
        } else {
            this.getFormFields();
        }

        this.setState({ editRowDataID: rowData._id })
        let keys = Object.keys(rowData)
        let formData = [];

        for (let item of this.state.formFields) {
            if (keys.indexOf(item.name) !== -1) {
                if (item.name === 'startDate' || item.name === 'endDate' || item.name === 'dateOfBirth' || item.name === 'joinDate') {
                    item.value = dateFormats.formatDate(rowData[item.name], config.dateDashFormat);
                }
                else {
                    item.value = rowData[item.name];
                }
            } else {
                item.value = ' ';
            }
            formData.push(item);
        }

        // to load intail values of form
        if (this.props.load) {
            if (this.props.type === 'CLients') {
                if (rowData.type) {
                    if (rowData.type.includes('Buyer')) {
                        typeValue.push('Buyer');
                    }
                    if (rowData.type.includes('Seller')) {
                        typeValue.push('Seller');
                    }
                    rowData.type = typeValue
                }
            }
            this.props.load(rowData);
        }
        await this.setState({ formFields: formData, clientType: typeValue });

        this.setState({ isLoading: false });

    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    //send data to server
    saveDataToServer = async (formValues) => {
        this.setState({ isLoading: true });
        // let validationExists = this.checkValidationExists();

        let validationExists;
        if (!validationExists) {
            if (formValues) {
                let method, apiUrl;
                if (this.props.formType === 'edit') {
                    method = 'PUT';
                    apiUrl = `${this.props.apiUrl}/${this.state.editRowDataID}`;
                } else {
                    method = 'POST';
                    if (this.props.type && this.props.type === 'Clients') {
                        apiUrl = `${this.props.apiUrl}?response=true`;
                    } else {
                        apiUrl = this.props.apiUrl;
                    }
                }
                let userBody = formValues;
                if ((this.props.role === 'Admin' || this.props.role === 'TeamLead' || this.props.role === 'SuperAdmin') && this.props.formType === 'add' && this.props.type === 'Tasks') {
                    userBody.status = 'Active'
                }
                if (this.props.type === 'Clients') {
                    let agentEmail = JSON.parse(localStorage.getItem('loginCredentials')).email;
                    userBody['agentEmail'] = agentEmail;
                }
                return fetch(method, apiUrl, userBody)
                    .then(async (response) => {
                        if (response && response.respCode) {
                            this.clearFormFields();
                            this.setState({ isLoading: false });

                            this.props.closeRejectModal();
                            this.props.reset();
                            showToasterMessage(response.respMessage, 'success');
                        } else {
                            if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                                await this.setState({
                                    isOpenSessionExpiredModal: true,
                                })
                            } else {
                                this.setState({ isLoading: false });
                                showToasterMessage(response.errorMessage, 'error');
                            }
                        }
                        await this.props.getTableListDataFromServer();
                    }).catch((err) => {
                        return err;
                    });
            } else {
                return;
            }
        }
    }

    clearFormFields = async () => {
        // to empty form
        if (this.props.load) {
            this.props.load({});
        }
        let formData = [...this.state.formFields];
        formData.forEach((item, index) => {
            item.value = '';
            item.invalid = false;
            item.message = ''
        });
        await this.setState({ formFields: formData })
        this.getFormFields();
    }

    //check if any validation exists or not
    checkValidationExists = () => {
        this.formValidations();
        let formData = [...this.state.formFields];
        let validationExists = false
        formData.forEach((item, index) => {
            if (item.required === true) {
                if (item.invalid === true) {
                    validationExists = true
                }
            }
        })
        return validationExists;
    }

    //validating form fields
    formValidations = () => {
        let formData = [...this.state.formFields];
        let formFields = [];
        formData.forEach((item, index) => {
            if (item.required === true) {
                let validation = validate.checkFieldValidations(item, this.state.DOB);
                if (validation && validation.invalid && validation.message) {
                    if (config.borderValidation === true) {
                        item.invalid = validation.invalid;
                    }
                    if (config.messages === true) {
                        item.message = validation.message;
                    }
                }
            } else {
                item.invalid = '';
                item.message = '';
            }
            formFields.push(item);
            this.setState({ formFields: formFields });
        })
    }


    closeFormModal = async () => {
        await this.clearFormFields();
        await this.props.reset();
        await this.props.closeFormModal();
    }


    getEditorText = (e, name) => {
        this.setState({ editorData: { [name]: e.htmlValue } });
    }
    //handle fields data
    handleData = async (value, name, type, searchName, otherField) => {
        let formValues = this.state.formValues;
        let formFields = [...this.state.formFields];
        // for auto complete values
        if (type && type === 'autoComplete') {
            if (value._id) {
                formValues[name] = value._id;
            }
            if (otherField && searchName && value[searchName]) {
                formValues[otherField] = value[searchName];
            }
        }
        formFields.forEach(async (item, index) => {
            if (item.name === name) {
                item.value = value;
                item.invalid = false;
                item.message = '';
                if (item.name === 'dateOfBirth') {
                    this.setState({ DOB: item.value })
                }
            }
        })
        formValues[name] = value;
        await this.setState({ formFields: formFields, formValues: formValues })

        if (name === 'reason' && type === 'changeStatus') {
            this.saveDataToServer(formValues.reason);
        } else if (name === 'status' && type === 'changeStatus') {
            this.saveDataToServer(formValues.status);
        } else if (name === 'wfhApproved' && type === 'changeStatus') {
            this.saveDataToServer(formValues.wfhApproved);
        }
    }

    // form Submit
    submit = (values) => {
        let values_ = values;
        if (values_) {
            let taskType = typeof values.taskId
            if (taskType === 'string') {
                this.setState({ taskError: true });
                return;
            }
        }

        if (this.props.type === 'Clients') {
            let typeValue = '';
            let typeArray = this.state.clientType;
            if (typeArray && typeArray.includes('Buyer') && typeArray.includes('Seller')) {
                typeValue = 'Buyer/Seller';
            } else if (typeArray && typeArray.includes('Buyer')) {
                typeValue = 'Buyer'
            } else if (typeArray && typeArray.includes('Seller')) {
                typeValue = 'Seller'
            } else {
                typeValue = '';
            }
            values_.type = typeValue;

            if (values.email) {
                let regex = config["emailRegex"];
                if (regex.test(values.email)) {
                } else {
                    this.setState({ emailError: true });
                    return;
                }
            }
            if (values.phoneNumber) {
                let regex = config["phoneNumberRegex"];
                if (regex.test(values.phoneNumber)) {
                } else {
                    this.setState({ phoneNumberError: true });
                    return;
                }
            }
        }

        let formValues = values_;
        if (Object.keys(values_).length > 0) {
            this.saveDataToServer(formValues);
        } else {
            return;
        }
    }

    removePhoto = async () => {
        this.setState({ pic: null });
    }

    handleAutoData = (value, name) => {
        if (this.props.type === 'Issues' || this.props.type === 'Timesheet') {
            if (name === 'taskId') {
                this.setState({
                    isTaskSet: true
                });
            } else if (name === 'projectId') {
                setTimeout(() => {
                    this.setState({
                        projectId: value._id,
                        projectName: value.name
                    });
                }, 0)
            }
        }
        if (name === 'projectId') {
            if (this.props.type === 'Tasks' || this.props.type === 'Issues') {
                let assignEmp = [];
                if (value && value.assignEmployees && value.assignEmployees.length > 0) {
                    assignEmp = value.assignEmployees;
                }
                if (value && value.teamLead) {
                    assignEmp.push(value.teamLead);
                }
                setTimeout(() => {
                    this.setState({
                        assignedEmps: assignEmp,
                        teamLead: value.teamLead.displayName
                    });
                }, 0)
            }


            if (this.props.type === 'Tasks' || this.props.type === 'leaves') {
                if (value.startDate && this.props.type === 'leaves') {
                    this.setState({ minDate: new Date() });
                }
                if (value.endDate) {
                    this.setState({ maxDate: new Date(value.endDate) });
                }
            }

        } if (name === 'taskId' && this.props.type === 'Timesheet') {
            let formFields = this.state.formFields;
            if (value && value.name === 'other') {
                this.setState({ taskOther: true });
                formFields.splice(4, 0,
                    {
                        required: false,
                        value: '',
                        type: 'input',
                        name: 'otherTask',
                        label: 'Task Name',
                        id: 'otherTask',
                        placeholder: 'Task Name'
                    },

                );
                this.setState({
                    formFields: formFields
                });
            } else if (this.state.taskOther) {
                formFields.splice(2, 1);
                this.setState({ taskOther: false });
            }
        }
    }

    // to set formfields based on project or non-project in Timesheet form
    checkProjectOrNot = (type, rowData) => {
        let formFields;
        if (!this.state.allFields) {
            formFields = this.state.formFields;
            this.setState({ allFields: formFields });
        } else if (this.state.allFields) {
            formFields = this.state.allFields;
        }

        if (type === 'Project') {
            let data = rowData;
            if (!rowData) {
                data = this.state.rowData;
            }
            if (data && data.taskName && data.taskName === 'other') {
                let index = formFields.findIndex(field => field.name === 'otherTask');
                if (index === -1) {
                    formFields.splice(4, 0, { required: false, value: '', type: 'input', name: 'otherTask', label: 'Task Name', id: 'otherTask', placeholder: 'Task Name' });
                }
            }
            else {
                formFields = formFields.filter((field) => {
                    return field.name !== 'otherTask';
                })
            }

        } else {

            let index = formFields.findIndex(field => field.name === 'otherTask');
            if (index === -1) {
                formFields.splice(3, 0, { required: false, value: '', type: 'input', name: 'otherTask', label: 'Task Name', id: 'otherTask', placeholder: 'Task Name' });
            }

            formFields = formFields.filter((field) => {
                return field.name !== 'projectId';
            })
            formFields = formFields.filter((field) => {
                return field.name !== 'taskId';
            })
        }
        setTimeout(() => {
            this.setState({ formFields: formFields });
        }, 0)

    }

    // Onchange checkbox
    onChange = (value) => {
        if (value === 'isClientAdded' && this.state.isClientAdded) {
            this.setState({
                isClientAdded: false
            })
        }
    }

    onMultiSelectChange = (event) => {
        if (this.props.type) {
            this.setState({
                clientType: event.target.value
            })
        }
    }

    render() {
        const { t } = this.props;
        let d = new Date();
        //In Add Time sheet modal display current date by default
        //And disable future dates for selection ||CodeByTej
        if (this.props.formType !== 'edit') {
            this.setState({
                currentDate: d
            })
            // this.state.currentDate = d;
        }
        if (this.props.type === 'Timesheet') {
            this.setState({
                maxDate: d
            })
            // this.state.maxDate = d;
        }

        const { handleSubmit } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <div style={{ display: 'flex' }}>
                <Modal
                    isOpen={this.props.openFormModal}
                    className={`modal-dialog-centered modal-dialog--primary  ${modalClass} formModalBorderRadius`}
                >
                    <ModalHeader className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.closeFormModal} />
                        <p className="bold-text  modal__title">{this.props.formType && this.props.formType === 'edit' ? 'Edit ' : 'Add '}{(this.props.formType === 'addClient' || this.state.role === 'Client') ? 'Client' : this.props.type}</p>
                    </ModalHeader>
                    <ModalBody className="modal__body mb-0 " >
                        <Card className='m-0 p-0'>
                            <CardBody className='m-0 p-0'>
                                <Loader loader={this.state.isLoading} />
                                <form key={this.props.type} onSubmit={handleSubmit(this.submit)} >
                                    <div className='col-sm-12'>
                                        <ButtonToolbar className=" modal__footer footerData">
                                            <Button color='primary' type="button" onClick={this.closeFormModal}>{t('forms.cancel')}</Button>
                                            {this.props.formType && this.props.formType === 'add' && this.props.type === 'Timesheet' ? <Button color='primary' outline type="submit" onClick={() => this.save('save&Add')}>{t('forms.save')}</Button> : null}
                                            {this.props.formType && this.props.formType === 'edit' ?
                                                <Button color='primary' outline type="submit" >{t('forms.update')}</Button> :
                                                <Button color='primary' outline type="submit" onClick={() => this.save('save')} >{this.props.type === 'Timesheet' ? t('forms.save&close') : t('forms.save')}</Button>}
                                        </ButtonToolbar>
                                    </div>
                                    <div className="row form" >
                                        {this.state.formFields ? this.state.formFields.map((item, i) => {
                                            return <div key={this.props.type + i} className={item.type === 'editor' ? 'col-sm-12' : 'col-sm-6'}  >
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label text-capitalize">{this.props.formType === 'add' ? (item.label === 'Photo' ? '' : item.label) : item.label}</label>
                                                    {item.type === 'dropDown' ?
                                                        <div className="form__form-group-field">
                                                            <Field key={i}
                                                                name={item.name}
                                                                component={Select}
                                                                options={item.options}
                                                                defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                                placeholder={item.placeholder}
                                                                leavesFormData={this.formattedLeavesFormData}
                                                                getFormFields={this.getFormFields}
                                                                onChange={this.props.type === 'Timesheet' ? e => this.checkProjectOrNot(e, null) : null}
                                                                isDisable={(this.props.type === 'Tasks' && item.name === 'status' && (this.props.role !== 'Admin' && this.props.role !== 'TeamLead' && this.props.role !== 'SuperAdmin')) ? true : false}
                                                            />
                                                        </div>
                                                        : item.type === 'multiSelect' ?
                                                            <div className="form__form-group-field" key={i}>
                                                                <MultiSelect
                                                                    name={item.name}
                                                                    style={{ width: '100%', lineHeight: 1.15, height: '32px', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                                                                    placeholder={item.placeholder}
                                                                    value={this.props.formType === 'add' ? (this.state.clientType ? this.state.clientType : item.defaultValues) : (this.state.clientType ? this.state.clientType : ['Buyer', 'Seller'])}
                                                                    options={item.options}
                                                                    onChange={(event) => this.onMultiSelectChange(event)}
                                                                />
                                                            </div>
                                                            : item.type === 'date' ?
                                                                <div key={i} className="form__form-group">
                                                                    <div className="form__form-group-field">
                                                                        <Field key={i}
                                                                            name={item.name ? item.name : null}
                                                                            placeholder={item.placeholder ? item.placeholder : null}
                                                                            id={item.id ? item.id : null}
                                                                            component={DatePicker}
                                                                            minDate={this.state.minDate ? this.state.minDate : null}
                                                                            maxDate={item.name === 'startDate' ? new Date() : this.state.maxDate ? this.state.maxDate : null}
                                                                            screen={this.props.type}
                                                                            currentDate={(item.name === 'date' || item.name === 'startDate') ? this.state.currentDate : null}
                                                                            disabled={(this.props.type === 'Attendance') ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ color: '#dc3545' }}>{item.message ? item.message : null}</div>
                                                                </div>
                                                                : item.type === 'empty' ?
                                                                    <div> </div>
                                                                    : item.type === 'radio' ?
                                                                        <div className='mb-0'>
                                                                            <div className='row'>
                                                                                {item.options.map((option, ind) => {
                                                                                    return <div key={ind} className={this.props.type === 'Timesheet' ? "form__form-group col-sm-12 mb-0" : "form__form-group col-sm-6 mb-0"}>
                                                                                        <div className="form__form-group-field">
                                                                                            <Field key={this.props.type + i + ind}
                                                                                                name={item.name ? item.name : null}
                                                                                                component={RadioButton}
                                                                                                label={option.value}
                                                                                                radioValue={option.value}
                                                                                                defaultChecked={option.value === 'Buyer' ? true : false}
                                                                                            // onChange={this.checkProjectOrNot}
                                                                                            // validate={item.name === 'type' ? [radioRequired] : null}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                                }
                                                                            </div>
                                                                            <div style={{ color: '#dc3545' }}>{item.message ? item.message : null}</div>
                                                                        </div>
                                                                        : item.type === 'autoComplete' ?
                                                                            <div>
                                                                                <Field key={i}
                                                                                    name={item.name ? item.name : null}
                                                                                    component={AutoComplete}
                                                                                    type={item.type}
                                                                                    filterField={item.filterField}
                                                                                    filterValue={item.filterValue}
                                                                                    filterType={item.isNotEq}
                                                                                    multiple={item.isMultiple}
                                                                                    placeholder={item.placeholder ? item.placeholder : ''}
                                                                                    searchApi={item.searchApi}
                                                                                    searchField={item.searchField}
                                                                                    otherField={item.otherField}
                                                                                    assignedEmps={(this.state.assignedEmps && this.state.assignedEmps.length) ?
                                                                                        this.state.assignedEmps : null}
                                                                                    projectTeamLead={this.state.teamLead}
                                                                                    filterIds={this.state.filterIds}
                                                                                    onChange={(event) => this.handleAutoData(event, item.name)}
                                                                                    projectId={this.state.projectId ? this.state.projectId : null}
                                                                                    projectName={this.state.projectName ? this.state.projectName : null}
                                                                                    screen={this.props.type}
                                                                                    allow={this.state.allowDuplicates}
                                                                                />
                                                                                {item.name === 'taskId' && this.state.taskError && this.state.taskError === true ?
                                                                                    <span className="form__form-group-error">{"Select Task from suggestions"}</span>
                                                                                    : null
                                                                                }
                                                                            </div>
                                                                            : item.type === 'textarea' ?
                                                                                <Field key={i}
                                                                                    name={item.name ? item.name : null}
                                                                                    component={DefaultTextArea}
                                                                                    type={item.type ? item.type : null}
                                                                                    placeholder={item.placeholder ? item.placeholder : null}
                                                                                    validate={!this.state.isTaskSet && item.name === 'otherTask' ? [required] : null}
                                                                                />
                                                                                : item.type === 'editor' ?
                                                                                    <Editor key={i}
                                                                                        getEditorText={this.getEditorText} name={item.name} value={item.value} />
                                                                                    : item.type === 'time' ?
                                                                                        <Field key={i}
                                                                                            name={item.name ? item.name : null}
                                                                                            component={TimePicker}
                                                                                            timeMode
                                                                                            placeholder={item.placeholder ? item.placeholder : null}
                                                                                        />
                                                                                        : item.type === 'checkBox' ?
                                                                                            <Field key={i}
                                                                                                name={item.name ? item.name : null}
                                                                                                component={CheckBox}
                                                                                                checked={this.state.isClientAdded}
                                                                                                onChange={() => this.onChange('isClientAdded')}
                                                                                                label={item.label}
                                                                                            />
                                                                                            : item.type === 'photo' ?
                                                                                                this.props.formType === 'edit' ?
                                                                                                    <div>
                                                                                                        {/* <div className="form__form-group-field"> */}
                                                                                                        <Field
                                                                                                            name="photo"
                                                                                                            component={RenderFileInputField}
                                                                                                            onRef={(ref) => (this.profileUploadRef = ref)}
                                                                                                            acceptType="image/*"
                                                                                                            chooseLabel={'Upload'}
                                                                                                            type={this.props.type}
                                                                                                            url={`uploads?uploadPath=${this.props.type === 'Clients' ? 'client' : 'user'}&entityType=${this.props.type === 'Clients' ? 'client' : 'user'}&_id=${this.state.rowId}`}
                                                                                                        />
                                                                                                        {/* </div> */}
                                                                                                        <div className='col-6' style={{ padding: '2px' }}>
                                                                                                            <div style={{ justifyContent: 'center' }}>
                                                                                                                {(this.state.pic) ?
                                                                                                                    <div>
                                                                                                                        <LightTooltip title={'Remove'} >
                                                                                                                            <CrossCircleIcon className='formPhotoField' onClick={this.removePhoto} />
                                                                                                                        </LightTooltip>
                                                                                                                        <img alt='' src={`${config.imgUrl}${item.url}/${this.state.pic}`} className='detailsImgStyle' />
                                                                                                                    </div>
                                                                                                                    : null
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : null
                                                                                                : <div>
                                                                                                    <Field key={i}
                                                                                                        name={item.name ? item.name : null}
                                                                                                        component={DefaultInput}
                                                                                                        isDisable={this.props.type !== 'Attendance' && this.props.formType && this.props.formType === 'edit' ? item.disable : (this.props.type === 'Attendance') ? true : null}
                                                                                                        type="text"
                                                                                                        disabled
                                                                                                        id={item.id ? item.id : null}
                                                                                                        placeholder={item.placeholder ? item.placeholder : null}
                                                                                                        validate={(!this.state.isTaskSet && item.name === 'screenName') ? [required] : null}
                                                                                                    />
                                                                                                    {item.name === 'aadharcardNumber' && this.state.aadharError && this.state.aadharError === true ?
                                                                                                        <span className="form__form-group-error">{"Enter valid Aadhar number with no space"}</span>
                                                                                                        : null
                                                                                                    }
                                                                                                    {this.props.type === 'Clients' && item.name === 'email' && this.state.emailError ?
                                                                                                        <span className="form__form-group-error">{"Enter valid Email"}</span>
                                                                                                        : null
                                                                                                    }
                                                                                                    {this.props.type === 'Clients' && item.name === 'phoneNumber' && this.state.phoneNumberError ?
                                                                                                        <span className="form__form-group-error">{"Enter valid Phone Number"}</span>
                                                                                                        : null
                                                                                                    }
                                                                                                </div>
                                                    }
                                                </div>
                                            </div>

                                        })
                                            : null}
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                    />
                    : null
                }
            </div >
        );
    }
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
FormModal = reduxForm({
    form: "Common Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(FormModal);

// You have to connect() to any reducers that you wish to connect to yourself
FormModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(FormModal);

export default FormModal
