import React from "react";
import TableRender from '../../Tables/CommonDataTable/TableRender';

class CLientsTable extends React.Component {

    getTableFields = async () => {
        let data = [
            { textAlign: 'center', width: 8, selectionMode: 'multiple', field: 'Sno', filter: false, sortable: false },
            { textAlign: 'center', width: 7, field: 'Sno', header: 'sno' },
            { textAlign: 'center', width: 15, field: 'displayName', header: 'name', filter: true, placeholder: 'Name' , showInMoblie: true},
            { textAlign: 'center', width: 10, field: 'isFavourite', header: 'isFavourite' },
            { textAlign: 'center', width: 15, field: 'type', header: 'type', filter: true, placeholder: 'Name', showInMoblie : true },
            { textAlign: 'center', width: 15, field: 'phoneNumber', header: 'phone', showInMoblie : true },
            { textAlign: 'center', width: 15, field: 'email', header: 'email',showInMoblie : true },
            { textAlign: 'center', width: 9, field: 'Actions', header: 'actions' , showInMoblie : true },
        ];
        return data;
    };

    render() {
        const { tl } = this.props;
        return (
            <TableRender
                type='Clients'
                tableFields={() => this.getTableFields()}
                tl={tl}
            />
        );
    }
}
export default CLientsTable