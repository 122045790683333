import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

// Config
import configImages from '../../../config/configImages';
import config from '../../../config/config';
import fetchMethodRequest from '../../../config/service';

// UI Components
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import Loader from '../../App/Loader';

import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

// Icons
import BackIcon from 'mdi-react/ArrowLeftBoldIcon';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

// Criteria conversion to send to matched Props List
const convertedItem = (item) => {
    let tempCriteria = item.criteria;
    if (tempCriteria) {
        if (tempCriteria.totalPriceMaxDigits) {
            let tempCommaString = (tempCriteria.totalPriceMaxDigits).toLocaleString('en-IN')
            tempCriteria['totalPriceMax'] = tempCommaString;
        }
        if (tempCriteria.totalPriceMinDigits) {
            let tempCommaString = (tempCriteria.totalPriceMinDigits).toLocaleString('en-IN')
            tempCriteria['totalPriceMin'] = tempCommaString;
        }
        if (tempCriteria.builtUpAreaMaxDigits) {
            let tempCommaString = (tempCriteria.builtUpAreaMaxDigits).toLocaleString('en-IN')
            tempCriteria['builtUpAreaMax'] = tempCommaString;
        }
        if (tempCriteria.builtUpAreaMinDigits) {
            let tempCommaString = (tempCriteria.builtUpAreaMinDigits).toLocaleString('en-IN')
            tempCriteria['builtUpAreaMin'] = tempCommaString;
        }
    } else {
        tempCriteria = {}
    }

    item['criteria'] = tempCriteria;

    return item;
}

class ClientDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientDetails: {},
            isLoading: true,
            filterCriteria: {
                limit: 10,
                page: 1,
                criteria: [],
                sortfield: 'created',
                direction: 'desc',
            },
            clientRequirements: [],
            clientProperties: [],
            tl: {},
        };
    }

    componentDidMount = async () => {
        let tl = {}
        if (this.props.location && this.props.location.state && this.props.location.state.tl) {
            tl = this.props.location.state.tl
        } else {
            tl = JSON.parse(localStorage.getItem('langJson'));
        }
        await this.setState({
            tl: tl
        })
        await this.getClientDataBasedOnId();
        await this.getClientRequirements();
        await this.getClientProperties();
    }

    // Get Client detais based on selected id
    getClientDataBasedOnId = async () => {
        await this.setState({ isLoading: true });
        fetchMethodRequest('GET', `clients/${this.props.match.params.id}`)
            .then(async (response) => {
                if (response && response.details) {
                    let clientDetails = response.details;
                    let isBuyer = false;
                    let isSeller = false;

                    if (clientDetails.type && clientDetails.type.includes('Buyer')) {
                        isBuyer = true;
                    }
                    if (clientDetails.type && clientDetails.type.includes('Seller')) {
                        isSeller = true;
                        await this.getClientRequirements();
                    }
                    await this.setState({
                        clientDetails: clientDetails,
                        isBuyer: isBuyer,
                        isSeller: isSeller,
                    })
                } else {
                    if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }
            }).catch((err) => {
                return err;
            });
        await this.setState({ isLoading: false });
    }

    // Get properties of Selected Client
    getClientProperties = async () => {
        let filterCriteria = this.state.filterCriteria;
        let criteria = [
            { key: 'clientId', value: this.props.match.params.id, type: 'eq' },
        ];
        filterCriteria.criteria = criteria;
        filterCriteria['sortfield'] = 'created';
        filterCriteria['direction'] = 'desc';

        let filterString = JSON.stringify(filterCriteria);
        let url = `properties?filter=${filterString}`;

        fetchMethodRequest('GET', url).then(async resp => {
            if (resp && resp.respCode) {
                this.setState({
                    clientProperties: resp.properties,
                })
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        }).catch(err => {
            return err;
        })
    }

    // Get Saved searches of selected Client
    getClientRequirements = async () => {
        await this.setState({ isLoading: true });
        let user = await JSON.parse(localStorage.getItem('loginCredentials'));
        let filterCriteria = this.state.filterCriteria;
        let criteria = [
            { key: 'agentEmail', value: user.email, type: 'eq' },
            { key: 'clientId', value: this.props.match.params.id, type: 'eq' },
        ];
        filterCriteria.criteria = criteria;

        let filterString = JSON.stringify(filterCriteria);
        let url = `criteria?filter=${filterString}`;

        fetchMethodRequest('GET', url).then(async resp => {
            if (resp && resp.respCode) {
                this.setState({
                    clientRequirements: resp.clientCriterias,
                })
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        }).catch(err => {
            return err;
        })
        await this.setState({ isLoading: false });
    }

    render() {
        const { clientDetails, tl } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Loader loader={this.state.isLoading} />
                <Card>
                    <CardHeader className='py-1'>
                        <div className="row m-0 align-items-center">
                            <div className="col-4 text-left pl-0">
                                <Link to='/clients'>
                                    <BackIcon
                                        className='backIcon cursorPointer'
                                    />
                                </Link>
                            </div>
                            <div className='col-4 pl-0 pr-0 text-center pageHeadingCss'>
                                {tl['clientDetails'] ? tl['clientDetails'] : 'Client Details'}
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody className='cardHeader'>
                        {/* Client Info */}
                        <div className='row mx-0 detailsFont'>
                            <div className='col-sm-8 px-0 order-md-1 order-2 align-self-center'>
                                {clientDetails && clientDetails.displayName ?
                                    <div className='pb-2 textCapitalize'>
                                        <span className='lightText'>
                                            {tl['name'] ? tl['name'] : 'Name'}{' : '}
                                        </span>
                                        {clientDetails.displayName}
                                    </div>
                                    : null
                                }
                                {clientDetails && clientDetails.email ?
                                    <div className='pb-2'>
                                        <span className='lightText textCapitalize'>
                                            {tl['email'] ? tl['email'] : 'Email'}{' : '}
                                        </span>
                                        {clientDetails.email}
                                    </div>
                                    : null
                                }
                                {clientDetails && clientDetails.phoneNumber ?
                                    <div className='pb-2 textCapitalize'>
                                        <span className='lightText'>
                                            {tl['phoneNumber'] ? tl['phoneNumber'] : 'Phone Number'}{' : '}
                                        </span>
                                        {clientDetails.phoneNumber}
                                    </div>
                                    : null
                                }
                                {clientDetails && clientDetails.type ?
                                    <div className='pb-2 textCapitalize'>
                                        <span className='lightText'>
                                            {tl['type'] ? tl['type'] : 'Type'}{' : '}
                                        </span>
                                        {clientDetails.type}
                                    </div>
                                    : null
                                }
                                {clientDetails && clientDetails.note ?
                                    <div className='pb-2 textCapitalize'>
                                        <span className='lightText'>
                                            {tl['notes'] ? tl['notes'] : 'Notes'}{' : '}
                                        </span>
                                        {clientDetails.type}
                                    </div>
                                    : null
                                }
                            </div>
                            <div className='col-sm-4 px-0 order-md-2 clientDetailsPageProfileContainer order-1'>
                                {this.state.clientDetails && this.state.clientDetails.photo ?
                                    <img
                                        className='clientDetailsPhoto'
                                        alt='...'
                                        loading='lazy'
                                        src={`${config.imgUrl}client/${this.state.clientDetails.photo}`}
                                    />
                                    : <img
                                        className='clientDetailsPhoto'
                                        alt=''
                                        src={configImages.defaultImg}
                                    />
                                }
                            </div>
                        </div>

                        {/* Client properties */}
                        {this.state.isSeller ?
                            <div className="mx-0 mt-2">
                                <div className='pageSubHeadingCss'>
                                    {tl['myProperties'] ? tl['myProperties'] : 'My Properties'}
                                </div>
                                <div className="px-0 row">
                                    {this.state.clientProperties && this.state.clientProperties.length > 0 ?
                                        this.state.clientProperties.map((item, index) => {
                                            return <div className="col-sm-6 col-lg-4  px-2  my-2" key={index}>
                                                <div className="row clientMyProps mx-1">
                                                    <div className="col-md-6 p-3 text-left ">
                                                        {item.photo && item.photo.length > 0 ?
                                                            <img src={`${config.imgUrl}property/${item.photo[0].name}`} alt='' className='clientImage' ></img>
                                                            : <img src={configImages.noPropImage} alt='' className='clientImage' ></img>
                                                        }
                                                    </div>
                                                    <div className="col-md-6 description_alingment textCapitalize p-3 p-lg-0 py-lg-3">
                                                        <p className='clientCriteriaCardHeading'>
                                                            {item.title}
                                                        </p>
                                                        <div className='detailsFont'>
                                                            <p className='mt-0'>
                                                                {item.city}, {item.locality}
                                                            </p>
                                                            <p className='mt-0'>
                                                                {item.totalPrice1 ? item.totalPrice1 : null}, {item.builtUpArea} {config.areaUnits}
                                                            </p>
                                                            <p className='mt-0'>
                                                                {item.furnishingType}
                                                            </p>
                                                            <p className='mt-0'>
                                                                {tl['views'] ? tl['views'] : 'Views'}{' : '}
                                                                {item.onClickCount ? item.onClickCount : 0}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                        : <h5 className='pl-3'>
                                            {tl['noPropertiesYet'] ? tl['noPropertiesYet'] : 'No Properties Yet'}
                                        </h5>
                                    }
                                </div>
                            </div>
                            : null
                        }

                        {/* Client saved/matched properties */}
                        {this.state.isBuyer ?
                            <div className="row mt-2">
                                <div className='col-12 pageSubHeadingCss'>
                                    {tl['matchedProps'] ? tl['matchedProps'] : 'Matched Properties'}
                                </div>
                                {this.state.clientRequirements.length > 0 ?
                                    this.state.clientRequirements.map((item, index) => {
                                        return <div className='col-sm-6 col-md-4 col-lg-3 my-2' key={index}>
                                            <Link
                                                className='linkOverCardCss'
                                                to={{
                                                    pathname: `/matched_properties`,
                                                    state: {
                                                        state: convertedItem(item),
                                                        fromScreen: window.location.pathname,
                                                        matchedFromScreen: window.location.pathname
                                                    },
                                                }}
                                            >
                                                <Card className='clientCriteriaCard cursorPointer pb-0'>
                                                    <CardHeader className='py-2 px-2 clientCriteriaCardHeading'>
                                                        <div className="d-flex justify-content-between">
                                                            <span>
                                                                {tl['criteria'] ? tl['criteria'] : 'Criteria'}
                                                            </span>
                                                            {tl['results'] ? tl['results'] : 'Results'}{' : '}{item.result.length}
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody className='p-0 px-2 detailsFont py-3'>
                                                        {item.criteria ?
                                                            <div className='align-self-center'>
                                                                {(item.criteria.propertyFor || item.criteria.propertyType || item.criteria.bedRooms) ?
                                                                    <div className='textCapitalize'>
                                                                        <span className='lightText'>
                                                                            {tl['property'] ? tl['property'] : 'Property'}{' : '}
                                                                        </span>
                                                                        {(item.criteria.bedRooms ?
                                                                            item.criteria.bedRooms + ' BHK'
                                                                            : '') +
                                                                            (item.criteria.propertyType ?
                                                                                ' ' + item.criteria.propertyType :
                                                                                '') +
                                                                            (item.criteria.propertyFor ?
                                                                                ' For ' + item.criteria.propertyFor
                                                                                : '')
                                                                        }
                                                                    </div>
                                                                    : null
                                                                }

                                                                {/* {item.criteria.propertyFor ?
                                                                    <div className='textCapitalize pt-1'>
                                                                        <span className='lightText'>
                                                                            {tl['propertyFor') ? tl['propertyFor') : 'Property For'}{' : '}
                                                                        </span>
                                                                        {item.criteria.propertyFor}
                                                                    </div>
                                                                    : null
                                                                }
                                                                {item.criteria.propertyType ?
                                                                    <div className='textCapitalize pt-1'>
                                                                        <span className='lightText'>
                                                                            {tl['propertyType') ? tl['propertyType') : 'Property Type'}{' : '}
                                                                        </span>
                                                                        {item.criteria.propertyType}
                                                                    </div>
                                                                    : null
                                                                }
                                                                {item.criteria.bedRooms ?
                                                                    <div className='textCapitalize pt-1'>
                                                                        <span className='lightText'>
                                                                            {tl['bedRooms') ? tl['bedRooms') : 'Bedrooms'}{' : '}
                                                                        </span>
                                                                        {item.criteria.bedRooms}
                                                                    </div>
                                                                    : null
                                                                } */}

                                                                {(item.criteria.location || item.criteria.city) ?
                                                                    <div className='textCapitalize pt-1'>
                                                                        <span className='lightText'>
                                                                            {tl['location'] ? tl['location'] : 'Location'}{' : '}
                                                                        </span>
                                                                        {item.criteria.location || item.criteria.city}
                                                                    </div>
                                                                    : null
                                                                }

                                                                {item.criteria.totalPriceMin || item.criteria.totalPriceMax ?
                                                                    <div className='textCapitalize pt-1'>
                                                                        <span className='lightText'>
                                                                            {'Budget : '}{config.currencySymbol + ' '}
                                                                        </span>
                                                                        <span>
                                                                            {(typeof (item.criteria.totalPriceMinDigits) === 'number') ?
                                                                                item.criteria.totalPriceMinDigits >= 10000000 ?
                                                                                    (item.criteria.totalPriceMinDigits / 10000000).toFixed(2) + ' Cr'
                                                                                    : item.criteria.totalPriceMinDigits >= 100000 ?
                                                                                        (item.criteria.totalPriceMinDigits / 100000).toFixed(2) + ' L'
                                                                                        : item.criteria.totalPriceMinDigits >= 1000 ?
                                                                                            (item.criteria.totalPriceMinDigits / 1000).toFixed(2) + ' K'
                                                                                            : item.criteria.totalPriceMinDigits
                                                                                : 0
                                                                            }{' - '}
                                                                            {(typeof (item.criteria.totalPriceMaxDigits) === 'number' ?
                                                                                item.criteria.totalPriceMaxDigits >= 10000000 ?
                                                                                    (item.criteria.totalPriceMaxDigits / 10000000).toFixed(2) + ' Cr'
                                                                                    : item.criteria.totalPriceMaxDigits >= 100000 ?
                                                                                        (item.criteria.totalPriceMaxDigits / 100000).toFixed(2) + ' L'
                                                                                        : item.criteria.totalPriceMaxDigits >= 1000 ?
                                                                                            (item.criteria.totalPriceMaxDigits / 1000).toFixed(2) + ' K'
                                                                                            : item.criteria.totalPriceMaxDigits
                                                                                : item.criteria.totalPriceMaxDigits
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    : null
                                                                }

                                                                {item.criteria.totalPriceMin || item.criteria.totalPriceMax ?
                                                                    <div className='textCapitalize pt-1'>
                                                                        <span className='lightText'>
                                                                            {tl['area'] ? tl['area'] : 'Area'}{' : '}
                                                                        </span>
                                                                        <span>
                                                                            {(typeof (item.criteria.builtUpAreaMinDigits) === 'number') ?
                                                                                item.criteria.builtUpAreaMinDigits >= 10000000 ?
                                                                                    (item.criteria.builtUpAreaMinDigits / 10000000).toFixed(2) + ' Cr'
                                                                                    : item.criteria.builtUpAreaMinDigits >= 100000 ?
                                                                                        (item.criteria.builtUpAreaMinDigits / 100000).toFixed(2) + ' L'
                                                                                        : item.criteria.builtUpAreaMinDigits >= 1000 ?
                                                                                            (item.criteria.builtUpAreaMinDigits / 1000).toFixed(2) + ' K'
                                                                                            : item.criteria.builtUpAreaMinDigits
                                                                                : 0
                                                                            }{' - '}
                                                                            {(typeof (item.criteria.builtUpAreaMaxDigits) === 'number' ?
                                                                                item.criteria.builtUpAreaMaxDigits >= 10000000 ?
                                                                                    (item.criteria.builtUpAreaMaxDigits / 10000000).toFixed(2) + ' Cr'
                                                                                    : item.criteria.builtUpAreaMaxDigits >= 100000 ?
                                                                                        (item.criteria.builtUpAreaMaxDigits / 100000).toFixed(2) + ' L'
                                                                                        : item.criteria.builtUpAreaMaxDigits >= 1000 ?
                                                                                            (item.criteria.builtUpAreaMaxDigits / 1000).toFixed(2) + ' K'
                                                                                            : item.criteria.builtUpAreaMaxDigits
                                                                                : item.criteria.builtUpAreaMaxDigits
                                                                            )}{' ' + config.areaUnits}
                                                                        </span>
                                                                    </div>
                                                                    : null
                                                                }

                                                                {item.criteria.facing ?
                                                                    <div className='textCapitalize pt-1'>
                                                                        <span className='lightText'>
                                                                            {tl['facing'] ? tl['facing'] : 'Facing'}{' : '}
                                                                        </span>
                                                                        {item.criteria.facing}
                                                                    </div>
                                                                    : null
                                                                }

                                                                {(item.criteria.availability || item.criteria.furnishingType) ?
                                                                    <div className='textCapitalize pt-1'>
                                                                        <span className='lightText'>
                                                                            {tl['status'] ? tl['status'] : 'Status'}{' : '}
                                                                        </span>
                                                                        {(item.criteria.furnishingType ?
                                                                            item.criteria.furnishingType : '') +
                                                                            ((item.criteria.availability && item.criteria.furnishingType) ?
                                                                                ' & ' : ''
                                                                            ) +
                                                                            (item.criteria.availability ?
                                                                                item.criteria.availability : '')
                                                                        }
                                                                    </div>
                                                                    : null
                                                                }

                                                                {/* {item.criteria.availability ?
                                                                    <div className='textCapitalize pt-1'>
                                                                        <span className='lightText'>
                                                                            {tl['availability') ? tl['availability') : 'Availability'}{' : '}
                                                                        </span>
                                                                        {item.criteria.availability}
                                                                    </div>
                                                                    : null
                                                                }
                                                                {item.criteria.furnishingType ?
                                                                    <div className='textCapitalize pt-1'>
                                                                        <span className='lightText'>
                                                                            {tl['furnishingType') ? tl['furnishingType') : 'Furnishing Type'}{' : '}
                                                                        </span>
                                                                        {item.criteria.furnishingType}
                                                                    </div>
                                                                    : null
                                                                } */}

                                                            </div>
                                                            : null
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                    })
                                    : <h5 className='pl-3'>
                                        {tl['noMatchedProperties'] ? tl['noMatchedProperties'] : 'No Matched Properties yet'}
                                    </h5>
                                }
                            </div >
                            : null
                        }
                    </CardBody >
                </Card >
                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </div >
        );
    }
}
export default withTranslation('common')(ClientDetails)