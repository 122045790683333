import React, { PureComponent } from 'react';
import { Col, Row, Button, Card, CardBody, CardHeader, CardFooter, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import ScrollBar from 'react-smooth-scrollbar';
import { Paginator } from 'primereact/paginator';
import classNames from 'classnames';

// Icons fontawesome and mdi-react
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShareIcon from 'mdi-react/ShareVariantIcon';
import CalenderQuetionIcon from 'mdi-react/CalendarQuestionIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import PropPlus from 'mdi-react/BankPlusIcon';
import TuneIcon from 'mdi-react/TuneIcon';
import ClipboardEditIcon from 'mdi-react/FileDocumentEditOutlineIcon';
import AspectRatio from 'mdi-react/AspectRatioIcon';
import BackIcon from 'mdi-react/ArrowLeftBoldIcon';
import UserIcon from 'mdi-react/UserCircleIcon';
import CallIcon from 'mdi-react/CallIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import Loader from '../../App/Loader';

// Modals
import ViewPhotosModal from '../../Tables/CommonDataTable/ViewPhotosModal';
import ClientSelectModel from '../components/ClientSelectModal';
import ShareLimitModal from './ShareLimitModal';
import SharePropsModal from './SharePropsModal';
import ConfirmActionModal from '../../Tables/CommonDataTable/ConfirmActionModal';

// Config
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import fetchMethodRequest from '../../../config/service';
import dateFormats from '../../UI/FormatDate/formatDate';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Redux Form
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { connect } from 'react-redux';
import validate from '../../Validations/validate';
import asyncValidate from '../../Validations/asyncValidate';

// Input Components
import DefaultInput from '../../../shared/components/form/DefaultInput';
import RadioButton from '../../../shared/components/form/RadioButton';
import Select from '../../../shared/components/form/Select';
import CheckBox from '../../../shared/components/form/CheckBox';

import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

import socket from '../../../config/Socket';

import ShareProperty from './ShareProperty'

// i18 Translation Provider
import { withTranslation } from 'react-i18next';
import { RentalPriceOptions, SoldPriceOptions } from '../../../config/budgetOPtions';
class CommonPropertiesScreen extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            myProperties: [],
            filterCriteria: {
                limit: 12,
                page: 1,
                criteria: [],
                sortfield: 'updated',
                direction: 'desc',
            },
            rows: 12,
            first: 1,
            pagination: {},
            isViewPhotosModalOpen: false,
            propertyPhotos: null,
            clientCriteria: null,
            tl: {},
            isOpenSharePropsModal: false,
            isFilterShown: false,
            isGlobalChecked: false,
            colored: false,
            header: true,
            totalPriceMaxOPtions: SoldPriceOptions,
            totalPriceMinOPtions: SoldPriceOptions,
        };
    }

    componentDidMount = async () => {
        // Clear un-wanted localStorage and get SelectAll state
        if (window.location.pathname === '/my_properties') {
            localStorage.removeItem('searchPropsSelected')
            localStorage.removeItem('matchedPropsSelected');
            localStorage.removeItem('showMatchedPropsSelected');

            localStorage.removeItem('searchIsGlobalChecked')
            localStorage.removeItem('matchedIsGlobalChecked')

            localStorage.removeItem('searchPropsDeselected');
            localStorage.removeItem('matchedPropsDeselected');
            localStorage.removeItem('showMatchedPropsDeSelected');


            if (localStorage.getItem('myIsGlobalChecked')) {
                let isGlobalChecked = (localStorage.getItem('myIsGlobalChecked') && localStorage.getItem('myIsGlobalChecked') !== 'undefined') ? JSON.parse(localStorage.getItem('myIsGlobalChecked')) : false;
                await this.setState({
                    isGlobalChecked: isGlobalChecked,
                })
            }
        } else if (window.location.pathname === '/search_properties') {
            localStorage.removeItem('myPropsSelected')
            localStorage.removeItem('matchedPropsSelected')
            localStorage.removeItem('showMatchedPropsSelected');


            localStorage.removeItem('myIsGlobalChecked')
            localStorage.removeItem('matchedIsGlobalChecked')

            localStorage.removeItem('myPropsDeselected');
            localStorage.removeItem('matchedPropsDeselected');
            localStorage.removeItem('showMatchedPropsDeSelected');


            if (localStorage.getItem('searchIsGlobalChecked')) {
                let isGlobalChecked = (localStorage.getItem('searchIsGlobalChecked') && localStorage.getItem('searchIsGlobalChecked') !== 'undefined') ? JSON.parse(localStorage.getItem('searchIsGlobalChecked')) : false;
                await this.setState({
                    isGlobalChecked: isGlobalChecked,
                })
            }
        } else if (window.location.pathname === '/matched_properties') {
            localStorage.removeItem('myPropsSelected')
            localStorage.removeItem('searchPropsSelected');
            localStorage.removeItem('showMatchedPropsSelected');

            localStorage.removeItem('myIsGlobalChecked')
            localStorage.removeItem('searchIsGlobalChecked')

            localStorage.removeItem('myPropsDeselected');
            localStorage.removeItem('searchPropsDeselected');
            localStorage.removeItem('showMatchedPropsDeSelected');

            if (localStorage.getItem('matchedIsGlobalChecked')) {
                let isGlobalChecked = (localStorage.getItem('matchedIsGlobalChecked') && localStorage.getItem('matchedIsGlobalChecked') !== 'undefined') ? JSON.parse(localStorage.getItem('matchedIsGlobalChecked')) : false;
                await this.setState({
                    isGlobalChecked: isGlobalChecked,
                })
            }
        }  else if (window.location.pathname === '/show_matched_properties') {
            localStorage.removeItem('myPropsSelected')
            localStorage.removeItem('searchPropsSelected')


            localStorage.removeItem('myIsGlobalChecked')
            localStorage.removeItem('searchIsGlobalChecked')

            localStorage.removeItem('myPropsDeselected');
            localStorage.removeItem('searchPropsDeselected');
            if (localStorage.getItem('showMatchedIsGlobalChecked')) {
                let isGlobalChecked = (localStorage.getItem('showMatchedIsGlobalChecked') && localStorage.getItem('showMatchedIsGlobalChecked') !== 'undefined') ? JSON.parse(localStorage.getItem('showMatchedIsGlobalChecked')) : false;
                await this.setState({
                    isGlobalChecked: isGlobalChecked,
                })
            }
        }
        let user = localStorage.getItem('loginCredentials') ? JSON.parse(localStorage.getItem('loginCredentials')) : null;

        // Get Language Object
        let tl = {}
        if (this.props.location && this.props.location.state && this.props.location.state.tl) {
            tl = this.props.location.state.tl
        } else {
            tl = (localStorage.getItem('langJson') && localStorage.getItem('langJson') !== 'undefined') ? JSON.parse(localStorage.getItem('langJson')) : {};
        }
        await this.setState({
            isLoading: true,
            loginUser: user,
            tl: tl
        })

        // Get Previous FilterCriteria and related data based on Screen
        if ((this.props.location && this.props.location.state && (this.props.location.state.state && !this.props.location.state.state.clientId))) {
            await this.setState({
                myProperties: this.props.location.state.prevData ? this.props.location.state.prevData : [],
                clientCriteria: this.props.location.state.prevCriteria ? this.props.location.state.prevCriteria : {},
                screen: this.props.location.state.prevScreen,
                ClientIdForMatcheProps: this.props.location.state.ClientIdForMatcheProps,
                currentFilterCriteria: this.props.location.state.prevFilterCriteria,
                matchedCriteria: this.props.location.state.criteriaForMatchedProps,
            })
            if (window.location.pathname === '/search_properties') {
                await this.getMyProperties('', this.props.location.state.prevFilterCriteria, 'matchChecked');
            }
            if (window.location.pathname === '/my_properties' && this.props.location.state.pageLoad) {
                await this.getMyProperties();
            } else if (window.location.pathname === '/my_properties') {
                await this.getMyProperties('', this.props.location.state.prevFilterCriteria, 'matchChecked');
            }
            if (window.location.pathname === '/matched_properties') {
                await this.getMatchedProperties(
                    this.props.location.state.criteriaForMatchedProps ? this.props.location.state.criteriaForMatchedProps : this.props.location.state.state,
                    this.props.location.state.prevFilterCriteria ? this.props.location.state.prevFilterCriteria : this.state.filterCriteria
                );
            }
            if (this.props.load) {
                this.props.load(this.props.location.state.prevCriteria ? this.props.location.state.prevCriteria : {});
            }
        } else {
            await this.props.load({});
            if (window.location.pathname === '/search_properties') {
                let prevCriteria = (localStorage.getItem('myClientCriteria') && localStorage.getItem('myClientCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myClientCriteria')) : {}
                let prevFilterCriteria = (localStorage.getItem('myFilterCriteria') && localStorage.getItem('myFilterCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myFilterCriteria')) : this.state.filterCriteria
                let fromScreen = (localStorage.getItem('fromScreen') && localStorage.getItem('fromScreen') !== 'undefined') ? JSON.parse(localStorage.getItem('fromScreen')) : '/dashboard'
                let propertyType = null;

                if (prevCriteria && prevCriteria.propertyType && (prevCriteria.propertyType === '1RK' || prevCriteria.propertyType === 'Commercial')) {
                    propertyType = prevCriteria.propertyType;
                }

                if (window.location.pathname === fromScreen) {
                    await this.setState({
                        currentFilterCriteria: prevFilterCriteria,
                        clientCriteria: prevCriteria,
                        screen: 'searchProps',
                        isLoading: false,
                        propertyType: propertyType,
                    })
                    if (this.props.load) {
                        await this.props.load(this.state.clientCriteria);
                    }
                    await this.getMyProperties(user, this.state.currentFilterCriteria);
                } else {
                    await this.setState({
                        screen: 'searchProps',
                        isLoading: false,
                    })
                    if (this.props.load) {
                        await this.props.load({ 'propertyFor': 'Sale', 'propertyType': 'Flat', 'location': 'Hyderabad' });
                    }
                    this.setState({
                        clientCriteria: { 'propertyFor': 'Sale', 'propertyType': 'Flat', 'location': 'Hyderabad' }
                    })
                    let defaultCriteria = this.state.filterCriteria;
                    defaultCriteria['criteria'].push({ key: 'propertyFor', value: 'Sale', type: 'eq' },
                        { key: 'propertyType', value: 'Flat', type: 'eq' },
                        { "key": "$or", "value": ["city", "locality", 'Hyderabad'], "type": "value" })
                    await this.getMyProperties('', defaultCriteria);
                }
            } else if (window.location.pathname === '/matched_properties') {
                let prevCriteria = (localStorage.getItem('myClientCriteria') && localStorage.getItem('myClientCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myClientCriteria')) : null;
                let prevFilterCriteria = (localStorage.getItem('myFilterCriteria') && localStorage.getItem('myFilterCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myFilterCriteria')) : null;

                await this.getMatchedProperties(this.props.location.state.state, prevFilterCriteria ? prevFilterCriteria : this.state.filterCriteria);
                await this.setState({
                    screen: window.location.pathname,
                    clientCriteria: prevCriteria ? prevCriteria : this.props.location.state.state.criteria,
                    matchedCriteria: this.props.location.state.state,
                    ClientIdForMatcheProps: this.props.location.state.state.clientId,
                    matchedFromScreen: this.props.location.state.matchedFromScreen,
                })

                if (this.state.clientCriteria && this.state.clientCriteria.propertyType && (this.state.clientCriteria.propertyType === '1RK' || this.state.clientCriteria.propertyType === 'Commercial')) {
                    await this.setState({
                        propertyType: this.state.clientCriteria.propertyType,
                    })
                }

            } else {
                let prevCriteria = (localStorage.getItem('myClientCriteria') && localStorage.getItem('myClientCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myClientCriteria')) : {};
                let prevFilterCriteria = (localStorage.getItem('myFilterCriteria') && localStorage.getItem('myFilterCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myFilterCriteria')) : this.state.filterCriteria;
                let fromScreen = (localStorage.getItem('fromScreen') && localStorage.getItem('fromScreen') !== 'undefined') ? JSON.parse(localStorage.getItem('fromScreen')) : '/dashboard';

                let propertyType = null;

                if (prevCriteria && prevCriteria.propertyType && (prevCriteria.propertyType === '1RK' || prevCriteria.propertyType === 'Commercial')) {
                    propertyType = prevCriteria.propertyType;
                }

                if (window.location.pathname === fromScreen) {
                    await this.setState({
                        currentFilterCriteria: prevFilterCriteria,
                        clientCriteria: prevCriteria,
                        propertyType: propertyType,
                    })
                    if (this.props.load) {
                        await this.props.load(this.state.clientCriteria);
                    }
                    await this.getMyProperties(user, this.state.currentFilterCriteria);
                } else {
                    if (this.props.load) {
                        await this.props.load({ 'propertyStatus':'New'});
                    }
                    this.setState({
                        clientCriteria: { 'propertyStatus': 'New' }
                    })
                    await this.getMyProperties();
                }
            }
            if (this.props.load) {
                const convertPricesInObject = (obj) => {
                    if (obj && obj.totalPriceMin) {
                        obj.totalPriceMin = this.convertFormattedAmountToDigits(obj.totalPriceMin);
                    }
                    if (obj && obj.totalPriceMax) {
                        obj.totalPriceMax = this.convertFormattedAmountToDigits(obj.totalPriceMax);
                    }
                    return obj;
                }

                const convertedProperty = convertPricesInObject(this.state.clientCriteria);

                if (this.props.location.pathname === '/matched_properties') {
                    if (convertedProperty.propertyFor && convertedProperty.propertyFor == 'Rent') {
                        this.setState({
                            totalPriceMaxOPtions: RentalPriceOptions,
                            totalPriceMinOPtions: RentalPriceOptions,
                            propertyFor: 'Rent'
                        })
                    } else if (convertedProperty.propertyFor && convertedProperty.propertyFor == 'Sale') {
                        this.setState({
                            totalPriceMaxOPtions: SoldPriceOptions,
                            totalPriceMinOPtions: SoldPriceOptions,
                            propertyFor: 'Sale'
                        })
                    }
                    this.setState({ referenceBudget: 'totalPriceMin' });
                    this.budgetChange('totalPriceMin', convertedProperty.totalPriceMin)
                    this.props.load(convertedProperty);
                }
            }
        }
        localStorage.removeItem('myFilterCriteria');
        localStorage.removeItem('myClientCriteria');
        localStorage.removeItem('fromScreen');
    }

    convertFormattedAmountToDigits = (formattedAmount) => {
        const parts = formattedAmount.split(' ');
        const amount = parseFloat(parts[0].replace(/,/g, ''));

        if (parts.length > 1) {
            const unit = parts[1];
            if (unit === 'Lakhs') {
                return amount * 100000;
            } else if (unit === 'Crores') {
                return amount * 10000000;
            } else {
                return amount;
            }
        } else {
            return amount;
        }
    }

    // Function to get data for Matched Properties Screen
    getMatchedProperties = async (savedCriteria, filter, type) => {
        let id = savedCriteria._id;
        if (!id) {
            id = this.state.matchedCriteria._id
        }
        let filterCriteria = filter ? filter : this.props.location.state.prevFilterCriteria;
        let filterString = JSON.stringify(filterCriteria);
        let url = `criteria/getProperties?filter=${filterString}&id=${id}`;

        if (filterCriteria['page'] <= 1) {
            await this.setState({
                first: 0
            })
        } else {
            await this.setState({
                first: (filterCriteria['page'] - 1) * this.state.rows
            })
        }

        fetchMethodRequest('GET', url).then(async resp => {
            if (resp && resp.respCode) {
                let tempProperties = [];
                if (resp && resp.properties && resp.properties.length > 0) {
                    resp.properties.map(item => {
                        item['isChecked'] = false;
                        return tempProperties.push(item);
                    })
                    if (type && type === 'pagechange' && this.state.isGlobalChecked) {
                        await this.setState({
                            isGlobalChecked: !this.state.isGlobalChecked,
                        })
                        await this.handleDataOnGlobalCheckClicked(tempProperties).then(async resp => {
                            tempProperties = resp;
                        })
                        await this.setState({
                            isGlobalChecked: !this.state.isGlobalChecked,
                        })
                    } else if (window.location.pathname === '/matched_properties') {
                        let properties = [];
                        if (localStorage.getItem('matchedPropsSelected') && localStorage.getItem('matchedPropsSelected') !== 'undefined') {
                            properties = JSON.parse(localStorage.getItem('matchedPropsSelected'))
                        }
                        if (properties && properties.length > 0) {
                            for (let i = 0; i < tempProperties.length; i++) {
                                for (let k = 0; k < properties.length; k++) {
                                    if (tempProperties[i]._id === properties[k]._id) {
                                        tempProperties[i].isChecked = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    tempProperties = [...tempProperties];
                }

                await this.setState({
                    myProperties: tempProperties,
                    pagination: resp.pagination,
                    isLoading: false,
                    savedCriteriaId: id,
                    currentFilterCriteria: filterCriteria,
                })
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        })
    }


    // Function to get data for My Properties & Search Properties Screen
    getMyProperties = async (user, filterObject, type) => {
        let t = await JSON.parse(localStorage.getItem('langJson'));
        let loginUser;
        if (!user || !user.email) {
            loginUser = JSON.parse(localStorage.getItem('loginCredentials'));
        } else {
            loginUser = user
        }
        let filterCriteria = {};
        if (type && type === 'pagechange') {
            filterCriteria = filterObject;
        } else {
            filterCriteria = filterObject;
            if (!filterCriteria) {
                filterCriteria = this.state.filterCriteria;
            }
            if (filterCriteria['page'] <= 1) {
                await this.setState({
                    first: 0
                })
            } else {
                await this.setState({
                    first: (filterCriteria['page'] - 1) * this.state.rows
                })
            }
        }

        // Pushing email in FilterCriteria to get User properties in My Properties Screen
        if (window.location.pathname === '/my_properties') {
            filterCriteria['criteria'].push({ key: 'email', value: loginUser.email, type: 'eq' }, { key: 'propertyStatus', value: 'New', type: 'eq' });
            if (filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
                let uniq = {}
                filterCriteria['criteria'] = filterCriteria['criteria'].filter(obj => !uniq[obj.key] && (uniq[obj.key] = true));
            } else {
                filterCriteria['criteria'] = [{ key: 'email', value: loginUser.email, type: 'eq' }]
            }
        }
        if (window.location.pathname === '/show_matched_properties') {
            if(this.props.location.state && this.props.location.state.showPropertiesList && this.props.location.state.showPropertiesList.length > 0 ) {
                filterCriteria['criteria'] = [{ key: '_id', value: this.props.location.state.showPropertiesList, type: 'in' }]
            }
        }

        let filterString = JSON.stringify(filterCriteria);
        let url;
        if (type && type === 'applyFilters'){
            url = `properties?filter=${filterString}&isFilterApplied=${true}`;
        }else{
            url = `properties?filter=${filterString}`;
        }
        fetchMethodRequest('GET', url).then(async resp => {
            if (resp && resp.respCode) {
                let tempProperties = [];
                if (resp.properties && resp.properties.length > 0) {
                    resp.properties.map(item => {
                        item['isChecked'] = false;
                        return tempProperties.push(item);
                    })

                    if (type && type === 'pagechange' && this.state.isGlobalChecked) {
                        await this.setState({
                            isGlobalChecked: !this.state.isGlobalChecked,
                        })
                        await this.handleDataOnGlobalCheckClicked(tempProperties).then(async resp => {
                            tempProperties = resp;
                        })
                        await this.setState({
                            isGlobalChecked: !this.state.isGlobalChecked,
                        })
                    } else {
                        if (window.location.pathname === '/my_properties') {
                            let properties = [];
                            if (localStorage.getItem('myPropsSelected') && localStorage.getItem('myPropsSelected') !== 'undefined') {
                                properties = JSON.parse(localStorage.getItem('myPropsSelected'))
                            }
                            if (properties && properties.length > 0) {
                                for (let i = 0; i < tempProperties.length; i++) {
                                    for (let k = 0; k < properties.length; k++) {
                                        if (tempProperties[i]._id === properties[k]._id) {
                                            tempProperties[i].isChecked = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        } else if (window.location.pathname === '/search_properties') {
                            let properties = [];
                            if (localStorage.getItem('searchPropsSelected') && localStorage.getItem('searchPropsSelected') !== 'undefined') {
                                properties = JSON.parse(localStorage.getItem('searchPropsSelected'))
                            }
                            if (properties && properties.length > 0) {
                                for (let i = 0; i < tempProperties.length; i++) {
                                    for (let k = 0; k < properties.length; k++) {
                                        if (tempProperties[i]._id === properties[k]._id) {
                                            tempProperties[i].isChecked = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        } else if (window.location.pathname === '/show_matched_properties') {
                            let properties = [];
                            if (localStorage.getItem('showMatchedPropsSelected') && localStorage.getItem('showMatchedPropsSelected') !== 'undefined') {
                                properties = JSON.parse(localStorage.getItem('showMatchedPropsSelected'))
                            }
                            if (properties && properties.length > 0) {
                                for (let i = 0; i < tempProperties.length; i++) {
                                    for (let k = 0; k < properties.length; k++) {
                                        if (tempProperties[i]._id === properties[k]._id) {
                                            tempProperties[i].isChecked = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    tempProperties = [...tempProperties];
                }

                await this.setState({
                    isLoading: false,
                    currentFilterCriteria: filterCriteria,
                    myProperties: tempProperties,
                    pagination: resp.pagination,
                    t: t,
                    clientCriteria: this.state.clientCriteria,
                })
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        })
    }

    //on changing pagination
    onPageChange = async (event, filterObject) => {
        if (this.state.savedCriteriaId) {
            await this.setState({
                isLoading: true,
            })
            let filterCriteria = this.state.filterCriteria;
            let currentPage = event.page + 1;
            filterCriteria['limit'] = event.rows;
            filterCriteria['page'] = currentPage;
            await this.setState({
                rows: event.rows,
                page: event.page,
                first: event.first,
                pageCount: event.pageCount,
            })
            await this.getMatchedProperties(this.props.location.state.state, filterCriteria, 'pagechange');
        } else {
            await this.setState({
                isLoading: true,
            })
            let user = JSON.parse(localStorage.getItem('loginCredentials'));
            let filterCriteria = filterObject;
            let currentPage = event.page + 1;
            filterCriteria['limit'] = event.rows;
            filterCriteria['page'] = currentPage;
            await this.setState({
                rows: event.rows,
                page: event.page,
                first: event.first,
                pageCount: event.pageCount,
                currentFilterCriteria: filterCriteria,
            })
            await this.getMyProperties(user, filterCriteria, 'pagechange');
        }
    }

    handleChange = async (name, val) => {
        if (name === 'totalPriceMin' && this.state.priceMinError) {
            await this.setState({
                [`${name}`]: val,
                priceMinError: '',
            })
        } else if (name === 'totalPriceMax' && this.state.priceMaxError) {
            await this.setState({
                [`${name}`]: val,
                priceMaxError: '',
            })
        } else if (name === 'builtUpAreaMin' && this.state.areaMinError) {
            await this.setState({
                [`${name}`]: val,
                areaMinError: '',
            })
        } else if (name === 'builtUpAreaMax' && this.state.areaMaxError) {
            await this.setState({
                [`${name}`]: val,
                areaMaxError: '',
            })
        } else if (name === 'propertyFor') {
            await this.setState({
                [`${name}`]: val,
            })
            if (val === 'Rent') {
                this.setState({
                    totalPriceMaxOPtions: RentalPriceOptions,
                    totalPriceMinOPtions: RentalPriceOptions
                })
            } else {
                this.setState({
                    totalPriceMaxOPtions: SoldPriceOptions,
                    totalPriceMinOPtions: SoldPriceOptions
                })
            }
            this.clearBudgetFilters()
            this.setState({ referenceBudget: null })
        }
        else {
            await this.setState({
                [`${name}`]: val,
            })
        }
    }

    // Submit Handler on Clicking Apply Filter button
    applyFilter = async (values) => {
        localStorage.removeItem('myPropsSelected');
        localStorage.removeItem('searchPropsSelected');
        localStorage.removeItem('matchedPropsSelected');
        localStorage.removeItem('showMatchedPropsSelected');

        localStorage.removeItem('myIsGlobalChecked');
        localStorage.removeItem('searchIsGlobalChecked');
        localStorage.removeItem('matchedIsGlobalChecked');
        localStorage.removeItem('showMatchedIsGlobalChecked');

        localStorage.removeItem('myPropsDeselected');
        localStorage.removeItem('searchPropsDeselected');
        localStorage.removeItem('matchedPropsDeselected');
        localStorage.removeItem('showMatchedPropsDeSelected');


        await this.setState({
            isLoading: true,
        })
        let criteria = [];
        let clientCriteria = null;
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let filterCriteria = this.state.currentFilterCriteria ? this.state.currentFilterCriteria : this.state.filterCriteria;
        filterCriteria.criteria = [];
        
        if (values.propertyStatus) {
            criteria.push({ key: 'propertyStatus', value: values.propertyStatus, type: 'eq' })
        }
        if (values.propertyFor) {
            criteria.push({ key: 'propertyFor', value: values.propertyFor, type: 'eq' })
        }
        if (values.propertyType) {
            criteria.push({ key: 'propertyType', value: values.propertyType, type: 'eq' })
        }
        if (values.location) {
            criteria.push({ "key": "$or", "value": ["city", "locality", values.location], "type": "value" })
        }
        if (values.totalPriceMin || values.totalPriceMax) {
            if (values.totalPriceMin && values.totalPriceMax) {
                criteria.push({ key: 'totalPrice', value: { "$gte": values.totalPriceMin.replace(/,/g, ''), "$lte": values.totalPriceMax.replace(/,/g, '') }, type: 'eq' })
            } else if (values.totalPriceMin) {
                criteria.push({ key: 'totalPrice', value: { "$gte": values.totalPriceMin.replace(/,/g, '') }, type: 'eq' })
            }
            else if (values.totalPriceMax) {
                criteria.push({ key: 'totalPrice', value: { "$lte": values.totalPriceMax.replace(/,/g, '') }, type: 'eq' })
            }
        }
        if (values.builtUpAreaMin || values.builtUpAreaMax) {
            if (values.builtUpAreaMin && values.builtUpAreaMax) {
                criteria.push({ key: 'builtUpArea', value: { "$gte": values.builtUpAreaMin.replace(/,/g, ''), "$lte": values.builtUpAreaMax.replace(/,/g, '') }, type: 'eq' })
            } else if (values.builtUpAreaMin) {
                criteria.push({ key: 'builtUpArea', value: { "$gte": values.builtUpAreaMin.replace(/,/g, ''), "$lte": values.builtUpAreaMax }, type: 'eq' })
            } else if (values.builtUpAreaMax) {
                criteria.push({ key: 'builtUpArea', value: { "$gte": values.builtUpAreaMin, "$lte": values.builtUpAreaMax }, type: 'eq' })
            }
        }
        if (values.facing) {
            criteria.push({ key: 'facing', value: values.facing, type: 'eq' })
        }
        if (values.bedRooms) {
            criteria.push({ key: 'bedRooms', value: values.bedRooms, type: 'eq' })
        }
        if (values.bathRooms) {
            criteria.push({ key: 'bathRooms', value: values.bathRooms, type: 'eq' })
        }
        if (values.availability) {
            criteria.push({ key: 'availability', value: values.availability, type: 'eq' })
        }
        if (values.furnishingType) {
            criteria.push({ key: 'furnishingType', value: values.furnishingType, type: 'eq' })
        }

        if (criteria && criteria.length > 0) {
            filterCriteria.criteria = criteria;
        } else {
            filterCriteria.criteria = [];
        }
        if (values && values.facing === null) {
            delete values['facing'];
        }

        clientCriteria = values;
        let x = Object.keys(values).length;
        if (x && x > 0) {
            filterCriteria['page'] = 1;
            await this.getMyProperties(user, filterCriteria, 'applyFilters')
            await this.setState({
                clientCriteria: clientCriteria,
                currentCriteria: values,
                currentFilterCriteria: filterCriteria,
                savedCriteriaId: null,
                isGlobalChecked: false,
            });
        } else {
            await this.setState({
                myProperties: [],
                isLoading: false,
                clientCriteria: null,
                currentCriteria: values,
                currentFilterCriteria: filterCriteria,
                isGlobalChecked: false,
            });
        }
    }

    // Handler to Reset Filters in Filter Container
    resetFilters = async (reset) => {
        localStorage.removeItem('myPropsSelected')
        localStorage.removeItem('searchPropsSelected')
        localStorage.removeItem('matchedPropsSelected')

        localStorage.removeItem('myIsGlobalChecked')
        localStorage.removeItem('searchIsGlobalChecked')
        localStorage.removeItem('matchedIsGlobalChecked')
        localStorage.removeItem('showMatchedIsGlobalChecked')

        localStorage.removeItem('myPropsDeselected');
        localStorage.removeItem('searchPropsDeselected');
        localStorage.removeItem('matchedPropsDeselected');

        await this.setState({
            isLoading: true,
            priceMaxError: '',
            priceMinError: '',
            areaMaxError: '',
            areaMinError: '',
        });

        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let filterCriteria = this.state.filterCriteria;
        filterCriteria.criteria = [];

        if (window.location.pathname === '/my_properties') {
            await this.getMyProperties(user, filterCriteria);
            await this.setState({ isLoading: false, clientCriteria: null, applyFilterCicked: false });
        } else {
            await this.setState({
                isLoading: false,
                priceMaxError: '',
                priceMinError: '',
                areaMaxError: '',
                areaMinError: '',
                myProperties: [],
                clientCriteria: null,
            })
        }

        if (reset) {
            reset();
            if (this.searchFacingSelectRef) {
                this.searchFacingSelectRef.handleChange(null);
            }
            this.clearBudgetFilters()
            this.setState({
                totalPriceMaxOPtions: SoldPriceOptions,
                totalPriceMinOPtions: SoldPriceOptions,
            })
        }
        if (this.props.load) {
            this.props.load({})
        }
    }

    // Handle View Photos Modal
    viewPhotosModal = async (photos) => {
        if (photos && photos === 'close') {
            await this.setState({
                propertyPhotos: null,
                isViewPhotosModalOpen: false
            })
        } else if (photos) {
            await this.setState({
                propertyPhotos: photos,
                isViewPhotosModalOpen: true
            })
        }
    }

    // Handler to show or hide Contact info on Property Card
    handleContactInfo = (item) => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let id = item._id;
        let properties = this.state.myProperties;
        let objIndex = properties.findIndex((obj => obj._id === id));
        properties[objIndex].showContactInfo = !properties[objIndex].showContactInfo;
        let newArray = [...properties];

        if (item.showContactInfo) {
            socket.emit('agentViewed', { userId: user._id, email: item.email })
        }
        this.setState({
            myProperties: newArray,
        })
    }

    // Handler to open Save Search to any Client Modal
    openClientSelectModel = async (currentFilter) => {
        let filterCriteria = currentFilter;
        delete filterCriteria['limit'];
        delete filterCriteria['page'];

        let filterString = JSON.stringify(filterCriteria);
        let url = `properties?filter=${filterString}`;

        fetchMethodRequest('GET', url).then(async resp => {
            if (resp && resp.respCode) {
                await this.setState({
                    saveSearchProperties: resp.properties,
                    isOpenClientSelectModel: true,
                    saveSearchFilterObject: resp.query,
                })
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        })
    }

    reNewProperty = (values) => {
        let loginUser = JSON.parse(localStorage.getItem('loginCredentials'));
        let url = `properties/${values._id}`;
        let method = 'PUT';
        values['propertyStatus'] = 'New';

        fetchMethodRequest(method, url, values).then(async res => {
            if (res && res.respCode) {
                showToasterMessage(res.respMessage, 'success')
                await this.getMyProperties(loginUser, this.state.currentFilterCriteria);
            } else {
                showToasterMessage(res.errorMessage, 'error')
            }
        }).catch(err => {
            showToasterMessage(err, 'error')
            return err
        })
    }

    openConfirmationModal = async (item) => {
        await this.setState({
            selectedData: item,
            openConfirmActionModal: true,
            actionType: 'updateProperty'
        })
    }

    UpdateSelectedProperty = () => {
        this.setState({
            openConfirmActionModal: false,
        })
        this.reNewProperty(this.state.selectedData);
    }

    // Handler to close Save Searches Modal
    closeClientSelectModal = async (type) => {
        if (type && type === 'saved') {
            this.props.reset();
            this.props.load({});
            await this.setState({
                isOpenClientSelectModel: false,
                // myProperties: [],
                // clientCriteria: null
            })
            if(this.props.load){
                this.props.load(this.state.clientCriteria)
            }
        } else {
            await this.setState({
                isOpenClientSelectModel: false,
            })
        }
    }

    // Handler to update Saved Criteria
    updateClientCriteria = async () => {
        await this.setState({
            isLoading: true,
        })
        let criteriaId = this.state.matchedCriteria._id;
        let url = '';
        let selectedProperties = [];
        let saveSearchFilterObject = {};
        let body = {};
        let selectedIds = [];

        let filterCriteria = this.state.currentFilterCriteria;
        delete filterCriteria['limit'];
        delete filterCriteria['page'];

        let filterString = JSON.stringify(filterCriteria);

        await fetchMethodRequest('GET', `properties?filter=${filterString}`).then(async resp => {
            if (resp && resp.respCode) {
                selectedProperties = resp.properties;
                saveSearchFilterObject = resp.query;
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        })

        let clientCriteria = this.state.clientCriteria;

        if (clientCriteria.totalPriceMax) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.totalPriceMax;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.totalPriceMax;
            }
            clientCriteria['totalPriceMaxDigits'] = finalTempNumber;
            clientCriteria['totalPriceMax'] = finalTempString;
        } else {
            delete clientCriteria['totalPriceMaxDigits'];
            delete clientCriteria['totalPriceMax'];
        }

        if (clientCriteria.totalPriceMin) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.totalPriceMin;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.totalPriceMin;
            }

            clientCriteria['totalPriceMinDigits'] = finalTempNumber
            clientCriteria['totalPriceMin'] = finalTempString
        } else {
            delete clientCriteria['totalPriceMinDigits']
            delete clientCriteria['totalPriceMin']
        }

        if (clientCriteria.builtUpAreaMax) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.builtUpAreaMax;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.builtUpAreaMax;
            }

            clientCriteria['builtUpAreaMaxDigits'] = finalTempNumber
            clientCriteria['builtUpAreaMax'] = finalTempString
        } else {
            delete clientCriteria['builtUpAreaMaxDigits']
            delete clientCriteria['builtUpAreaMax']
        }

        if (clientCriteria.builtUpAreaMin) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.builtUpAreaMin;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.builtUpAreaMin;
            }

            clientCriteria['builtUpAreaMinDigits'] = finalTempNumber
            clientCriteria['builtUpAreaMin'] = finalTempString
        } else {
            delete clientCriteria['builtUpAreaMinDigits']
            delete clientCriteria['builtUpAreaMin']
        }

        if (selectedProperties && selectedProperties.length > 0) {
            for (let i = 0; i < selectedProperties.length; i++) {
                selectedIds.push(selectedProperties[i]._id);
            }
        } else {
            selectedIds = [];
        }

        if (clientCriteria['facing'] === null) {
            delete clientCriteria['facing'];
        }

        body = {
            criteria: clientCriteria,
            result: selectedIds,
            search: { filter: saveSearchFilterObject }
        }

        url = `criteria/${criteriaId}`;

        await fetchMethodRequest('PUT', url, body).then(async resp => {
            if (resp && resp.respCode) {
                showToasterMessage(resp.respMessage, 'success')
                await this.setState({
                    isLoading: false,
                    redirectToPrevScreenFromMatchedProps: true,
                })
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        })
    }

    // Handler to redirect to previuos screen from Matched properties
    redirectToPrevScreenFromMatchedProps = () => {
        this.setState({
            redirectToPrevScreenFromMatchedProps: true,
        })
    }

    // Handler triggered when clicked on Select All or Clear All Button
    handleGlobalCheckBox = async (data) => {
        await this.handleDataOnGlobalCheckClicked(data).then(async resp => {
            await this.setState({
                isGlobalChecked: !this.state.isGlobalChecked,
                myProperties: resp,
            })
        })
        if (window.location.pathname === '/my_properties') {
            localStorage.setItem('myIsGlobalChecked', JSON.stringify(this.state.isGlobalChecked));
        } else if (window.location.pathname === '/search_properties') {
            localStorage.setItem('searchIsGlobalChecked', JSON.stringify(this.state.isGlobalChecked));
        } else if (window.location.pathname === '/matched_properties') {
            localStorage.setItem('matchedIsGlobalChecked', JSON.stringify(this.state.isGlobalChecked));
        } else if(window.location.pathname === '/show_matched_properties') {
            localStorage.setItem('showMatchedIsGlobalChecked', JSON.stringify(this.state.isGlobalChecked));
        }
    }

    // Handler to manipulate select all or clear all action
    handleDataOnGlobalCheckClicked = async (data) => {
        let myPropsSelected = [];
        let searchPropsSelected = [];
        let matchedPropsSelected = [];
        let showMatchedPropsSelected = [];

        let myPropsDeselected = [];
        let searchPropsDeselected = [];
        let matchedPropsDeselected = [];
        let showMatchedPropsDeSelected = [];

        let properties = data;
        let concatAndDeDuplicateObjects = (p, ...arrs) => [].concat(...arrs).reduce((a, b) => !a.filter(c => b[p] === c[p]).length ? [...a, b] : a, []);
        if (window.location.pathname === '/my_properties') {
            myPropsSelected = localStorage.getItem('myPropsSelected') ? JSON.parse(localStorage.getItem('myPropsSelected')) : [];
            myPropsDeselected = localStorage.getItem('myPropsDeselected') ? JSON.parse(localStorage.getItem('myPropsDeselected')) : [];

            if (this.state.isGlobalChecked) {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }
                localStorage.removeItem('myPropsSelected');
                localStorage.removeItem('myPropsDeselected');
            } else {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }

                myPropsSelected = concatAndDeDuplicateObjects('_id', myPropsSelected, properties);

                for (let i = 0; i < properties.length; i++) {
                    for (let j = 0; j < myPropsDeselected.length; j++) {
                        if (myPropsDeselected[j]._id === properties[i]._id) {
                            properties[i].isChecked = false;
                        }
                    }
                }
                for (let i = 0; i < myPropsSelected.length; i++) {
                    for (let j = 0; j < myPropsDeselected.length; j++) {
                        if (myPropsDeselected[j]._id === myPropsSelected[i]._id) {
                            myPropsSelected.splice(i, 1);
                            i--;
                        }
                    }
                }
                localStorage.setItem('myPropsSelected', JSON.stringify(myPropsSelected));
            }
        } else if (window.location.pathname === '/search_properties') {
            searchPropsSelected = (localStorage.getItem('searchPropsSelected') && localStorage.getItem('searchPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('searchPropsSelected')) : [];
            searchPropsDeselected = (localStorage.getItem('searchPropsDeselected') && localStorage.getItem('searchPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('searchPropsDeselected')) : [];

            if (this.state.isGlobalChecked) {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }
                localStorage.removeItem('searchPropsSelected');
                localStorage.removeItem('searchPropsDeselected');
            } else {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }

                searchPropsSelected = concatAndDeDuplicateObjects('_id', searchPropsSelected, properties);

                for (let i = 0; i < properties.length; i++) {
                    for (let j = 0; j < searchPropsDeselected.length; j++) {
                        if (searchPropsDeselected[j]._id === properties[i]._id) {
                            properties[i].isChecked = false;
                        }
                    }
                }
                for (let i = 0; i < searchPropsSelected.length; i++) {
                    for (let j = 0; j < searchPropsDeselected.length; j++) {
                        if (searchPropsDeselected[j]._id === searchPropsSelected[i]._id) {
                            searchPropsSelected.splice(i, 1);
                            i--;
                        }
                    }
                }
                localStorage.setItem('searchPropsSelected', JSON.stringify(searchPropsSelected));
            }
        } else if (window.location.pathname === '/matched_properties') {
            matchedPropsSelected = (localStorage.getItem('matchedPropsSelected') && localStorage.getItem('matchedPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('matchedPropsSelected')) : [];
            matchedPropsDeselected = (localStorage.getItem('matchedPropsDeselected') && localStorage.getItem('matchedPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('matchedPropsDeselected')) : [];

            if (this.state.isGlobalChecked) {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }
                localStorage.removeItem('matchedPropsSelected');
                localStorage.removeItem('matchedPropsDeselected');
            } else {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }

                matchedPropsSelected = concatAndDeDuplicateObjects('_id', matchedPropsSelected, properties);

                for (let i = 0; i < properties.length; i++) {
                    for (let j = 0; j < matchedPropsDeselected.length; j++) {
                        if (matchedPropsDeselected[j]._id === properties[i]._id) {
                            properties[i].isChecked = false;
                        }
                    }
                }
                for (let i = 0; i < matchedPropsSelected.length; i++) {
                    for (let j = 0; j < matchedPropsDeselected.length; j++) {
                        if (matchedPropsDeselected[j]._id === matchedPropsSelected[i]._id) {
                            matchedPropsSelected.splice(i, 1);
                            i--;
                        }
                    }
                }
                localStorage.setItem('matchedPropsSelected', JSON.stringify(matchedPropsSelected));
            }
        }
        else if (window.location.pathname === '/show_matched_properties') {
            showMatchedPropsSelected = (localStorage.getItem('showMatchedPropsSelected') && localStorage.getItem('showMatchedPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('showMatchedPropsSelected')) : [];
            showMatchedPropsDeSelected = (localStorage.getItem('showMatchedPropsDeSelected') && localStorage.getItem('showMatchedPropsDeSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('showMatchedPropsDeSelected')) : [];

            if (this.state.isGlobalChecked) {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }
                localStorage.removeItem('showMatchedPropsSelected');
                localStorage.removeItem('showMatchedPropsDeSelected');
            } else {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }

                showMatchedPropsSelected = concatAndDeDuplicateObjects('_id', showMatchedPropsSelected, properties);

                for (let i = 0; i < properties.length; i++) {
                    for (let j = 0; j < showMatchedPropsDeSelected.length; j++) {
                        if (showMatchedPropsDeSelected[j]._id === properties[i]._id) {
                            properties[i].isChecked = false;
                        }
                    }
                }
                showMatchedPropsSelected = showMatchedPropsSelected.filter(selectedProp =>
                    !showMatchedPropsDeSelected.some(deSelectedProp => deSelectedProp._id === selectedProp._id)
                );           
                localStorage.setItem('showMatchedPropsSelected', JSON.stringify(showMatchedPropsSelected));
            }
        }

        let newArray = [...properties];

        return newArray;
    }

    // Handler to Check or Un-Check Individual Property
    handleCheckBox = async (item) => {
        let id = item._id;
        let properties = this.state.myProperties;
        let objIndex = properties.findIndex((obj => obj._id === id));

        let myPropsSelected = [];
        let searchPropsSelected = [];
        let matchedPropsSelected = [];
        let prop = [];
        let showMatchedPropsSelected = []

        let myPropsDeselected = [];
        let searchPropsDeselected = [];
        let matchedPropsDeselected = [];
        let delProp = [];
        let showMatchedPropsDeSelected = [];
        let concatAndDeDuplicateObjects = (p, ...arrs) => [].concat(...arrs).reduce((a, b) => !a.filter(c => b[p] === c[p]).length ? [...a, b] : a, []);

        if (window.location.pathname === '/my_properties') {
            myPropsSelected = (localStorage.getItem('myPropsSelected') && localStorage.getItem('myPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('myPropsSelected')) : []
            myPropsDeselected = (localStorage.getItem('myPropsDeselected') && localStorage.getItem('myPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('myPropsDeselected')) : []

            if (properties[objIndex].isChecked) {
                delProp.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;
                let propIndex = myPropsSelected.findIndex((obj => obj._id === id));
                myPropsSelected.splice(propIndex, 1);

                myPropsDeselected = concatAndDeDuplicateObjects('_id', myPropsDeselected, delProp);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('myPropsDeselected', JSON.stringify(myPropsDeselected));
                }

                localStorage.setItem('myPropsSelected', JSON.stringify(myPropsSelected));
            } else {
                prop.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;

                let propIndex = myPropsDeselected.findIndex((obj => obj._id === id));
                myPropsDeselected.splice(propIndex, 1);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('myPropsDeselected', JSON.stringify(myPropsDeselected));
                }

                myPropsSelected = concatAndDeDuplicateObjects('_id', myPropsSelected, prop);

                localStorage.setItem('myPropsSelected', JSON.stringify(myPropsSelected));
            }
        } else if (window.location.pathname === '/search_properties') {
            searchPropsSelected = (localStorage.getItem('searchPropsSelected') && localStorage.getItem('searchPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('searchPropsSelected')) : []
            searchPropsDeselected = (localStorage.getItem('searchPropsDeselected') && localStorage.getItem('searchPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('searchPropsDeselected')) : []

            if (properties[objIndex].isChecked) {
                delProp.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;
                let propIndex = searchPropsSelected.findIndex((obj => obj._id === id));
                searchPropsSelected.splice(propIndex, 1);

                searchPropsDeselected = concatAndDeDuplicateObjects('_id', searchPropsDeselected, delProp);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('searchPropsDeselected', JSON.stringify(searchPropsDeselected));
                }

                localStorage.setItem('searchPropsSelected', JSON.stringify(searchPropsSelected));
            } else {
                prop.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;

                let propIndex = searchPropsDeselected.findIndex((obj => obj._id === id));
                searchPropsDeselected.splice(propIndex, 1);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('searchPropsDeselected', JSON.stringify(searchPropsDeselected));
                }

                searchPropsSelected = concatAndDeDuplicateObjects('_id', searchPropsSelected, prop);

                localStorage.setItem('searchPropsSelected', JSON.stringify(searchPropsSelected));
            }
        } else if (window.location.pathname === '/matched_properties') {
            matchedPropsSelected = (localStorage.getItem('matchedPropsSelected') && localStorage.getItem('matchedPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('matchedPropsSelected')) : []
            matchedPropsDeselected = (localStorage.getItem('matchedPropsDeselected') && localStorage.getItem('matchedPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('matchedPropsDeselected')) : []

            if (properties[objIndex].isChecked) {
                delProp.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;
                let propIndex = matchedPropsSelected.findIndex((obj => obj._id === id));
                matchedPropsSelected.splice(propIndex, 1);

                matchedPropsDeselected = concatAndDeDuplicateObjects('_id', matchedPropsDeselected, delProp);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('matchedPropsDeselected', JSON.stringify(matchedPropsDeselected));
                }

                localStorage.setItem('matchedPropsSelected', JSON.stringify(matchedPropsSelected));
            } else {
                prop.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;

                let propIndex = matchedPropsDeselected.findIndex((obj => obj._id === id));
                matchedPropsDeselected.splice(propIndex, 1);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('matchedPropsDeselected', JSON.stringify(matchedPropsDeselected));
                }

                matchedPropsSelected = concatAndDeDuplicateObjects('_id', matchedPropsSelected, prop);

                localStorage.setItem('matchedPropsSelected', JSON.stringify(matchedPropsSelected));
            }
        } else if (window.location.pathname === '/show_matched_properties') {
            showMatchedPropsSelected = (localStorage.getItem('showMatchedPropsSelected') && localStorage.getItem('showMatchedPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('showMatchedPropsSelected')) : []
            showMatchedPropsDeSelected = (localStorage.getItem('showMatchedPropsDeSelected') && localStorage.getItem('showMatchedPropsDeSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('showMatchedPropsDeSelected')) : []

            if (properties[objIndex].isChecked) {
                delProp.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;
                let propIndex = showMatchedPropsSelected.findIndex((obj => obj._id === id));
                showMatchedPropsSelected.splice(propIndex, 1);

                showMatchedPropsDeSelected = concatAndDeDuplicateObjects('_id', showMatchedPropsDeSelected, delProp);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('showMatchedPropsDeSelected', JSON.stringify(showMatchedPropsDeSelected));
                }

                localStorage.setItem('showMatchedPropsSelected', JSON.stringify(showMatchedPropsSelected));
            } else {
                prop.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;

                let propIndex = showMatchedPropsDeSelected.findIndex((obj => obj._id === id));
                showMatchedPropsDeSelected.splice(propIndex, 1);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('showMatchedPropsDeSelected', JSON.stringify(showMatchedPropsDeSelected));
                }

                showMatchedPropsSelected = concatAndDeDuplicateObjects('_id', showMatchedPropsSelected, prop);

                localStorage.setItem('showMatchedPropsSelected', JSON.stringify(showMatchedPropsSelected));
            }
        }

        let newArray = [...properties];

        await this.setState({
            myProperties: newArray,
        })
    }

    // Handler to share selected properties
    handleGLobalShare = async () => {
        let propsToShare = [];
        if (window.location.pathname === '/my_properties') {
            if (localStorage.getItem('myPropsSelected') && localStorage.getItem('myPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('myPropsSelected'))
            }
        } else if (window.location.pathname === '/search_properties') {
            if (localStorage.getItem('searchPropsSelected') && localStorage.getItem('searchPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('searchPropsSelected'))
            }
        } else if (window.location.pathname === '/matched_properties') {
            if (localStorage.getItem('matchedPropsSelected') && localStorage.getItem('matchedPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('matchedPropsSelected'))
            }
        } else if (window.location.pathname === '/show_matched_properties') {
            if (localStorage.getItem('showMatchedPropsSelected') && localStorage.getItem('showMatchedPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('showMatchedPropsSelected'))
            }
        }
        let selectedProperties = propsToShare;

        if (selectedProperties && selectedProperties.length > config.shareLimit) {
            await this.setState({
                isOpenShareLimitModal: true
            })
        } else {
            await this.setState({
                isOpenSharePropsModal: true,
                selectedProperties: selectedProperties,
            })
        }
    }
      
      



    closeSharePropsModal = async () => {
        await this.setState({
            isOpenSharePropsModal: false,
        })
    }

    closeShareLimitModal = async () => {
        await this.setState({
            isOpenShareLimitModal: false,
        })
    }

    //Open Confirmation Modal For Deleting Property/Properties
    deleteConfirmAction = async (event, propertyId) => {
        if (propertyId) {
            await this.setState({
                deleteRowDataId: propertyId,
                openConfirmActionModal: true,
                actionTitle: 'propertyDeleteConfirmationText',
                actionType: 'deleteProperty'
            });
        } else {
            let propsToShare = [];
            if (localStorage.getItem('myPropsSelected') && localStorage.getItem('myPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('myPropsSelected'))
            }

            let selectedRowsId = [];
            let selectedRows = propsToShare;

            if (selectedRows && selectedRows.length && selectedRows.length > 0) {
                selectedRows.forEach((item, index) => {
                    selectedRowsId.push(item._id)
                })
                await this.setState({
                    selectedRowsId: selectedRowsId,
                    openConfirmActionModal: true,
                    actionTitle: 'deletePropConfirmationTitle',
                    actionType: 'deleteProperty'
                })
            }
        }
    }

    // Close Confirm Action Modal
    closeConfirmActionModal = async () => {
        await this.setState({
            openConfirmActionModal: false,
            deleteRowDataId: null,
        })
    }

    // Delete selected Property
    deleteSelectedRow = async () => {
        let method, url, body = {};

        url = 'properties/deleteProperties';
        method = 'PUT';

        if (this.state.deleteRowDataId) {
            let ids = []
            ids.push(this.state.deleteRowDataId);
            body['properties'] = [...ids]
        } else {
            body['properties'] = [...this.state.selectedRowsId];
        }

        fetchMethodRequest(method, url, body).then(async (response) => {
            this.setState({
                openConfirmActionModal: false
            });
            if (response && response.respCode) {
                showToasterMessage(response.respMessage, 'success');
                if (this.state.deleteRowDataId) {
                } else {
                    localStorage.removeItem('myPropsSelected')
                }
                await this.getMyProperties();

            } else {
                if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(response.errorMessage, 'error');
                }
            }
        }).catch((err) => {
            return err;
        });
    }

    handlePropCardClick = (e) => {
        if (e._dispatchInstances && e._dispatchInstances.length && e._dispatchInstances.length > 1) {
            e.preventDefault();
        }
    }

    budgetChange = (type, value) => {
        if (!this.state.referenceBudget) {
            this.setState({ referenceBudget: type })
        }
        if (this.state.referenceBudget === 'totalPriceMin' && type === 'totalPriceMin') {
            if (this.totalPriceMaxRef) {
                this.totalPriceMaxRef.handleChange(null);
            }
        }
        if (this.state.referenceBudget === 'totalPriceMax' && type === 'totalPriceMax') {
            if (this.totalPriceMinRef) {
                this.totalPriceMinRef.handleChange(null);
            }
        }
        if (type === 'totalPriceMin') {
            if (!this.state.referenceBudget || this.state.referenceBudget === 'totalPriceMin') {
                let maxOptions = this.state.propertyFor === 'Rent' ? RentalPriceOptions : SoldPriceOptions;
                let valueIndex = maxOptions.findIndex((opt) => opt.value == value);
                if (valueIndex >= 0)
                    maxOptions = maxOptions.filter((opt, ind) => ind > valueIndex);
                this.setState({ totalPriceMaxOPtions: maxOptions });
            }

        } else {
            if (!this.state.referenceBudget || this.state.referenceBudget === 'totalPriceMax') {
                let minOptions = this.state.propertyFor === 'Rent' ? RentalPriceOptions : SoldPriceOptions;
                let valueIndex = minOptions.findIndex((opt) => opt.value == value);
                if (valueIndex >= 0)
                    minOptions = minOptions.filter((opt, ind) => ind < valueIndex);
                this.setState({ totalPriceMinOPtions: minOptions });
            }
        }
    }

    clearBudgetFilters = () => {
        if (this.totalPriceMinRef) {
            this.totalPriceMinRef.handleChange(null);
        }
        if (this.totalPriceMaxRef) {
            this.totalPriceMaxRef.handleChange(null);
        }
    }

    render() {
        const { tl, myProperties, loginUser } = this.state;
        const { handleSubmit, reset, t } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        const isAtleastOneChecked = myProperties.findIndex((obj => obj.isChecked === true)) + 1;

        window.addEventListener("resize", async (event) => {
            if (document.body.clientWidth >= 576) {
                await this.setState({ isFilterShown: true })
            }
        })

        return (
            <div className='addPropertyScreen'>
                <Loader className='propertiesScreenLoader' loader={this.state.isLoading} />
                <Row className='mx-1 mx-md-2'>
                    {/* Page headings start here */}
                    {window.location.pathname === '/search_properties' ?
                        <div className='hideInMobile text-center mx-auto'>
                            <span className='pageHeadingCss'>
                                {tl['searchProperties'] ? tl['searchProperties'] : 'Search Properties'}
                            </span>
                        </div>
                        : null
                    }
                    {window.location.pathname === '/matched_properties' ?
                        <div className=' text-center mx-auto'>
                            <span className='hideInMobile pageHeadingCss'>
                                {tl['matchedProps'] ? tl['matchedProps'] : 'Matched Properties'}
                            </span>
                            {this.props.location.state && this.props.location.state.searchName && <div className='propertyCardHeading mb-2'> {this.props.location.state.searchName}</div>}
                        </div>
                        : null
                    }
                    {window.location.pathname === '/my_properties' ?
                        <div className='hideInMobile text-center mx-auto'>
                            <span className='pageHeadingCss'>
                                {tl['myProperties'] ? tl['myProperties'] : 'My Properties'}
                            </span>
                        </div>
                        : null
                    }
                    {
                        window.location.pathname === '/show_matched_properties' ?
                        <div className='hideInMobile text-center mx-auto'>
                            <span className='pageHeadingCss'>
                            {tl['matchedProps'] ? tl['matchedProps'] : 'Matched Properties'}
                            </span>
                        </div>
                        : null
                    }
                    {/* Page headings end here */}

                    {/* Page header buttons start here */}
                    <div style={{ width: '100%' }}>
                        {window.location.pathname && window.location.pathname === '/search_properties' ?
                            <div className='text-right d-flex justify-content-end'>
                               {
                                    this.state.clientCriteria &&
                                        this.state.myProperties && this.state.myProperties.length > 0 ?
                                        <Button
                                            color='primary'
                                            onClick={() => this.handleGlobalCheckBox(this.state.myProperties)}
                                            className='mr-0 ml-2 buttonTextWeight'
                                        >
                                            {this.state.isGlobalChecked ?
                                                tl['clearAll'] ? tl['clearAll'] : 'Clear All'
                                                : tl['selectAll'] ? tl['selectAll'] : 'Select All'
                                            }
                                        </Button>
                                        : null
                                }
                                {
                                    this.state.clientCriteria ?
                                        <Button
                                            color={'primary'}
                                            onClick={
                                                (this.state.clientCriteria || this.state.currentFilterCriteria) ?
                                                    () => this.openClientSelectModel(this.state.currentFilterCriteria)
                                                    : null
                                            }
                                            className='mr-0 ml-2 buttonTextWeight'
                                        >
                                            {tl['saveSearch'] ? tl['saveSearch'] : 'Save Search'}
                                        </Button>
                                        : null
                                }
                                {
                                    this.state.clientCriteria &&
                                        this.state.myProperties && this.state.myProperties.length > 0 ?
                                        <ShareProperty multipleShare={true} selectedProperties={this.state.selectedProperties} class={'mr-0 ml-2 buttonTextWeight'} isDisable={isAtleastOneChecked && isAtleastOneChecked >= 0 ? false : true} />
                                        : null
                                }
                                {
                                    <span className='px-0 text-right d-sm-block d-md-block d-lg-none'>
                                        <Button
                                            className='mr-0 ml-2 buttonTextWeight custom-button'
                                            color='primary'
                                            onClick={() => { this.setState({ displayForm: true }) }}
                                        >
                                            <TuneIcon className='mt-0 large-icon custom-button-icon' />
                                        </Button>
                                    </span>
                                }
                            </div>
                            : window.location.pathname && window.location.pathname === '/matched_properties' || window.location.pathname === '/show_matched_properties'?
                                <div className='row mx-0 mx-md-1'>
                                    <div className="col-2 text-left px-0">
                                        <Link
                                            to={
                                                this.props.location &&
                                                    this.props.location.state && this.props.location.state.fromScreen ?
                                                    this.props.location.state.fromScreen
                                                    : 'savedSearchesList'
                                            }
                                        >
                                            <BackIcon
                                                className='backIcon cursorPointer'
                                                onClick={() => this.redirectToPrevScreenFromMatchedProps()}
                                            />
                                        </Link>
                                    </div>
                                    <div className='col-10 text-right px-0 d-flex justify-content-end'>
                                        {
                                            this.state.myProperties && this.state.myProperties.length > 0 ?

                                                <Button
                                                    className='mr-0 ml-2 buttonTextWeight'
                                                    color='primary'
                                                    onClick={() => this.handleGlobalCheckBox(this.state.myProperties)}
                                                >
                                                    {this.state.isGlobalChecked ?
                                                        tl['clearAll'] ? tl['clearAll'] : 'Clear All'
                                                        : tl['selectAll'] ? tl['selectAll'] : 'Select All'
                                                    }
                                                </Button>
                                                : null
                                        }
                                        {this.state.clientCriteria && !(this.props.location.state && this.props.location.state.hideFilters) ?
                                            <Button color='primary' className='criteriaUpdateCss mr-0 ml-2 buttonTextWeight'
                                                disabled={this.state.savedCriteriaId ? true : false}
                                                onClick={this.state.clientCriteria ? () => this.updateClientCriteria() : null}
                                            >
                                                <span className='d-flex align-items-center'>
                                                    <ClipboardEditIcon className='shareIconAlignment ml-auto mb-1 mr-0 ml-0' />
                                                    <span className='ml-1 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'>
                                                        {tl['update'] ? tl['update'] : 'Update'}
                                                    </span>
                                                </span>
                                            </Button>
                                            : null
                                        }
                                        {
                                            this.state.myProperties && this.state.myProperties.length > 0 ?
                                                <ShareProperty multipleShare={true} selectedProperties={this.state.selectedProperties} class={'mr-0 ml-2 buttonTextWeight'} isDisable={isAtleastOneChecked && isAtleastOneChecked >= 0 ? false : true} />

                                                : null
                                        }
                                        { !(this.props.location.state && this.props.location.state.hideFilters) &&
                                            <span className='px-0 text-right d-sm-block d-md-block d-lg-none'>
                                                <Button
                                                    className='criteriaUpdateCss mr-0 ml-2 buttonTextWeight custom-button'
                                                    color='primary'
                                                    onClick={() => { this.setState({ displayForm: true }) }}
                                                >
                                                    <TuneIcon className='mt-0 large-icon custom-button-icon' />
                                                </Button>
                                            </span>
                                        }
                                    </div>
                                </div>
                                : <div className='d-flex justify-content-end'>
                                    {
                                        this.state.myProperties && this.state.myProperties.length > 0 ?
                                            <ShareProperty multipleShare={true} selectedProperties={this.state.selectedProperties} isDisable={isAtleastOneChecked && isAtleastOneChecked >= 0 ? false : true}/>
                                            : null
                                    }
                                    {/* {
                                        this.state.myProperties && this.state.myProperties.length > 0 ?
                                            <Button
                                                color='primary'
                                                type='button'
                                                disabled={isAtleastOneChecked && isAtleastOneChecked >= 0 ? false : true}
                                                onClick={isAtleastOneChecked && isAtleastOneChecked >= 0 ? () => this.deleteConfirmAction() : null}
                                                className='mr-0 ml-2 buttonTextWeight mb-1'
                                            >
                                                {tl['delete'] ? tl['delete'] : 'Delete'}
                                            </Button>
                                            : null
                                    } */}
                                    <Link
                                        to={{
                                            pathname: `/add_property`,
                                            state: {
                                                fromScreen: window.location.pathname,
                                                fromCriteria: this.state.clientCriteria,
                                                fromData: this.state.myProperties,
                                                ClientIdForMatcheProps: this.state.ClientIdForMatcheProps,
                                                currentFilterCriteria: this.state.currentFilterCriteria,
                                                tl: tl,
                                            }
                                        }}>
                                        <Button className='add_prop_btn mr-0 ml-2 buttonTextWeight mb-1 custom-button'
                                            color='primary'
                                        >
                                            <span className='d-flex align-items-center'>
                                                <PropPlus className='mt-0 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block' />
                                                <FontAwesomeIcon
                                                    icon='plus'
                                                    size='2xl'
                                                    data-toggle="tool-tip" title={t("Add")}
                                                    className='d-sm-block d-md-block d-lg-none custom-button-icon'
                                                />
                                                <span className='d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'>{tl['addProperty'] ? tl['addProperty'] : 'Add Property'}</span>
                                            </span>
                                        </Button>
                                    </Link>
                                    { 
                                        <span className='px-0 text-right ml-2 d-sm-block d-md-block d-lg-none'>
                                            <Button
                                                className='mr-0 mb-1 buttonTextWeight custom-button'
                                                color='primary'
                                                onClick={() => { this.setState({ displayForm: true }) }}
                                            >
                                                <TuneIcon className='mt-0 large-icon custom-button-icon' />
                                            </Button>
                                        </span>
                                    }
                                </div>
                        }
                    </div>
                    {/* Page header buttons end here */}
                </Row>
                <Row className='justify-content-between mt-3 ml-2 mr-2 mb-0'>
                    {/* Filter Container show hide buttons based on screen width start here */}
                    {/* <Col xs={12} className='showInMobile px-0 text-right .d-none .d-sm-block .d-md-none'>
                        {(window.innerWidth < 575) ?
                            <Button
                                className='mr-0 mb-1 buttonTextWeight'
                                color='primary'
                                onClick={() => { this.setState({ displayForm: true }) }}
                            >
                                {tl['showFilters'] ? tl['showFilters'] : 'Show Filters'}
                            </Button>
                            : null
                        }
                    </Col> */}
                    {/* Filter Container show hide buttons based on screen width end here */}

                    {/* Apply filters container starts here */}
                    { !(this.props.location.state && this.props.location.state.hideFilters) &&
                        // (window.innerWidth > 575) ?
                        <Col sm={5} md={3} className='filtersContainer shadowCss mb-2 mt-1 px-2 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'>
                            <Row>
                                <ScrollBar>
                                    <div className="row mx-0">
                                        <div className="col">
                                            <form className="form myPropertiesSearchScroll" onSubmit={handleSubmit(this.applyFilter)}>
                                                {window.location.pathname === '/my_properties' &&
                                                    <div className="form__form-group">
                                                        <span className="textUpperCase">
                                                            {tl['status'] ? tl['status'] : 'Status'}
                                                        </span>
                                                        <div className="form__form-group-field row mx-0">
                                                            <Field
                                                                className='col-12 col-md-4 pl-0'
                                                                name={'propertyStatus'}
                                                                component={RadioButton}
                                                                label={tl && tl['New'] ? tl['New'] : 'New'}
                                                                radioValue={'New'}
                                                                defaultChecked={'New'}
                                                            />
                                                            <Field
                                                                className='col-12 col-md-4 pl-0'
                                                                name={'propertyStatus'}
                                                                component={RadioButton}
                                                                label={tl['soldOut'] ? tl['soldOut'] : 'Sold Out'}
                                                                radioValue={'Sold Out'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                className='col-12 col-md-4 pl-0'
                                                                name={'propertyStatus'}
                                                                component={RadioButton}
                                                                label={tl['expired'] ? tl['expired'] : 'Expired'}
                                                                radioValue={'expired'}
                                                                defaultChecked={false}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['propertyFor'] ? tl['propertyFor'] : 'Property For'}
                                                    </span>
                                                    <div className="form__form-group-field row mx-0">
                                                        <Field
                                                            className='col-12 col-md-6 pl-0'
                                                            name={'propertyFor'}
                                                            component={RadioButton}
                                                            label={tl && tl['sale'] ? tl['sale'] : 'Sale'}
                                                            radioValue={'Sale'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyFor', e)}
                                                        />
                                                        <Field
                                                            className='col-12 col-md-6 pl-0'
                                                            name={'propertyFor'}
                                                            component={RadioButton}
                                                            label={tl['rent'] ? tl['rent'] : 'Rent'}
                                                            radioValue={'Rent'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyFor', e)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['propertyType'] ? tl['propertyType'] : 'Property Type'}
                                                    </span>
                                                    <div className="form__form-group-field row mx-0">
                                                        <Field
                                                            className='col-12 col-lg-6 pl-0'
                                                            name={'propertyType'}
                                                            component={RadioButton}
                                                            label={tl['1rk'] ? tl['1rk'] : '1RK'}
                                                            radioValue={'1RK'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyType', e)}
                                                        />
                                                        <Field
                                                            className='col-12 col-lg-6 pl-0'
                                                            name={'propertyType'}
                                                            component={RadioButton}
                                                            label={tl['flat'] ? tl['flat'] : 'Flat'}
                                                            radioValue={'Flat'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyType', e)}
                                                        />
                                                        <Field
                                                            className='col-12 col-lg-6 pl-0'
                                                            name={'propertyType'}
                                                            component={RadioButton}
                                                            label={tl['duplex'] ? tl['duplex'] : 'Duplex'}
                                                            radioValue={'Duplex'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyType', e)}
                                                        />
                                                        <Field
                                                            className='col-12 col-lg-6 pl-0'
                                                            name={'propertyType'}
                                                            component={RadioButton}
                                                            label={tl['bungalow'] ? tl['bungalow'] : 'Bungalow'}
                                                            radioValue={'Bungalow'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyType', e)}
                                                        />
                                                        <Field
                                                            className='col-12 col-lg-6 pl-0'
                                                            name={'propertyType'}
                                                            component={RadioButton}
                                                            label={tl['commercial'] ? tl['commercial'] : 'Commercial'}
                                                            radioValue={'Commercial'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyType', e)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['location'] ? tl['location'] : 'Location'}
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <div className='col pl-0'>
                                                            <Field
                                                                name='location'
                                                                component={DefaultInput}
                                                                placeholder={tl['location'] ? tl['location'] : 'Location'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {config.currencySymbol + ' '}
                                                        {tl['budget'] ? tl['budget'] : 'Budget'}
                                                    </span>
                                                    <div className="form__form-group-field row">
                                                        <div className="col-12 col-lg-6 pl-0">
                                                            <Field
                                                                onRef={(ref) => (this.totalPriceMinRef = ref)}
                                                                name={'totalPriceMin'}
                                                                id={'totalPriceMin'}
                                                                component={Select}
                                                                options={this.state.totalPriceMinOPtions}
                                                                defaultValue={null}
                                                                placeholder={tl['min'] ? tl['min'] : 'Min'}
                                                                maxHeight='200px'
                                                                onChange={(val) => this.budgetChange('totalPriceMin', val)}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6 pl-0">
                                                            <Field
                                                                onRef={(ref) => (this.totalPriceMaxRef = ref)}
                                                                name={'totalPriceMax'}
                                                                id={'totalPriceMax'}
                                                                component={Select}
                                                                options={this.state.totalPriceMaxOPtions}
                                                                defaultValue={null}
                                                                maxHeight='200px'
                                                                placeholder={tl['max'] ? tl['max'] : 'Max'}
                                                                onChange={(val) => this.budgetChange('totalPriceMax', val)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['area'] ? tl['area'] : 'Area'}{' (' + config.areaUnits + ')'}
                                                    </span>
                                                    <div className="form__form-group-field row">
                                                        <div className="col-12 col-lg-6 pl-0">
                                                            <Field
                                                                name='builtUpAreaMin'
                                                                component={DefaultInput}
                                                                placeholder={tl['min'] ? tl['min'] : 'Min'}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6 pl-0">
                                                            <Field
                                                                name='builtUpAreaMax'
                                                                component={DefaultInput}
                                                                placeholder={tl['max'] ? tl['max'] : 'Max'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['facing'] ? tl['facing'] : 'facing'}
                                                    </span>
                                                    <div className="form__form-group-field mb-1">
                                                        <div className="col pl-0">
                                                            <Field
                                                                onRef={(ref) => (this.searchFacingSelectRef = ref)}
                                                                name={'facing'}
                                                                id={'facing'}
                                                                component={Select}
                                                                options={[
                                                                    { label: tl['north'] ? tl['north'] : 'North', value: 'North' },
                                                                    { label: tl['east'] ? tl['east'] : 'East', value: 'East' },
                                                                    { label: tl['west'] ? tl['west'] : 'West', value: 'West' },
                                                                    { label: tl['south'] ? tl['south'] : 'South', value: 'South' },
                                                                    { label: tl['northEast'] ? tl['northEast'] : 'North East', value: 'North East' },
                                                                    { label: tl['northWest'] ? tl['northWest'] : 'North West', value: 'North West' },
                                                                    { label: tl['southEast'] ? tl['southEast'] : 'South East', value: 'South East' },
                                                                    { label: tl['southWest'] ? tl['southWest'] : 'South West', value: 'South West' },
                                                                ]}
                                                                defaultValue={null}
                                                                placeholder={tl['select'] ? tl['select'] : 'Select'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Hide Bedrooms If selected property type is 1RK or Commercial */}
                                                {this.state.propertyType && (this.state.propertyType === '1RK' || this.state.propertyType === 'Commercial') ?
                                                    null :
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label textUpperCase">
                                                            {tl['bedRooms'] ? tl['bedRooms'] : 'Bedrooms'}
                                                        </span>
                                                        <div className="form__form-group-field row mx-0">
                                                            <Field
                                                                className='col-12 col-md-4 pl-0'
                                                                name={'bedRooms'}
                                                                component={RadioButton}
                                                                label={'1'}
                                                                radioValue={'1'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                className='col-12 col-md-4 pl-0'
                                                                name={'bedRooms'}
                                                                component={RadioButton}
                                                                label={'2'}
                                                                radioValue={'2'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                className='col-12 col-md-4 pl-0'
                                                                name={'bedRooms'}
                                                                component={RadioButton}
                                                                label={'3'}
                                                                radioValue={'3'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                className='col-12 col-md-4 pl-0'
                                                                name={'bedRooms'}
                                                                component={RadioButton}
                                                                label={'4'}
                                                                radioValue={'4'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                className='col-12 col-md-4 pl-0'
                                                                name={'bedRooms'}
                                                                component={RadioButton}
                                                                label={'5'}
                                                                radioValue={'5'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                className='col-12 col-md-4 pl-0'
                                                                name={'bedRooms'}
                                                                component={RadioButton}
                                                                label={'6'}
                                                                radioValue={'6'}
                                                                defaultChecked={false}
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['availability'] ? tl['availability'] : 'Availability'}
                                                    </span>
                                                    <div className="form__form-group-field row mx-0">
                                                        <Field
                                                            className='col-12 col-md-6 pl-0'
                                                            name={'availability'}
                                                            component={RadioButton}
                                                            label={tl['underConstruction'] ? tl['underConstruction'] : 'Under Construction'}
                                                            radioValue={'Under Construction'}
                                                            defaultChecked={false}
                                                        />
                                                        <Field
                                                            className='col-12 col-md-6 pl-0'
                                                            name={'availability'}
                                                            component={RadioButton}
                                                            label={tl['readyToMove'] ? tl['readyToMove'] : 'Ready to Move'}
                                                            radioValue={'Ready to Move'}
                                                            defaultChecked={false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['furnishingType'] ? tl['furnishingType'] : 'Furnishing Type'}
                                                    </span>
                                                    <div className="form__form-group-field row mx-0">
                                                        <Field
                                                            className='col-12 col-md-6 pl-0'
                                                            name={'furnishingType'}
                                                            component={RadioButton}
                                                            label={tl['unFurnished'] ? tl['unFurnished'] : 'Un-Furnished'}
                                                            radioValue={'Un-Furnished'}
                                                            defaultChecked={false}
                                                        />
                                                        <Field
                                                            className='col-12 col-md-6 pl-0'
                                                            name={'furnishingType'}
                                                            component={RadioButton}
                                                            label={tl['semiFurnished'] ? tl['semiFurnished'] : 'Semi-Furnished'}
                                                            radioValue={'Semi-Furnished'}
                                                            defaultChecked={false}
                                                        />
                                                        <Field
                                                            className='col-12 col-md-12 pl-0'
                                                            name={'furnishingType'}
                                                            component={RadioButton}
                                                            label={tl['furnished'] ? tl['furnished'] : 'Furnished'}
                                                            radioValue={'Furnished'}
                                                            defaultChecked={false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__form-group">
                                                    <div className="form__form-group-field">
                                                        <div className="col pl-0">
                                                            <Button
                                                                type='submit'
                                                                className="col buttonTextWeight"
                                                                color="primary"
                                                            >
                                                                {tl['applyFilters'] ? tl['applyFilters'] : 'Apply Filters'}
                                                            </Button>
                                                            <Button
                                                                type='button'
                                                                className="col buttonTextWeight"
                                                                color="secondary"
                                                                onClick={() => this.resetFilters(reset)}
                                                            >
                                                                {tl['reset'] ? tl['reset'] : 'Reset'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </ScrollBar>
                            </Row>
                        </Col>
                        // : null
                    }{/* Apply filters container starts here */}


                    {<Modal isOpen={this.state.displayForm}
                        className={`form_modal modal-dialog-centered modal-dialog--primary  ${modalClass} formModalBorderRadius`}>
                        <ModalHeader className="modal__header">
                            <button className="lnr lnr-cross modal__close-btn" type="button"
                                onClick={(e) => { this.setState({ displayForm: false }); }} />
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <div className="row mx-0">
                                    <div className="col">
                                        <div className="form-container">
                                            <form className="form myPropertiesSearchScroll" onSubmit={handleSubmit(this.applyFilter)} style={{ justifyContent: 'end' }}>
                                                <div className="form-header">
                                                    <div className="button-container">
                                                        <Button
                                                            type='button'
                                                            className="mb-0"
                                                            color="secondary"
                                                            onClick={() => this.resetFilters(reset)}
                                                        >
                                                            {tl['reset'] ? tl['reset'] : 'Reset'}
                                                        </Button>
                                                        <Button
                                                            type='submit'
                                                            className="mb-0"
                                                            color="primary"
                                                            onClick={(e) => this.setState({ displayForm: false })}
                                                        >
                                                            {tl['applyFilters'] ? tl['applyFilters'] : 'Apply Filters'}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="form-content">
                                                    {window.location.pathname === '/my_properties' &&
                                                        <div className="form__form-group">
                                                            <span className="textUpperCase">
                                                                {tl['status'] ? tl['status'] : 'Status'}
                                                            </span>
                                                            <div className="form__form-group-field row mx-0">
                                                                <Field
                                                                    name={'propertyStatus'}
                                                                    component={RadioButton}
                                                                    label={tl && tl['New'] ? tl['New'] : 'New'}
                                                                    radioValue={'New'}
                                                                    defaultChecked={'New'}
                                                                />
                                                                <Field
                                                                    name={'propertyStatus'}
                                                                    component={RadioButton}
                                                                    label={tl['soldOut'] ? tl['soldOut'] : 'Sold Out'}
                                                                    radioValue={'Sold Out'}
                                                                    defaultChecked={false}
                                                                />
                                                                <Field
                                                                    name={'propertyStatus'}
                                                                    component={RadioButton}
                                                                    label={tl['expired'] ? tl['expired'] : 'Expired'}
                                                                    radioValue={'expired'}
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="form__form-group">
                                                        <span className="textUpperCase">
                                                            {tl['propertyFor'] ? tl['propertyFor'] : 'Property For'}
                                                        </span>
                                                        <div className="form__form-group-field row mx-0">
                                                            <Field
                                                                name={'propertyFor'}
                                                                component={RadioButton}
                                                                label={tl && tl['sale'] ? tl['sale'] : 'Sale'}
                                                                radioValue={'Sale'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                name={'propertyFor'}
                                                                component={RadioButton}
                                                                label={tl['rent'] ? tl['rent'] : 'Rent'}
                                                                radioValue={'Rent'}
                                                                defaultChecked={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label textUpperCase">
                                                            {tl['propertyType'] ? tl['propertyType'] : 'Property Type'}
                                                        </span>
                                                        <div className="form__form-group-field row mx-0">
                                                            <Field
                                                                name={'propertyType'}
                                                                component={RadioButton}
                                                                label={tl['1rk'] ? tl['1rk'] : '1RK'}
                                                                radioValue={'1RK'}
                                                                defaultChecked={false}
                                                                onChange={(e) => this.handleChange('propertyType', e)}
                                                            />
                                                            <Field
                                                                name={'propertyType'}
                                                                component={RadioButton}
                                                                label={tl['flat'] ? tl['flat'] : 'Flat'}
                                                                radioValue={'Flat'}
                                                                defaultChecked={false}
                                                                onChange={(e) => this.handleChange('propertyType', e)}
                                                            />
                                                            <Field
                                                                name={'propertyType'}
                                                                component={RadioButton}
                                                                label={tl['duplex'] ? tl['duplex'] : 'Duplex'}
                                                                radioValue={'Duplex'}
                                                                defaultChecked={false}
                                                                onChange={(e) => this.handleChange('propertyType', e)}
                                                            />
                                                            <Field
                                                                name={'propertyType'}
                                                                component={RadioButton}
                                                                label={tl['bungalow'] ? tl['bungalow'] : 'Bungalow'}
                                                                radioValue={'Bungalow'}
                                                                defaultChecked={false}
                                                                onChange={(e) => this.handleChange('propertyType', e)}
                                                            />
                                                            <Field
                                                                name={'propertyType'}
                                                                component={RadioButton}
                                                                label={tl['commercial'] ? tl['commercial'] : 'Commercial'}
                                                                radioValue={'Commercial'}
                                                                defaultChecked={false}
                                                                onChange={(e) => this.handleChange('propertyType', e)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label textUpperCase">
                                                            {tl['location'] ? tl['location'] : 'Location'}
                                                        </span>
                                                        <div className="form__form-group-field">
                                                            <div className='col pl-0'>
                                                                <Field
                                                                    name='location'
                                                                    component={DefaultInput}
                                                                    placeholder={tl['location'] ? tl['location'] : 'Location'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label textUpperCase">
                                                            {config.currencySymbol + ' '}
                                                            {tl['budget'] ? tl['budget'] : 'Budget'}
                                                        </span>
                                                        <div className="form__form-group-field row">
                                                            <div className="col-6 col-lg-6 pl-0">
                                                                <Field
                                                                    onRef={(ref) => (this.totalPriceMinRef = ref)}
                                                                    name={'totalPriceMin'}
                                                                    id={'totalPriceMin'}
                                                                    component={Select}
                                                                    options={this.state.totalPriceMinOPtions}
                                                                    defaultValue={null}
                                                                    placeholder={tl['min'] ? tl['min'] : 'Min'}
                                                                    maxHeight='200px'
                                                                    onChange={(val) => this.budgetChange('totalPriceMin', val)}
                                                                />
                                                            </div>
                                                            <div className="col-6 col-lg-6 pl-0">
                                                                <Field
                                                                    onRef={(ref) => (this.totalPriceMaxRef = ref)}
                                                                    name={'totalPriceMax'}
                                                                    id={'totalPriceMax'}
                                                                    component={Select}
                                                                    options={this.state.totalPriceMaxOPtions}
                                                                    defaultValue={null}
                                                                    maxHeight='200px'
                                                                    placeholder={tl['max'] ? tl['max'] : 'Max'}
                                                                    onChange={(val) => this.budgetChange('totalPriceMax', val)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label textUpperCase">
                                                            {tl['area'] ? tl['area'] : 'Area'}{' (' + config.areaUnits + ')'}
                                                        </span>
                                                        <div className="form__form-group-field row">
                                                            <div className="col-6 col-lg-6 pl-0">
                                                                <Field
                                                                    name='builtUpAreaMin'
                                                                    component={DefaultInput}
                                                                    placeholder={tl['min'] ? tl['min'] : 'Min'}
                                                                />
                                                            </div>
                                                            <div className="col-6 col-lg-6 pl-0">
                                                                <Field
                                                                    name='builtUpAreaMax'
                                                                    component={DefaultInput}
                                                                    placeholder={tl['max'] ? tl['max'] : 'Max'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label textUpperCase">
                                                            {tl['facing'] ? tl['facing'] : 'facing'}
                                                        </span>
                                                        <div className="form__form-group-field mb-1">
                                                            <div className="col pl-0">
                                                                <Field
                                                                    onRef={(ref) => (this.searchFacingSelectRef = ref)}
                                                                    name={'facing'}
                                                                    id={'facing'}
                                                                    component={Select}
                                                                    options={[
                                                                        { label: tl['north'] ? tl['north'] : 'North', value: 'North' },
                                                                        { label: tl['east'] ? tl['east'] : 'East', value: 'East' },
                                                                        { label: tl['west'] ? tl['west'] : 'West', value: 'West' },
                                                                        { label: tl['south'] ? tl['south'] : 'South', value: 'South' },
                                                                        { label: tl['northEast'] ? tl['northEast'] : 'North East', value: 'North East' },
                                                                        { label: tl['northWest'] ? tl['northWest'] : 'North West', value: 'North West' },
                                                                        { label: tl['southEast'] ? tl['southEast'] : 'South East', value: 'South East' },
                                                                        { label: tl['southWest'] ? tl['southWest'] : 'South West', value: 'South West' },
                                                                    ]}
                                                                    defaultValue={null}
                                                                    placeholder={tl['select'] ? tl['select'] : 'Select'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {this.state.propertyType && (this.state.propertyType === '1RK' || this.state.propertyType === 'Commercial') ?
                                                        null :
                                                        <div className="form__form-group">
                                                            <span className="form__form-group-label textUpperCase">
                                                                {tl['bedRooms'] ? tl['bedRooms'] : 'Bedrooms'}
                                                            </span>
                                                            <div className="form__form-group-field row mx-0">
                                                                <Field
                                                                    name={'bedRooms'}
                                                                    component={RadioButton}
                                                                    label={'1'}
                                                                    radioValue={'1'}
                                                                    defaultChecked={false}
                                                                />
                                                                <Field
                                                                    name={'bedRooms'}
                                                                    component={RadioButton}
                                                                    label={'2'}
                                                                    radioValue={'2'}
                                                                    defaultChecked={false}
                                                                />
                                                                <Field
                                                                    name={'bedRooms'}
                                                                    component={RadioButton}
                                                                    label={'3'}
                                                                    radioValue={'3'}
                                                                    defaultChecked={false}
                                                                />
                                                                <Field
                                                                    name={'bedRooms'}
                                                                    component={RadioButton}
                                                                    label={'4'}
                                                                    radioValue={'4'}
                                                                    defaultChecked={false}
                                                                />
                                                                <Field
                                                                    name={'bedRooms'}
                                                                    component={RadioButton}
                                                                    label={'5'}
                                                                    radioValue={'5'}
                                                                    defaultChecked={false}
                                                                />
                                                                <Field
                                                                    name={'bedRooms'}
                                                                    component={RadioButton}
                                                                    label={'6'}
                                                                    radioValue={'6'}
                                                                    defaultChecked={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label textUpperCase">
                                                            {tl['availability'] ? tl['availability'] : 'Availability'}
                                                        </span>
                                                        <div className="form__form-group-field row mx-0">
                                                            <Field
                                                                name={'availability'}
                                                                component={RadioButton}
                                                                label={tl['underConstruction'] ? tl['underConstruction'] : 'Under Construction'}
                                                                radioValue={'Under Construction'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                name={'availability'}
                                                                component={RadioButton}
                                                                label={tl['readyToMove'] ? tl['readyToMove'] : 'Ready to Move'}
                                                                radioValue={'Ready to Move'}
                                                                defaultChecked={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label textUpperCase">
                                                            {tl['furnishingType'] ? tl['furnishingType'] : 'Furnishing Type'}
                                                        </span>
                                                        <div className="form__form-group-field row mx-0">
                                                            <Field
                                                                name={'furnishingType'}
                                                                component={RadioButton}
                                                                label={tl['unFurnished'] ? tl['unFurnished'] : 'Un-Furnished'}
                                                                radioValue={'Un-Furnished'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                name={'furnishingType'}
                                                                component={RadioButton}
                                                                label={tl['semiFurnished'] ? tl['semiFurnished'] : 'Semi-Furnished'}
                                                                radioValue={'Semi-Furnished'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                name={'furnishingType'}
                                                                component={RadioButton}
                                                                label={tl['furnished'] ? tl['furnished'] : 'Furnished'}
                                                                radioValue={'Furnished'}
                                                                defaultChecked={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </ModalBody>
                    </Modal>
                    }


                    {/* Property cards container starts here */}
                    <Col sm={7} md={9} className='propertiesContainer px-2 m-auto'>
                        <Row className='ml-sm-1'>
                            <ScrollBar className='myPropertiesListScroll'>
                                <div className={`row mx-0 mt-1 ${!this.state.myProperties.length > 0 ? 'justify-content-center' : ''}`}>
                                    {this.state.myProperties && this.state.myProperties.length > 0 ?
                                        this.state.myProperties.map((item, index) => {
                                            return (
                                                <div
                                                    className="col-md-6 col-lg-4 pb-2 pr-1 pl-2"
                                                    key={index}
                                                >
                                                    <Card className='propertyCardCss'>
                                                        <CardHeader className='p-0'>
                                                            <CheckBox
                                                                className='row propertyCheckBox'
                                                                key={item._id}
                                                                name={item._id}
                                                                id={item._id}
                                                                checked={item.isChecked}
                                                                onChange={() => this.handleCheckBox(item)}
                                                                onClick={(e) => console.log('singleCheck', e)}
                                                            />
                                                            {item.propertyFor === 'Rent' ?
                                                                <label className='propertyPriceOverlay rent'>
                                                                    <div>{`${config.currencySymbol} ${item.rent}`}</div>
                                                                    <div>{item.deposit ?
                                                                        `${config.currencySymbol} ${item.deposit1} ${tl['deposit'] ? tl['deposit'] : 'Deposit'}`
                                                                        : null}
                                                                    </div>
                                                                </label>
                                                                :
                                                                <label className='propertyPriceOverlay sale'>
                                                                    {`${config.currencySymbol} ${item.totalPrice1 ? item.totalPrice1 : item.totalPrice}`}
                                                                </label>
                                                            }
                                                            <div className="cornerRibbon">
                                                                {item.propertyFor}
                                                            </div>
                                                            {item.propertyStatus && item.propertyStatus === 'Sold Out' ?
                                                                <div className="mark_images">
                                                                    {'SOLD OUT'}
                                                                </div>
                                                                : null
                                                            }
                                                            {item.photo && item.photo.length > 0 && item.photo[0] && item.photo[0].name ?
                                                                <div
                                                                    className="img__wrap cursorPointer"
                                                                    onClick={() => this.viewPhotosModal(item.photo)}
                                                                >
                                                                    <img
                                                                        className="propertyCardImage"
                                                                        alt=''
                                                                        src={`${config.imgUrl}property/${item.photo[0].name}`}
                                                                    />
                                                                    <p className="img__description">
                                                                        <AspectRatio />
                                                                    </p>
                                                                </div>
                                                                :
                                                                <img
                                                                    alt=''
                                                                    src={configImages.noPropImage}
                                                                    className='propertyCardImage'
                                                                />
                                                            }
                                                        </CardHeader>
                                                        <CardBody className='propertyCardBodyCss cursorPointer pt-2'>
                                                            <Link
                                                                onClick={e => this.handlePropCardClick(e)}
                                                                ref={(r) => this.propDetailsLink = r}
                                                                className='linkOverCardCss'
                                                                to={{
                                                                    pathname: `/propertyDetails/${item._id}`,
                                                                    state: {
                                                                        fromScreen: window.location.pathname,
                                                                        fromCriteria: this.state.clientCriteria,
                                                                        fromData: this.state.myProperties,
                                                                        ClientIdForMatcheProps: this.state.ClientIdForMatcheProps,
                                                                        currentFilterCriteria: this.state.currentFilterCriteria,
                                                                        criteriaForMatchedProps: this.state.matchedCriteria,
                                                                        tl: tl,
                                                                        state: this.state.matchedCriteria,
                                                                        matchedFromScreen: this.state.matchedFromScreen,
                                                                    }
                                                                }}
                                                            >
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        {
                                                                            // item.createdBy && item.createdBy.user && loginUser && (item.createdBy.user === loginUser._id) ?
                                                                            <div className="row mx-0">
                                                                                <div className="col-10 px-0  align-self-start">
                                                                                    <span className='propertyCardHeading'>
                                                                                        {item.title ? item.title : ''}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-2 px-0 align-self-start ml-auto">
                                                                                    {window.location.pathname && window.location.pathname === '/my_properties' ?
                                                                                        <div className="svg-icons d-flex justify-content-end">
                                                                                            <Link
                                                                                                to={{
                                                                                                    pathname: `/properties/${item._id}`,
                                                                                                    state: {
                                                                                                        fromScreen: window.location.pathname,
                                                                                                        fromCriteria: this.state.clientCriteria,
                                                                                                        fromData: this.state.myProperties,
                                                                                                        ClientIdForMatcheProps: this.state.ClientIdForMatcheProps,
                                                                                                        currentFilterCriteria: this.state.currentFilterCriteria,
                                                                                                        editData: item,
                                                                                                    },
                                                                                                }}>
                                                                                                <FontAwesomeIcon
                                                                                                    className='myPropEditIcon'
                                                                                                    color='white'
                                                                                                    icon='edit'
                                                                                                    data-toggle="tool-tip"
                                                                                                    title="Edit"
                                                                                                    style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                                                                                />
                                                                                            </Link>
                                                                                           {window.location.pathname === '/my_properties' && <Link className='ml-2'
                                                                                                to={{
                                                                                                    pathname: `/properties/${item._id}`,
                                                                                                    state: {
                                                                                                        fromScreen: window.location.pathname,
                                                                                                        fromCriteria: this.state.clientCriteria,
                                                                                                        fromData: this.state.myProperties,
                                                                                                        ClientIdForMatcheProps: this.state.ClientIdForMatcheProps,
                                                                                                        currentFilterCriteria: this.state.currentFilterCriteria,
                                                                                                        editData: item,
                                                                                                        actionType : 'Clone'
                                                                                                    },
                                                                                                }}>
                                                                                                <ContentCopyIcon size={16} color="#024a88" />
                                                                                            </Link>}
                                                                                            {/* <FontAwesomeIcon className='myPropDeleteIcon'
                                                                                                color='white'
                                                                                                icon='trash-alt'
                                                                                                data-toggle="tool-tip"
                                                                                                title="Delete"
                                                                                                style={{ color: '#bf1725', width: '13', marginRight: '0px' }}
                                                                                                onClick={(event) => this.deleteConfirmAction(event, item._id)}
                                                                                            /> */}
                                                                                        </div>
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            // :
                                                                            // <span className='propertyCardHeading'>
                                                                            //     {/* {item.title ? item.title : ''} */}
                                                                            // </span>
                                                                        }
                                                                        <div className='d-flex propertyCardDetails'>
                                                                            <span className='py-1'>
                                                                                {item.propertyType === 'Commercial' || item.propertyType === '1RK' ?
                                                                                    tl['plotArea'] ? tl['plotArea'] : 'Plot Area'
                                                                                    : tl['builtUpArea'] ? tl['builtUpArea'] : 'Built-Up Area'
                                                                                }
                                                                                {' : '}
                                                                                {item.builtUpArea ?
                                                                                    `${(parseInt(item.builtUpArea)).toLocaleString('en-IN')} ${config.areaUnits}`
                                                                                    : null
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className="row mx-0 align-items-center justify-content-between propertyCardDetails">
                                                                            <span className='secondaryText'>
                                                                                {item.availability}
                                                                            </span>
                                                                            <ShareProperty item={item} />
                                                                            
                                                                        </div>
                                                                        <div className="row mx-0 align-items-center propertyCardDetails">
                                                                            <span>
                                                                                {item.furnishingType}
                                                                            </span>
                                                                            <EyeIcon size={16} className='ml-auto' />
                                                                            <span className='pl-1'>
                                                                                {item.onClickCount}
                                                                            </span>
                                                                        </div>
                                                                        {(window.location.pathname === '/my_properties' && item.expiringSoon) &&
                                                                            <div className='row mx-0 align-items-center propertyCardDetails '>
                                                                                <span className="text-danger">{item.expiringSoon}</span>
                                                                                <span className="button_reNew ml-auto my-auto">
                                                                                    <Button
                                                                                        type='button'
                                                                                        onClick={() => this.openConfirmationModal(item)}  
                                                                                    >
                                                                                        {tl['reNew'] ? tl['reNew'] : 'ReNew'}
                                                                                    </Button>
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Link>
                                                        </CardBody>
                                                        <CardFooter className='py-0 propertyCardFooter propertyCardDetails'>
                                                            <Link
                                                                onClick={e => this.handlePropCardClick(e)}
                                                                ref={(r) => this.propDetailsLink = r}
                                                                className='linkOverCardCss'
                                                                to={{
                                                                    pathname: `/propertyDetails/${item._id}`,
                                                                    state: {
                                                                        fromScreen: window.location.pathname,
                                                                        fromCriteria: this.state.clientCriteria,
                                                                        fromData: this.state.myProperties,
                                                                        ClientIdForMatcheProps: this.state.ClientIdForMatcheProps,
                                                                        currentFilterCriteria: this.state.currentFilterCriteria,
                                                                        criteriaForMatchedProps: this.state.matchedCriteria,
                                                                        tl: tl,
                                                                        state: this.state.matchedCriteria,
                                                                        matchedFromScreen: this.state.matchedFromScreen,
                                                                    }
                                                                }}
                                                            >
                                                                {item.showContactInfo ?
                                                                    <div onClick={() => this.handleContactInfo(item)} className='secondaryText'>
                                                                        {item.displayName ?
                                                                            <div className='px-0 col-12 text-right'>
                                                                                <span className='textCapitalize'>
                                                                                    {item.displayName}
                                                                                    <UserIcon className='contactInfoIcon' />
                                                                                </span>
                                                                            </div>
                                                                            : null
                                                                        }
                                                                        {item.phoneNumber ?
                                                                            <div className='px-0 col-12 text-right'>
                                                                                <span>
                                                                                    {item.phoneNumber}
                                                                                    <CallIcon className='contactInfoIcon' />
                                                                                </span>
                                                                            </div>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                    : null
                                                                }
                                                                <div className='d-flex'>
                                                                    <div className='py-1'>
                                                                        <span className='secondaryText'>
                                                                            <CalenderQuetionIcon className='howLongIcon' />
                                                                            {dateFormats.isHowLong(item.created)}
                                                                        </span>
                                                                    </div>
                                                                    {window.location.pathname && window.location.pathname !== '/my_properties' ?
                                                                        <div className='ml-auto my-auto' color='primary'>
                                                                            <span
                                                                                className={item.showContactInfo ? 'contactInfoToggle2' : 'contactInfoToggle1'}
                                                                                onClick={() => this.handleContactInfo(item)}
                                                                            >
                                                                                {
                                                                                    item.showContactInfo ?
                                                                                        tl['hideAgentInfo'] ? tl['hideAgentInfo'] : 'Hide Agent Info'
                                                                                        : tl['showAgentInfo'] ? tl['showAgentInfo'] : 'Show Agent Info'
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        : null
                                                                    }
                                                                    {(window.location.pathname === '/my_properties' && item.propertyStatus === 'expired') &&
                                                                        <div className="button_reNew ml-auto my-auto">
                                                                            <Button
                                                                                type='button'
                                                                                onClick={() => this.openConfirmationModal(item)}                                                                                
                                                                            >
                                                                                {tl['reNew'] ? tl['reNew'] : 'ReNew'}
                                                                            </Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className='ml-auto my-auto' color='primary'>
                                                                </div>
                                                            </Link>
                                                        </CardFooter>

                                                    </Card>
                                                </div>
                                            )
                                        })
                                        : window.location.pathname && window.location.pathname === '/search_properties' ?
                                            <div className='row mx-0 d-flex justify-content-center emptyPropsContainer'>
                                                <div className='col my-auto'>
                                                    <div>
                                                        <div className='text-center'>
                                                            <h4>
                                                                {this.state.clientCriteria ?
                                                                    tl['noResultsFound'] ? tl['noResultsFound'] : 'No Results Found'
                                                                    : tl['pleaseSelectYourReq'] ? tl['pleaseSelectYourReq'] : 'Please Select Your Requirements'
                                                                }
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : window.location.pathname && window.location.pathname === '/matched_properties' ?
                                                <div className='row mx-0 d-flex justify-content-center emptyPropsContainer'>
                                                    <div className='col my-auto'>
                                                        <div>
                                                            <div className='text-center'>
                                                                <h4>
                                                                    {tl['noMatchedProps'] ? tl['noMatchedProps'] : 'No Matched Properties'}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className='row mx-0 d-flex justify-content-center emptyPropsContainer'>
                                                    <div className='col my-auto'>
                                                        <div>
                                                            <div className='text-center'>
                                                                <h4>
                                                                    {this.state.clientCriteria ?
                                                                        tl['noResultsFound'] ? tl['noResultsFound'] : 'No Results Found'
                                                                        : tl['noPropsYet'] ? tl['noPropsYet'] : 'No Properties Yet'
                                                                    }
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </ScrollBar>
                        </Row>
                        <Row className='align-items-end'>
                            <Col>
                                {this.state.myProperties && this.state.myProperties.length > 0 ?
                                    <Paginator
                                        className='paginatorStyles'
                                        totalRecords={this.state.pagination.totalCount}
                                        first={this.state.first}
                                        page={this.state.page}
                                        rows={this.state.rows}
                                        onPageChange={(e) => this.onPageChange(e, this.state.currentFilterCriteria ? this.state.currentFilterCriteria : this.state.filterCriteria)}
                                        style={{ padding: 10, backgroundColor: 'none', border: 'none' }}
                                    >
                                    </Paginator>
                                    : null
                                }
                            </Col>
                        </Row>
                    </Col>
                    {/* Property cards container ends here */}
                </Row>

                {/* Redirect to Previous screen from Matched Properties */}
                {this.state.redirectToPrevScreenFromMatchedProps ?
                    <Redirect
                        to={{
                            pathname: this.state.matchedFromScreen,
                            state: { tl: tl }
                        }}
                    />
                    : null
                }

                {/* Photos Viewer */}
                {this.state.isViewPhotosModalOpen ?
                    <ViewPhotosModal
                        isViewPhotosModalOpen={this.state.isViewPhotosModalOpen}
                        propertyPhotos={this.state.propertyPhotos}
                        closeViewPhotosModal={this.viewPhotosModal}
                        tl={tl}
                    />
                    : null
                }

                {/* Share Limit Crossed Alert */}
                {this.state.isOpenShareLimitModal ?
                    <ShareLimitModal
                        isOpenShareLimitModal={this.state.isOpenShareLimitModal}
                        closeShareLimitModal={() => { this.setState({ isOpenShareLimitModal: false }) }}
                        tl={tl}
                    />
                    : null
                }

                {/* Client Select Modal  */}
                {this.state.isOpenClientSelectModel ?
                    <ClientSelectModel
                        isOpenClientSelectModel={this.state.isOpenClientSelectModel}
                        closeClientSelectModal={this.closeClientSelectModal}
                        clientCriteria={this.state.clientCriteria}
                        properties={this.state.saveSearchProperties}
                        saveSearchFilterObject={this.state.saveSearchFilterObject}
                        tl={tl}
                    />
                    : null
                }

                {/* Share Property/ies Modal */}
                {this.state.isOpenSharePropsModal ?
                    <SharePropsModal
                        isOpenSharePropsModal={this.state.isOpenSharePropsModal}
                        selectedProperties={this.state.selectedProperties}
                        closeSharePropsModal={this.closeSharePropsModal}
                        tl={tl}
                    />
                    : null
                }

                {/* Delete Selected Property modal */}
                {this.state.openConfirmActionModal ?
                    <ConfirmActionModal
                        openConfirmActionModal={this.state.openConfirmActionModal}
                        closeConfirmActionModal={this.closeConfirmActionModal}
                        deleteSelectedRow={this.deleteSelectedRow}
                        favSelectedClients={this.favSelectedClients}
                        actionType={this.state.actionType}
                        actionTitle={this.state.actionTitle}
                        UpdateSelectedProperty={this.UpdateSelectedProperty}
                        tl={tl}
                    />
                    : null
                }

                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </div >
        );
    }
}

CommonPropertiesScreen = reduxForm({
    form: 'searchProps', // a unique identifier for this form
    enableReinitialize: true,
    validate,
    asyncValidate,
    asyncBlurFields: ['totalPriceMax', 'totalPriceMin', 'builtUpAreaMin', 'builtUpAreaMax']
})(CommonPropertiesScreen);

CommonPropertiesScreen = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(CommonPropertiesScreen);

export default withTranslation('common')(CommonPropertiesScreen)
