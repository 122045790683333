import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

import Select from '../../../shared/components/form/Select';

import { Field, reduxForm } from 'redux-form';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

import ClientsFormModal from '../../Clients/components/ClientsFormModal';
import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

import validate from '../../Validations/validate';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

const required = value => (value || typeof value === 'string' ? undefined : 'Please select a Client')

class ClientSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientOptions: [],
            isOpenClientsFormModal: false,
        };
    }

    componentDidMount = async () => {
        await this.getClientOptions();
    }

    // Get Clients List for search field
    getClientOptions = async () => {
        let user = await JSON.parse(localStorage.getItem('loginCredentials'));
        fetchMethodRequest('GET', `clients/getNames?email=${user.email}&type=Buyer`).then(async resp => {
            if (resp && resp.respCode) {
                await this.setState({
                    clientOptions: resp.clients,
                })
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        })
    }

    // Open Add Client Modal
    openAddClientModal = async () => {
        await this.setState({
            isOpenClientsFormModal: true,
        })
    }

    // Close Client modal
    closeClientsFormModal = async () => {
        await this.getClientOptions();
        await this.setState({
            isOpenClientsFormModal: false,
        })
    }

    // Handle save button
    submit = async (values) => {
        let user = await JSON.parse(localStorage.getItem('loginCredentials'));
        let clientId = values.clientSelected;
        let clientCriteria = this.props.clientCriteria;
        let selectedProperties = this.props.properties ? this.props.properties : [];
        let selectedIds = [];


        if (clientCriteria.totalPriceMax) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.totalPriceMax;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.totalPriceMax;
            }

            clientCriteria['totalPriceMaxDigits'] = finalTempNumber
            clientCriteria['totalPriceMax'] = finalTempString
        }

        if (clientCriteria.totalPriceMin) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.totalPriceMin;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.totalPriceMin;
            }

            clientCriteria['totalPriceMinDigits'] = finalTempNumber
            clientCriteria['totalPriceMin'] = finalTempString
        }

        if (clientCriteria.builtUpAreaMax) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.builtUpAreaMax;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.builtUpAreaMax;
            }

            clientCriteria['builtUpAreaMaxDigits'] = finalTempNumber
            clientCriteria['builtUpAreaMax'] = finalTempString
        }

        if (clientCriteria.builtUpAreaMin) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.builtUpAreaMin;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.builtUpAreaMin;
            }

            clientCriteria['builtUpAreaMinDigits'] = finalTempNumber
            clientCriteria['builtUpAreaMin'] = finalTempString
        }

        if (selectedProperties && selectedProperties.length > 0) {
            for (let i = 0; i < selectedProperties.length; i++) {
                selectedIds.push(selectedProperties[i]._id);
            }
        } else {
            selectedIds = [];
        }


        let body = {
            agentEmail: user.email,
            clientId: clientId,
            clientName: this.state.client.label,
            criteria: clientCriteria,
            result: selectedIds,
            search: { filter: this.props.saveSearchFilterObject }
        }
        if (clientId) {
            fetchMethodRequest('POST', 'criteria', body).then(async resp => {
                if (resp && resp.respCode) {
                    showToasterMessage('Properties Added Successfuy', 'success')
                    await this.props.closeClientSelectModal('saved');
                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            })
        } else {
            showToasterMessage('Please select a client', 'error')
        }
    }

    // Handle client selection
    handleSelectedOption = async (option) => {
        await this.setState({
            client: option
        })
    }

    render() {
        const { tl, handleSubmit, t } = this.props;
        const { clientOptions } = this.state;
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpenClientSelectModel}
                    className={`modal-dialog-centered modal-dialog--primary formModalBorderRadius`}
                >
                    <ModalBody className='p-0'>
                        <form onSubmit={handleSubmit(this.submit)}>
                            <h4 className="text-center mb-3">
                                {tl['selectAclientToSave'] ? tl['selectAclientToSave'] : 'Select a Client to save'}
                            </h4>
                            <div className="form__form-group-field row">
                                <div className='col-12 col-sm-8 col-md-9 px-0 mb-3 text-left'>
                                    <Field
                                        name={'clientSelected'}
                                        className='clientSearch'
                                        fieldName={'clientSelected'}
                                        component={Select}
                                        options={clientOptions}
                                        placeholder={'Select'}
                                        maxHeight={120}
                                        type={'clientSelection'}
                                        getSelectedOption={this.handleSelectedOption}
                                        validate={required}
                                    />
                                </div>
                                <div className="col-12 col-sm-4 col-md-3 px-0 pl-0 pl-sm-2 text-right">
                                    <Button
                                        color="primary"
                                        onClick={() => this.openAddClientModal()}
                                        className='buttonTextWeight'
                                    >
                                        {tl['addNew'] ? tl['addNew'] : 'Add New'}
                                    </Button>
                                </div>
                            </div>
                            <div className="col-12 text-left px-0">
                                <Button
                                    color="primary"
                                    type="button"
                                    className='deleteModalBtn marginRight buttonTextWeight'
                                    onClick={() => this.props.closeClientSelectModal()}
                                >
                                    {tl['cancel'] ? tl['cancel'] : 'Cancel'}
                                </Button>
                                <Button
                                    color='primary'
                                    type="submit"
                                    className='deleteModalBtn buttonTextWeight'
                                >
                                    {tl['save'] ? tl['save'] : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>

                {/* Clients form modal */}
                {this.state.isOpenClientsFormModal ?
                    <ClientsFormModal
                        isOpenClientsFormModal={this.state.isOpenClientsFormModal}
                        closeClientsFormModal={this.closeClientsFormModal}
                        type={'Clients'}
                        formType={'add'}
                        apiUrl={'clients'}
                        tl={tl}
                    />
                    : null
                }

                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </div>
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ClientSelectModal = reduxForm({
    form: "ClientSelectModal Form", // a unique identifier for this form
    validate,
})(ClientSelectModal);

export default withTranslation('common')(ClientSelectModal)