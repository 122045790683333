import { LOAD_PROFILE } from '../actions/profileActions';

const initialState = {
  firstName: 'Test',
  lastName: 'Testing'
};


export default function (state = initialState, action) {
switch (action.type) {
    case LOAD_PROFILE:
      return {
        data: action.data
      }
    default:
      return state;
  }
}
