import React from 'react';
// config file
export default class DefaultTextArea extends React.Component {

    handleChange = (event) => {
        if (this.props.handlerFromParent) {
            this.props.handlerFromParent(event.target.value, event.target.name);
        }
    }

    render() {
        const { input, placeholder, type, isDisable, meta: { touched, error }, className } = this.props;

        return (
            <div className="form__form-group-input-wrap">
                <textarea style={{ background: 'white' }} className={className ? className : ''} {...input} disabled={isDisable} placeholder={placeholder} type={type} />
                {touched && error && <span className="form__form-group-error">{error}</span>}
            </div>
        );
    }
}