import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Redirect } from 'react-router-dom';

import ShareIcon from 'mdi-react/ShareVariantIcon';
import BackIcon from 'mdi-react/ArrowLeftBoldIcon';
import UserIcon from 'mdi-react/UserCircleIcon';
import CallIcon from 'mdi-react/CallIcon';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import SharePropsModal from './SharePropsModal';

import Loader from '../../App/Loader';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

import fetch from '../../../config/service';
import config from '../../../config/config';
import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

import socket from '../../../config/Socket';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';
import ShareProperty from './ShareProperty';

class PropertyDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            PropertyDetails: {},
            PropertyDetailsArray: [],
            isLoading: false,
            tl: {},
        };
    }

    componentDidMount = async () => {
        let tl = {}
        if (this.props.location && this.props.location.state && this.props.location.state.tl) {
            tl = this.props.location.state.tl
        } else {
            tl = JSON.parse(localStorage.getItem('langJson'));
        }
        await this.setState({
            tl: tl
        })
        await this.getPropertyDataBasedOnId();

        if (this.props.location.state && this.props.location.state.fromScreen !== '/matchedPropertiesList') {
            localStorage.setItem('myClientCriteria', JSON.stringify(this.props.location.state.fromCriteria))
            localStorage.setItem('myFilterCriteria', JSON.stringify(this.props.location.state.currentFilterCriteria))
            localStorage.setItem('fromScreen', JSON.stringify(this.props.location.state.fromScreen))
        }
    }

    // Redirect Handler when clicked in Back Icon
    redirectToPreviousScreen = async () => {
        await this.setState({
            isNavigateToPreviiusScreen: true,
        })
    }

    // Get Property details based on path params id
    getPropertyDataBasedOnId = async () => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'))
        await this.setState({ isLoading: true })
        fetch('GET', `properties/${this.props.match.params.id}`)
            .then(async (response) => {
                if (response && response.details) {
                    let PropertyDetails = response.details;
                    socket.emit('agentViewed', { userId: user._id, email: PropertyDetails.email })
                    let keys = Object.keys(PropertyDetails);
                    let formData = [];

                    keys.forEach(key => {
                        if (!(key === 'createdBy' || key === '_id' || key === '__v' || key === 'active' || key === 'status' || key === 'photo' || key === 'createdByName' || key === 'deviceName' || key === 'created' || key === 'updated' || key === 'title')) {
                            formData.push(
                                { label: key, value: PropertyDetails[key] }
                            )
                        }
                    })
                    await this.setState({
                        PropertyDetails: PropertyDetails,
                        PropertyDetailsArray: formData,
                        isLoading: false
                    })
                } else {
                    if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }
            }).catch((err) => {
                this.setState({ isLoading: false });
                return err;
            });
    }

    // Share Icon onClick handler
    handleSingleShare = async (prop) => {
        let selectedProperties = [];
        selectedProperties.push(prop);

        await this.setState({
            isOpenSharePropsModal: true,
            selectedProperties: selectedProperties,
        })
    }

    // Close share property modal
    closeSharePropsModal = async () => {
        await this.setState({
            isOpenSharePropsModal: false,
        })
    }

    render() {
        const { PropertyDetails, tl } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Loader loader={this.state.isLoading} />
                <Card>
                    <CardHeader className='py-1'>
                        <div className="row m-0 align-items-center justify-content-between">
                            <BackIcon
                                className='backIcon cursorPointer'
                                onClick={() => this.redirectToPreviousScreen()}
                            />
                            <span className='pageHeadingCss'>
                                {tl['propertyDetails'] ? tl['propertyDetails'] : 'Property Details'}
                            </span>
                            <div className='mt-2'>
                                <ShareProperty item={{_id : this.props.match.params.id}}/>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody className='cardHeader'>
                        {/* Property Title */}
                        <div className="pb-2 pageSubHeadingCss">
                            {PropertyDetails.title}
                        </div>

                        {/* Property Overview Details */}
                        <div className="row detailsFont">
                            <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                <span className='textCapitalize lightText'>
                                    {tl['propertyFor'] ? tl['propertyFor'] : 'Property For'}{' : '}
                                </span>
                                {PropertyDetails.propertyFor}
                            </div>
                            <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                <span className='textCapitalize lightText'>
                                    {tl['propertyType'] ? tl['propertyType'] : 'Property Type'}{' : '}
                                </span>
                                {PropertyDetails.propertyType}
                            </div>
                            {PropertyDetails.propertyFor === 'Sale' ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['price'] ? tl['price'] : 'Price'}{' : '}{config.currencySymbol + ' '}
                                    </span>
                                    {PropertyDetails.totalPrice1}
                                </div> : null
                            }
                            {PropertyDetails.propertyFor === 'Rent' ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['rent'] ? tl['rent'] : 'Rent'}{' : '}{config.currencySymbol + ' '}
                                    </span>
                                    {PropertyDetails.totalPrice1}
                                </div>
                                : null
                            }
                            {PropertyDetails.propertyFor === 'Rent' ?
                                PropertyDetails.deposit ?
                                    <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                        <span className='textCapitalize lightText'>
                                            {tl['deposit'] ? tl['deposit'] : 'Deposit'}{' : '}{config.currencySymbol + ' '}
                                        </span>
                                        {PropertyDetails.deposit1}
                                    </div>
                                    : null
                                : null
                            }
                            {PropertyDetails.propertyFor === 'Rent' ?
                                PropertyDetails.maintenance ?
                                    <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                        <span className='textCapitalize lightText'>
                                            {tl['maintenance'] ? tl['maintenance'] : 'Maintenance'}{' : '}{config.currencySymbol + ' '}
                                        </span>
                                        {PropertyDetails.maintenance}
                                    </div>
                                    : null
                                : null
                            }
                            {PropertyDetails.propertyType === 'Commercial' ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['plotArea'] ? tl['plotArea'] : 'Plot Area'}{' : '}
                                    </span>
                                    {PropertyDetails.builtUpArea ?
                                        (parseInt(PropertyDetails.builtUpArea)).toLocaleString('en-IN') + ' ' + config.areaUnits
                                        : null
                                    }
                                </div>
                                : null
                            }
                            {PropertyDetails.propertyType !== 'Commercial' ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['builtUpArea'] ? tl['builtUpArea'] : 'Built-Up Area'}{' : '}
                                    </span>
                                    {PropertyDetails.builtUpArea ?
                                        (parseInt(PropertyDetails.builtUpArea)).toLocaleString('en-IN') + ' ' + config.areaUnits
                                        : null
                                    }
                                </div>
                                : null
                            }
                            {PropertyDetails.propertyType !== 'Commercial' ?
                                PropertyDetails.carpetArea ?
                                    <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                        <span className='textCapitalize lightText'>
                                            {tl['carpetArea'] ? tl['carpetArea'] : 'Carpet Area'}{' : '}
                                        </span>
                                        {PropertyDetails.carpetArea ?
                                            (parseInt(PropertyDetails.carpetArea)).toLocaleString('en-IN') + ' ' + config.areaUnits
                                            : null
                                        }
                                    </div>
                                    : null
                                : null
                            }
                            {PropertyDetails.avaiability ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['availability'] ? tl['availability'] : 'Availability'}{' : '}
                                    </span>
                                    {PropertyDetails.avaiability}
                                </div>
                                : null
                            }
                            {PropertyDetails.furnishingType ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['furnishingType'] ? tl['furnishingType'] : 'Furnishing Type'}{' : '}
                                    </span>
                                    {PropertyDetails.furnishingType}
                                </div>
                                : null
                            }
                            <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                <span className='textCapitalize lightText'>
                                    {tl['facing'] ? tl['facing'] : 'Facing'}{' : '}
                                </span>
                                {PropertyDetails.facing}
                            </div>
                            {PropertyDetails.bedrooms ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['bedRooms'] ? tl['bedRooms'] : 'Bedrooms'}{' : '}
                                    </span>
                                    {PropertyDetails.bedrooms}
                                </div>
                                : null
                            }
                            {PropertyDetails.bathrooms ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['bathRooms'] ? tl['bathRooms'] : 'Bathrooms'}{' : '}
                                    </span>
                                    {PropertyDetails.bathrooms}
                                </div>
                                : null
                            }
                            <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                <span className='textCapitalize lightText'>
                                    {tl['noOfFloors'] ? tl['noOfFloors'] : 'Number Of Floors'}{' : '}
                                </span>
                                {PropertyDetails.noOfFloors}
                            </div>
                            {PropertyDetails.floorNumber ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['floorNumber'] ? tl['floorNumber'] : 'Floor Number'}{' : '}
                                    </span>
                                    {PropertyDetails.floorNumber}
                                </div>
                                : null
                            }
                            <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                <span className='textCapitalize lightText'>
                                    {tl['city'] ? tl['city'] : 'City'}{' : '}
                                </span>
                                {PropertyDetails.city}
                            </div>
                            <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                <span className='textCapitalize lightText'>
                                    {tl['locality'] ? tl['locality'] : 'Locality'}{' : '}
                                </span>
                                {PropertyDetails.locality}
                            </div>
                            {PropertyDetails.landMark ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['landMark'] ? tl['landMark'] : 'Land Mark'}{' : '}
                                    </span>
                                    {PropertyDetails.landMark}
                                </div>
                                : null
                            }
                            {PropertyDetails.amenities ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['amenities'] ? tl['amenities'] : 'Amenities'}{' : '}
                                    </span>
                                    {PropertyDetails.amenities}
                                </div>
                                : null
                            }
                            {PropertyDetails.description ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['description'] ? tl['description'] : 'Description'}{' : '}
                                    </span>
                                    {PropertyDetails.description}
                                </div>
                                : null
                            }
                            {PropertyDetails.onClickCount ?
                                <div className='col-sm-6 col-md-5 col-lg-4 propertDetailsPadding'>
                                    <span className='textCapitalize lightText'>
                                        {tl['viewCount'] ? tl['viewCount'] : 'View Count'}{' : '}
                                    </span>
                                    {PropertyDetails.onClickCount}
                                </div>
                                : null
                            }
                        </div>

                        {/* Agent Info starts here*/}
                        <div className="mt-4 pageSubHeadingCss">
                            {tl['agentInfo'] ? tl['agentInfo'] : 'Agent Info'}{' : '}
                        </div>
                        <div className="mt-1 detailsFont">
                            {PropertyDetails.displayName ?
                                <span className=" textCapitalize mr-4">
                                    <UserIcon
                                        className='mr-1'
                                        color={config.themeBlue}
                                        size='18'
                                    />
                                    {PropertyDetails.displayName}
                                </span>
                                : null
                            }
                            {PropertyDetails.phoneNumber ?
                                <span >
                                    <CallIcon
                                        className='mr-1'
                                        color={config.themeBlue}
                                        size='18'
                                    />
                                    {PropertyDetails.phoneNumber}
                                </span>
                                : null
                            }
                        </div>
                        {/* Agent Info ends here*/}

                        {/* Property Photos Slider */}
                        {PropertyDetails.photo && PropertyDetails.photo.length > 0 ?
                            <AwesomeSlider
                                className='propDetailsPhotoSlider mx-auto mt-lg-4'
                                bullets={true}
                                style={{
                                    height: '500px',
                                    width: '100%'
                                }}
                            >
                                {PropertyDetails.photo && PropertyDetails.photo.length > 0 ?
                                    PropertyDetails.photo.map((item, index) => {
                                        return (
                                            <div key={index} className='photoViewModalSliderDiv'>
                                                <img
                                                    src={`${config.imgUrl}property/${item.name}`}
                                                    className='photoViewModalImg'
                                                    alt=''
                                                />
                                            </div>
                                        )
                                    })
                                    : null
                                }
                            </AwesomeSlider>
                            : null
                        }
                    </CardBody>
                </Card >

                {/* Navigate to previous screen */}
                {this.state.isNavigateToPreviiusScreen ?
                    <Redirect
                        to={{
                            pathname: this.props.location.state.fromScreen,
                            state: {
                                prevCriteria: this.props.location.state.fromCriteria,
                                prevData: this.props.location.state.fromData,
                                prevScreen: this.props.location.state.fromScreen,
                                ClientIdForMatcheProps: this.props.location.state.ClientIdForMatcheProps,
                                prevFilterCriteria: this.props.location.state.currentFilterCriteria,
                                criteriaForMatchedProps: this.props.location.state.criteriaForMatchedProps,
                                tl: tl,
                                state: this.props.location.state.state,
                                matchedFromScreen: this.props.location.state.matchedFromScreen,
                            }
                        }} />
                    : null
                }

                {/* Share Property Modal */}
                {this.state.isOpenSharePropsModal ?
                    <SharePropsModal
                        isOpenSharePropsModal={this.state.isOpenSharePropsModal}
                        selectedProperties={this.state.selectedProperties}
                        closeSharePropsModal={this.closeSharePropsModal}
                        tl={tl}
                    />
                    : null
                }

                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </div >
        );
    }
}

export default withTranslation('common')(PropertyDetails)