import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';

const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
const fr = `${process.env.PUBLIC_URL}/img/language/fr.png`;

const GbLng = () => (
    <span className="topbar__language-btn-title">
        <img src={gb} alt="gb" />
        <span>EN</span>
    </span>
);

const FrLng = () => (
    <span className="topbar__language-btn-title">
        <img src={fr} alt="fr" />
        <span>FR</span>
    </span>
);

const LanBtn = ({ lang }) => {
    return (
        <span className="topbar__language-btn-title">
            <span style={{ textTransform: 'uppercase' }}>{lang}</span>
        </span>
    )
}

class TranslationTool extends PureComponent {
    static propTypes = {
        i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
    };

    constructor() {
        super();
        this.state = {
            collapse: false,
            mainButtonContent: <LanBtn lang='en' />,
            langKeys: ['en']
        };
    }

    componentDidMount = async () => {
        let lang = localStorage.getItem('currentLang');
        let langKeys = JSON.parse(localStorage.getItem('langKeys'));

        let button = this.state.mainButtonContent;
        if (lang) {
            button = <LanBtn lang={lang} />
        }
        this.setState({
            mainButtonContent: button,
            langKeys: langKeys ? langKeys : []
        })
    }

    toggle = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    };

    // Change language based upon selection
    changeLanguage = async (lang) => {
        const { i18n } = this.props;
        i18n.changeLanguage(lang);

        this.setState({ mainButtonContent: <LanBtn lang={lang} /> });
        localStorage.setItem('currentLang', lang ? lang : 'en')
        this.toggle();
    };

    render() {
        const { mainButtonContent, collapse, langKeys } = this.state;

        return (

            <div className="topbar__collapse topbar__collapse--language">
                {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
                <button className="topbar__btn loginTranslateButton" type="button" onClick={this.toggle}>
                    {mainButtonContent}
                    <DownIcon className="topbar__icon mx-0" />
                </button>
                <Collapse
                    isOpen={collapse}
                    className="topbar__collapse-content topbar__collapse-content--language"
                >
                    {langKeys.map(lang => {
                        return (
                            <button
                                key={lang}
                                className="topbar__language-btn"
                                type="button"
                                onClick={() => this.changeLanguage(lang)}
                            >
                                <span style={{ textTransform: 'uppercase' }}>{lang}</span>
                            </button>
                        )
                    })}
                </Collapse>
            </div>
        );
    }
}

export default withTranslation('common')(TranslationTool);
