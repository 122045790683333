import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

//config
import fetch from '../../../config/service';
import configMessages from '../../../config/configMessages';
import config from '../../../config/config';

// Redux Form
import DefaultInput from '../../../shared/components/form/DefaultInput';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { connect } from 'react-redux';
import validate from '../../Validations/validate';

// UI Components
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import Loader from '../../App/Loader';
import InfoIcon from 'mdi-react/InfoCircleIcon';

//ToolTip
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

//tooltip styles
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.themeColor,
    color: config.themeWhite,
    boxShadow: theme.shadows[10],
    fontSize: 12,
    fontWeight: '400',
    margin: 0,
  },

}))(Tooltip);

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)

class RegistrationForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isRegistrationSuccess: false,
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    this.props.change('pwd', '')
    this.props.load({})
  }

  // Submit handler when click on Register
  submit = (values) => {
    if (values) {
      this.handleLoginUser(values);
    }
  }

  // Registration Handler
  handleLoginUser = async (values) => {
    let userBody = values;

    return fetch('POST', 'users/signUp?response=true', userBody)
      .then(async (response) => {
        if (response && response.respCode && response.respCode === 204) {
          showToasterMessage(response.respMessage, 'success');
          this.props.reset();
          await this.setState({
            isRegistrationSuccess: true,
          })
        }
      }).catch((err) => {
        return err
      });

  }

  render() {
    const { handleSubmit, tl, t } = this.props;

    return (
      <form className="form registrationForm" onSubmit={handleSubmit(this.submit)}>
        <Loader loader={this.state.isLoading} />
        <div className="mb-2">
          <h4>
            {tl['agentRegistration'] ? tl['agentRegistration'] : 'Agent Registration'}
          </h4>
        </div>
        <div className="row">
          <div className="form__form-group col-sm-6">
            <span className="form__form-group-label">{tl['firstName'] ? tl['firstName'] : 'First Name'} *</span>
            <div className="form__form-group-field">
              <Field
                name="firstName"
                component={DefaultInput}
                type="text"
                placeholder={tl['firstName'] ? tl['firstName'] : 'First Name'}
                validate={required}
              />
            </div>
          </div>
          <div className="form__form-group col-sm-6">
            <span className="form__form-group-label">{tl['lastName'] ? tl['lastName'] : 'Last Name'} *</span>
            <div className="form__form-group-field">
              <Field
                name="lastName"
                component={DefaultInput}
                type="text"
                placeholder={tl['lastName'] ? tl['lastName'] : 'Last Name'}
                validate={required}
              />
            </div>
          </div>
          <div className="form__form-group col-sm-6">
            <span className="form__form-group-label">{tl['email'] ? tl['email'] : 'Email'} *</span>
            <div className="form__form-group-field">
              <Field
                name="email"
                component={DefaultInput}
                type="email"
                placeholder={tl['email'] ? tl['email'] : 'Email'}
                validate={required}
              />
            </div>
          </div>
          <div className="form__form-group col-sm-6">
            <span className="form__form-group-label">{tl['phoneNumber'] ? tl['phoneNumber'] : 'Phone Number'} *</span>
            <div className="form__form-group-field">
              <Field
                name="phoneNumber"
                component={DefaultInput}
                type="text"
                placeholder={tl['phoneNumber'] ? tl['phoneNumber'] : 'Phone Number'}
                validate={required}
              />
            </div>
          </div>
          <div className="form__form-group col-sm-6">
            <span className="form__form-group-label">
              {tl['password'] ? tl['password'] : 'Password'} *
            </span>
            <LightTooltip
              title='Password must contain atleast one number one alphabet of each case and a special char with a minimum length of 8 charactrs'
              placement='top-start'
            >
              <InfoIcon className='passwordInfoIcon ml-1' size='18' />
            </LightTooltip>
            <div className="form__form-group-field">
              <Field
                name="password"
                component={DefaultInput}
                type={'password'}
                placeholder={tl['password'] ? tl['password'] : 'Password'}
                validate={required}
              />
            </div>
          </div>
          <div className="form__form-group col-sm-6">
            <span className="form__form-group-label">{tl['confirmPassword'] ? tl['confirmPassword'] : 'Confirm Password'} *</span>
            <div className="form__form-group-field">
              <Field
                name="confirmPassword"
                component={DefaultInput}
                type={'password'}
                placeholder={tl['confirmPassword'] ? tl['confirmPassword'] : 'Confirm Password'}
                validate={required}
              />
            </div>
          </div>
          <div className="form__form-group col-sm-6">
            <span className="form__form-group-label">{tl['reraNumber'] ? tl['reraNumber'] : 'RERA Number'} *</span>
            <div className="form__form-group-field">
              <Field
                name="RERANumber"
                component={DefaultInput}
                type="text"
                placeholder={tl['reraNumber'] ? tl['reraNumber'] : 'RERA Number'}
                validate={required}
              />
            </div>
          </div>
        </div>

        {/*Register*/}
        <div className="col-12 px-0 text-right">
          <Button
            className="mb-0 mt-2"
            color="primary"
            disabled={this.state.isLoginDisabled}
          >
            {tl['register'] ? tl['register'] : 'Register'}
          </Button>
        </div>

        {/* Link To Registration Screen */}
        <div className="col-12 px-0 account__forgot-password pt-2">
          <Link
            className='p-0 pl-1'
            to={{ pathname: 'log_in', state: { tl: tl } }}
          >
            {tl['backToLogin'] ? tl['backToLogin'] : 'Back To Login'}
          </Link>
        </div>

        {/* Redirect to # after successful login */}
        {this.state.isRegistrationSuccess ?
          <Redirect
            to={{ pathname: 'log_in', state: { tl: tl } }}
          />
          : null
        }
      </form>
    );
  }
}

RegistrationForm = reduxForm({
  form: 'registration_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(RegistrationForm);

// You have to connect() to any reducers that you wish to connect to yourself
RegistrationForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(RegistrationForm);

export default withTranslation('common')(RegistrationForm);