import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, CardHeader } from 'reactstrap';

// Form Fields
import PropTypes from 'prop-types';
import DefaultInput from '../../../../shared/components/form/DefaultInput';
import RenderFileInputField from '../../../Form/components/FileUpload';

// Redux
import { Field, reduxForm } from 'redux-form';
import validate from './validate';
import { connect } from 'react-redux';
import { load as loadAccount } from './../../../../redux/reducers/commonReducer';

// Congig files
import fetchMethodRequest from '../../../../config/service';
import config from '../../../../config/config';
import configMessages from '../../../../config/configMessages';

// Custom Components
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import Loader from '../../../App/Loader';

//Session expired modal
import SessionExpireModal from '../../../SessionExpire/sessionExpireModal';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)

class Profile extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      loginUserRole: '',
    };
  }

  componentDidMount = async () => {
    await this.getUserData();
  }

  // get data from server based on Id
  getUserData = async (msg) => {
    if (localStorage.getItem('loginCredentials')) {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      let accessToken = user.accessToken;
      let refreshToken = user.refreshToken;

      this.setState({
        userId: user._id,
        pic: user.photo,
      });
      let Url = 'users/' + user._id;
      fetchMethodRequest('GET', Url).then(async (response) => {
        if (response && response.respCode) {
          let respDetails = response.details;
          this.props.load(respDetails);
          user.photo = response.details.photo;
          respDetails['accessToken'] = accessToken;
          respDetails['refreshToken'] = refreshToken;
          localStorage.setItem('loginCredentials', JSON.stringify(respDetails))
          await this.setState({
            pic: response.details.photo,
            isLoading: false
          });
        } else if (response && response.errorCode) {
          this.setState({
            isLoading: false
          });
          this.props.load(user);
        }
      }).catch((err) => {
        return err;
      });
    } else {
      return;
    }
  }

  // Profile Update Handler when clicked on Submit
  submit = async (values) => {
    let url = `users/${this.state.userId}`;
    let body = values;
    delete body['email']
    await this.setState({ isLoading: true })
    if (body) {
      fetchMethodRequest('PUT', url, body).then(async resp => {
        if (resp && resp.respCode) {
          showToasterMessage(resp.respMessage, 'success')
          await this.getUserData();
          await this.setState({ isLoading: false })
        } else {
          if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
            await this.setState({
              isOpenSessionExpiredModal: true,
            })
          } else {
            showToasterMessage(resp.errorMessage, 'error')
            await this.setState({ isLoading: false })
          }
        }
      }).catch(err => {
        return err
      })
    } else {
      await this.setState({ isLoading: false })
    }
  }

  // Profile Photo Upload Handler
  uploadProfilePic = async (picture) => {
    let url = `uploads?uploadPath=user&entityType=user&_id=${this.state.userId}`
    await this.setState({ isLoading: true });
    if (picture) {
      fetchMethodRequest('POST', url, picture, 'upload').then(async (res) => {
        if (res && res.respCode) {
          await this.getUserData(res.respMessage);
          await showToasterMessage(res.respMessage, 'success');
        } else {
          if (res.errorMessage && res.errorMessage === 'Session expired please login again.') {
            await this.setState({
              isOpenSessionExpiredModal: true,
            })
          } else {
            showToasterMessage(res.errorCode, 'error');
            await this.setState({ isLoading: false });
          }
        }
      }).catch((err) => {
        return err;
      });
    } else {
      await this.setState({ isLoading: false });
    }
  }

  render() {
    const { handleSubmit, tl, t } = this.props;
    return (
      <Col md={12} lg={12}>
        <Loader loader={this.state.isLoading} />
        <Card>
          <CardHeader style={{ fontSize: 20 }}>
            <div className="text-center">
              <h5 className='blackText textUpperCase'>
                {tl && tl['profile'] ? tl['profile'] : 'Profile'}
              </h5>
            </div>
          </CardHeader>
          <CardBody>
            <form className="form row" onSubmit={handleSubmit(this.submit)}>
              <div className="form__form-group col-md-6">
                <span className="form__form-group-label">
                  {tl && tl['firstName'] ? tl['firstName'] : 'First Name'} *
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="firstName"
                    component={DefaultInput}
                    type="text"
                    placeholder={tl && tl['firstName'] ? tl['firstName'] : 'First Name'}
                    validate={required}
                  />
                </div>
              </div>
              <div className="form__form-group col-md-6">
                <span className="form__form-group-label">
                  {tl && tl['lastName'] ? tl['lastName'] : 'Last Name'} *
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="lastName"
                    component={DefaultInput}
                    type="text"
                    placeholder={tl && tl['lastName'] ? tl['lastName'] : 'Last Name'}
                    validate={required}
                  />
                </div>
              </div>
              <div className="form__form-group col-md-6">
                <span className="form__form-group-label">
                  {tl && tl['phoneNumber'] ? tl['phoneNumber'] : 'Phone Number'} *
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="phoneNumber"
                    component={DefaultInput}
                    type="text"
                    placeholder={tl && tl['phoneNumber'] ? tl['phoneNumber'] : 'Phone Number'}
                    validate={required}
                  />
                </div>
              </div>
              <div className="form__form-group col-md-6">
                <span className="form__form-group-label">
                  {tl && tl['email'] ? tl['email'] : 'Email'} *
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="email"
                    component={DefaultInput}
                    type="email"
                    placeholder={tl && tl['email'] ? tl['email'] : 'Email'}
                    validate={required}
                    isDisable={true}
                  />
                </div>
              </div>
              <div className="form__form-group col-md-6">
                <span className="form__form-group-label">
                  {tl && tl['RERANumber'] ? tl['RERANumber'] : 'RERA Number'} *
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="RERANumber"
                    component={DefaultInput}
                    type="text"
                    placeholder={tl && tl['RERANumber'] ? tl['RERANumber'] : 'RERA Number'}
                    validate={required}
                  />
                </div>
              </div>

              <div className="form__form-group col-md-6">
                <span className="form__form-group-label">
                  {tl && tl['uploadPhoto'] ? tl['uploadPhoto'] : 'Upload Photo'}
                </span>
                <div className="form__form-group-field">
                  <Field
                    ref={(r) => this.profilePhotoLink = r}
                    name="photo"
                    className='profileUpload'
                    component={RenderFileInputField}
                    onRef={(ref) => (this.profileUploadRef = ref)}
                    acceptType="image/*"
                    chooseLabel={tl && tl['upload'] ? tl['upload'] : 'Upload'}
                    type='profile'
                    mode='basic'
                    savePhoto={this.uploadProfilePic}
                  />
                  <div className='col-auto ml-auto px-0'>
                    <div style={{ justifyContent: 'center' }}>
                      {(this.state.pic) ?
                        <img
                          alt='Profile.jpg'
                          src={`${config.imgUrl}user/${this.state.pic}`}
                          className='profileEditImage'
                        />
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="form__form-group col-12 text-right mb-0 mt-2">
                <Button color="primary" type="submit" className='mb-0'>
                  {tl && tl['update'] ? tl['update'] : 'Update'}
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
        {/* #Session Expire Modal */}
        {this.state.isOpenSessionExpiredModal ?
          <SessionExpireModal
            isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
            tl={tl}
          />
          : null
        }
      </Col>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Profile = reduxForm({
  form: "ProfileForm", // a unique identifier for this form
  enableReinitialize: true,
  validate
})(Profile);

// You have to connect() to any reducers that you wish to connect to yourself
Profile = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(Profile);

export default withTranslation('common')(Profile);