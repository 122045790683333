import React from 'react';
import configImages from '../../../config/configImages';
import ShareIcon from 'mdi-react/ShareVariantIcon';
import { withTranslation } from 'react-i18next';
import fetchMethodRequest from '../../../config/service';
import Config from '../../../config/config';
import { Button } from 'reactstrap';
import WhatsAppIcon from 'mdi-react/WhatsappIcon';
import TelegramIcon from 'mdi-react/TelegramIcon';

class ShareProperty extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openDropdownId: null,
            item : this.props.item ? this.props.item : {_id : null},
            selectedProperties : [],
            multipleShare : this.props.multipleShare,
            toggleMenu : false,
            isDisable : this.props.isDisable,
        };
        this.componentRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
      }
    
      componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
      }
    
      handleClickOutside = (event) => {
        // Check if the click is outside the component
        if (this.componentRef.current && !this.componentRef.current.contains(event.target)) {
          if (this.state.multipleShare) {
            this.setState({ toggleMenu: false });
          } else {
            this.setState({ openDropdownId: null });
          }
        }
      };

    handleSingleShare = (event, itemId) => {
        event.preventDefault();
        this.setState((prevState) => ({
          openDropdownId: prevState.openDropdownId === itemId ? null : itemId,
        }));
    };

    handleGLobalShare = async () => {
        let propsToShare = [];
        if (window.location.pathname === '/my_properties') {
            if (localStorage.getItem('myPropsSelected') && localStorage.getItem('myPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('myPropsSelected'))
            }
        } else if (window.location.pathname === '/search_properties') {
            if (localStorage.getItem('searchPropsSelected') && localStorage.getItem('searchPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('searchPropsSelected'))
            }
        } else if (window.location.pathname === '/matched_properties') {
            if (localStorage.getItem('matchedPropsSelected') && localStorage.getItem('matchedPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('matchedPropsSelected'))
            }
        } else if (window.location.pathname === '/show_matched_properties') {
          if (localStorage.getItem('showMatchedPropsSelected') && localStorage.getItem('showMatchedPropsSelected') !== 'undefined') {
              propsToShare = JSON.parse(localStorage.getItem('showMatchedPropsSelected'))
          }
      }
        this.setState({ toggleMenu : !this.state.toggleMenu})
        let selectedProperties = propsToShare;

        if (selectedProperties && selectedProperties.length > Config.shareLimit) {
            await this.setState({
                selectedProperties: selectedProperties,
            })
            // need to write the warning
        } else {
            await this.setState({
                selectedProperties: selectedProperties,
            })
        }
    }

      generateLink = async () => {
        let url = 'sharedCriteria';
        let body = {type : 'propertyIds'};
        if(this.state.selectedProperties.length <=0 && this.state.item) {
            body.propertyIds = [this.state.item._id];
        }
        else if (this.state.selectedProperties && this.state.selectedProperties.length > 0) {
            body.agtentEmail = this.state.selectedProperties[0].email;
            body.propertyIds = this.state.selectedProperties.map( prop => prop._id);
        }
        if(this.state.item && this.state.item.email){
            body.agtentEmail = this.state.item.email
        }
        return fetchMethodRequest('POST', url , body)
      }


      shareLink = async (platform) => {
        let res = await this.generateLink();
        let link = res.link;
        if (!link) return;
      
        let url;
        if (platform === 'WhatsApp') {
          const whatsappURL = encodeURIComponent(`Check out this link: ${link}`);
          url = `https://wa.me?text=${whatsappURL}`;
        } else if (platform === 'Telegram') {
          const telegramURL = encodeURIComponent(`Check out this link: ${link}`);
          url = `https://t.me/share/url?url=${telegramURL}`;
        } else {
          console.error('Unsupported platform');
          return;
        }
      
        if (navigator.userAgent.match(/iPhone|iPad|iPod|Android/i)) {
          // Mobile specific logic
          window.location.href = url; // Redirecting instead of opening a new window
        } else {
          // Desktop specific logic
          const windowFeatures = 'menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,width=800,height=600';
          const newWindow = window.open(url, '_blank', windowFeatures);
      
          if (newWindow) {
            newWindow.focus();
          } else {
            alert('Failed to open the new window. Please allow pop-ups for this website.');
          }
        }
        if(this.state.multipleShare){
            this.setState({toggleMenu : false});
        } else {
            this.setState({ openDropdownId: null });
        }
      };
      

    render() {
        return (
            <span className='position-relative' ref={this.componentRef}>
            { this.state.multipleShare ?
                <Button
                className={this.props.class ? this.props.class : 'mr-0 ml-2 buttonTextWeight mb-1'}
                id={`navbarDropdown-${this.state.item ? this.state.item._id : ''}`}
                aria-expanded={this.state.multipleShare && this.state.toggleMenu}
                color='primary'
                disabled={this.props.isDisable}
                onClick={this.handleGLobalShare}
                >
                <span className='d-flex align-items-center'>
                  <ShareIcon
                    className='shareIconAlignment cursorPointer ml-auto mb-1 mr-0 ml-0'
                  />
                  <span className='ml-1 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'>
                    { 'Share'}
                  </span>
                </span>
                </Button> :
                // <ShareIcon  // For the Future Implementation
                //     className="shareIconAlignment cursorPointer ml-auto"
                //     id={`navbarDropdown-${this.state.item ? this.state.item._id : ''}`}
                //     role="button"
                //     aria-expanded={this.state.item && this.state.openDropdownId === this.state.item._id}
                //     onClick={(event) => this.handleSingleShare(event, this.state.item ? this.state.item._id :'')}
                // />
                <div className='d-flex justify-content-between align-items-center w-45'>
                  <TelegramIcon className='shareIcons cursorPointer mb-1 mr-0 ml-0' style={{color :'#0088CC'}} onClick={() => this.shareLink('Telegram')}/>
                  <WhatsAppIcon className='shareIcons cursorPointer mb-1 mr-0 ml-0' style={{color :'#25D366'}}  onClick={() => this.shareLink('WhatsApp')}/>
                </div>
            }
                {((this.state.item._id && this.state.openDropdownId === this.state.item._id) || (this.props.multipleShare && this.state.toggleMenu)) && (
                <ul className="dropdown-menu show" aria-labelledby={`navbarDropdown-${this.state.item ? this.state.item._id : ''}`}>
                    {this.props.multipleShare ? console.log(this.state.item && this.state.openDropdownId === this.state.item._id,this.props.multipleShare && this.state.toggleMenu) :null}
                    <li className="dropdown-item d-flex justify-content-around align-items-center" onClick={() => this.shareLink('WhatsApp')}>
                    <div className="logo-wrapper">
                        <img src={configImages.watsappImage} alt="" />
                    </div>
                        WhatsApp</li>
                    <li className="dropdown-item d-flex justify-content-around align-items-center" onClick={() => this.shareLink('Telegram')}>
                        <div className="logo-wrapper">
                            <img src={configImages.telegramImage} alt="" />
                        </div>
                        Telegram</li>
                            
                </ul>
                )}
            </span>
        )
    }
}

export default withTranslation('common')(ShareProperty)
