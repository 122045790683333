import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Router from './Router';

class RouterComponent extends Component {


    render() {
        return (
            <div>
                <Router />
            </div>
        )
    }
}

export default withTranslation('common')(RouterComponent);