import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

import DefaultInput from '../../../shared/components/form/DefaultInput';

import { Field, reduxForm } from 'redux-form';
import validate from '../../Validations/validate';

import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

const required = value => (value || typeof value === 'string' ? undefined : 'Please enter email')

class SharePropsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenSessionExpiredModal: false,
        }
    }

    // Handle properties share 
    submit = async (values) => {
        let properties = await this.props.selectedProperties;
        let body = {};

        body = {
            to: values.email,
            name: 'Client',
            properties: properties,
        }
        fetchMethodRequest('POST', 'properties/shareProperties', body).then(async resp => {
            if (resp && resp.respCode) {
                showToasterMessage(resp.respMessage, 'success')
                this.props.closeSharePropsModal()
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        }).catch(err => {
            console.log(err);
            return;
        })
    }

    render() {
        const { handleSubmit, tl, t } = this.props;
        return (
            <Modal
                isOpen={this.props.isOpenSharePropsModal}
                className={`modal-dialog-centered modal-dialog--primary formModalBorderRadius`}
            >
                <ModalBody className='p-0'>
                    <form className='form' onSubmit={handleSubmit(this.submit)}>
                        <h4 className="mx-auto mb-3">
                            {tl['enterEmailToShare'] ? tl['enterEmailToShare'] : 'Enter an Email to Share'}
                        </h4>
                        <div className="col-12 text-left propertiesTitleToShareDiv mb-2">
                            {this.props.selectedProperties && this.props.selectedProperties.length > 0 ?
                                this.props.selectedProperties.map((prop, index) => {
                                    return (
                                        <span key={index} className='propTitleToShare'>
                                            {prop.title}
                                        </span>
                                    )
                                })
                                : null
                            }
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label d-flex">
                                {tl['email'] ? tl['email'] : 'Email'} *
                            </span>
                            <div className="form__form-group-field">
                                <Field
                                    name='email'
                                    component={DefaultInput}
                                    type="email"
                                    placeholder={tl['email'] ? tl['email'] : 'Email'}
                                    validate={required}
                                />
                            </div>
                        </div>
                        <div className="col-12 text-right px-0">
                            <Button
                                color="primary"
                                type="button"
                                className='deleteModalBtn marginRight buttonTextWeight'
                                onClick={() => this.props.closeSharePropsModal()}
                            >
                                {tl['cancel'] ? tl['cancel'] : 'Cancel'}
                            </Button>
                            <Button
                                color='primary'
                                type="submit"
                                className='deleteModalBtn buttonTextWeight'
                            >
                                {tl['share'] ? tl['share'] : 'Share'}
                            </Button>
                        </div>
                    </form>
                </ModalBody>
                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </Modal>
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SharePropsModal = reduxForm({
    form: "SharePropsModal Form", // a unique identifier for this form
    validate,
})(SharePropsModal);

export default withTranslation('common')(SharePropsModal)