import React from 'react';
import { Button, Modal } from 'reactstrap';
import config from '../../../config/config';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

class ShareLimitModal extends React.Component {

    render() {
        const { t, tl } = this.props;
        return (
            <Modal
                isOpen={this.props.isOpenShareLimitModal}
                toggle={() => this.props.closeShareLimitModal()}
                className={`modal-dialog invalidFilterModal modal-dialog-centered modal-dialog--primary formModalBorderRadius`}>
                <h4>
                    {tl['warning'] ? tl['warning'] : 'Warning'}
                </h4>
                <h5 className='mt-2'>
                    {tl['maxShareLimit'] ? tl['maxShareLimit'] : 'Maximum share limit is'}{' '}{config.shareLimit}
                </h5>
                <div className='text-right'>
                    <Button
                        color='primary'
                        className='buttonTextWeight'
                        onClick={this.props.closeShareLimitModal}
                    >
                        {tl['ok'] ? tl['ok'] : 'Ok'}
                    </Button>
                </div>
            </Modal>
        );
    }
}

export default withTranslation('common')(ShareLimitModal)