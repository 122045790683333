import React from "react";
import TableRender from '../../Tables/CommonDataTable/TableRender';

class MatchedPropsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleType: []
        };
    }

    getTableFields = async () => {
        let data = [
            { textAlign: 'center', width: 8, selectionMode: 'multiple', field: 'Sno', filter: false, sortable: false },
            { textAlign: 'center', width: 6, field: 'Sno', header: 'sno' },
            { textAlign: 'center', width: 10, field: 'propertyFor', header: 'propertyFor', placeholder: 'search' ,showInMoblie: true },
            { textAlign: 'center', width: 15, field: 'propertyType', header: 'propertyType', placeholder: 'search' ,showInMoblie: true },
            { textAlign: 'center', width: 15, field: 'totalPrice', header: 'price' ,showInMoblie: true },
            { textAlign: 'center', width: 10, field: 'builtUpArea', header: 'area' ,showInMoblie: true },
            { textAlign: 'center', width: 15, field: 'availability', header: 'availability',showInMoblie: true },
            { textAlign: 'center', width: 15, field: 'furnishingType', header: 'furnishingType',showInMoblie: true },
            { textAlign: 'center', width: 10, field: 'Actions', header: 'actions', },
        ];
        return data;
    };

    render() {
        const { tl } = this.props;
        return (
            <TableRender
                type='matchedProps'
                tableFields={() => this.getTableFields()}
                tl={tl}
            />
        );
    }
}
export default (MatchedPropsTable);