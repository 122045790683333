
const configMessages = {
    email: 'Please enter valid email address',
    fillField: 'Please fill above field',
    checkRadio: 'Please select any one radio',
    aadharcardNumber: 'Please enter valid Aadhar number (no spaces in between)',
    pancardNumber: 'Please enter valid Pan Card Number',
    phoneNumber: 'Please enter valid Phone Number',
    InvalidDate: 'Invalid Date',
    noRecords: 'Found No Details',
    loading: 'Loading...',
    RERANumber: 'Please enter valid RERA Number',
    password: 'Please enter valid Password',
};
export default configMessages;
