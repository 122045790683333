import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Card, CardBody, ButtonToolbar } from 'reactstrap';
import classNames from 'classnames';

import DefaultInput from '../../../shared/components/form/DefaultInput';
import DefaultTextArea from '../../../shared/components/form/DefaultTextArea';
import { FileUpload } from 'primereact/fileupload';

import config from '../../../config/config';
import configMessages from '../../../config/configMessages';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import validate from '../../Validations/validate';
import CheckBox from '../../../shared/components/form/CheckBox';

//Client Reducer import to used in redux connect of this Form
import { loadClientData as loadClientFormData } from './../../../redux/reducers/clientFormReducer';

import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Loader from '../../App/Loader';

import fetchMethodRequest from '../../../config/service';

import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)

class ClientsFormModal extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            formFields: [],
            formValues: {},
            selectedValue: '',
            checkedValues: [],
            colored: false,
            header: true,
            isLoading: false,
            checked: false,
            isClientAdded: false,
            isBuyer: false,
            isSeller: false,
        };
        this.props.loadCF({});
    }

    componentDidMount = async () => {
        if (this.props.formType && this.props.formType === 'edit') {
            await this.getrowData(await this.props.clientRowData)
        } else {
            if (this.props.isFromAddProperty) {
                await this.setState({
                    isSeller: true,
                })
            } else {
                await this.setState({
                    isBuyer: true,
                })
            }
        }
    }

    //getSingleRowData
    getrowData = async (rowData) => {
        let loadData = rowData
        let pic = null;
        if (this.props.formType && this.props.formType === 'edit') {
            if (loadData && loadData.photo) {
                pic = loadData.photo;
            }
            if (loadData.type) {
                if (loadData.type.includes('Buyer')) {
                    loadData['isBuyer'] = true;
                }
                if (loadData.type.includes('Seller')) {
                    loadData['isSeller'] = true;
                }
            }
        } else {
            loadData = {};
            loadData['isBuyer'] = true;
        }
        // to load intail values of form
        if (this.props.loadCF) {
            this.props.loadCF(loadData);
        }

        await this.setState({
            pic: pic,
            isBuyer: loadData['isBuyer'],
            isSeller: loadData['isSeller'],
            editRowDataID: loadData._id,
        });
    }

    // form Submit
    submit = (values) => {
        let values_ = values;
        let typeValue = '';
        if (this.props.type === 'Clients') {
            if (values.email) {
                let regex = config["emailRegex"];
                if (regex.test(values.email)) {
                } else {
                    this.setState({ emailError: true });
                    return;
                }
            }
            if (values.phoneNumber) {
                let regex = config["phoneNumberRegex"];
                if (regex.test(values.phoneNumber)) {
                } else {
                    this.setState({ phoneNumberError: true });
                    return;
                }
            }
        }

        if (this.state.isBuyer && this.state.isSeller) {
            typeValue = 'Buyer/Seller';
        } else if (this.state.isBuyer) {
            typeValue = 'Buyer'
        } else if (this.state.isSeller) {
            typeValue = 'Seller'
        } else {
            typeValue = 'Buyer';
        }
        values_.type = typeValue;

        let formValues = values_;
        if (Object.keys(values_).length > 0) {
            this.saveDataToServer(formValues);
        } else {
            return;
        }
    }

    //send data to server
    saveDataToServer = async (formValues) => {
        this.setState({ isLoading: true });
        if (formValues) {
            let method, apiUrl;
            if (this.props.formType === 'edit') {
                method = 'PUT';
                apiUrl = `clients/${this.state.editRowDataID}`;
            } else {
                method = 'POST';
                apiUrl = `clients?response=true`;
            }

            let userBody = formValues;
            let agentEmail = JSON.parse(localStorage.getItem('loginCredentials')).email;
            userBody['agentEmail'] = agentEmail;

            return fetchMethodRequest(method, apiUrl, userBody)
                .then(async (response) => {
                    if (response && response.respCode) {
                        if (response && response.clientId && this.state.newPic) {
                            await this.uploadProfilePic(this.state.newPic, response.clientId)
                        }
                        this.closeFormModal();
                        showToasterMessage(response.respMessage, 'success');
                        if (this.props.getTableListDataFromServer) {
                            await this.props.getTableListDataFromServer();
                        }
                        await this.props.closeClientsFormModal(
                            {
                                label: response.details.displayName,
                                value: response.details._id,
                                _id: response.details.displayName,
                            }
                        );
                        await this.setState({ isLoading: false });
                     } else {
                        if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                            await this.setState({
                                isOpenSessionExpiredModal: true,
                            })
                        } else {
                            this.setState({ isLoading: false });
                            showToasterMessage(response.errorMessage, 'error');
                        }
                    }
                }).catch((err) => {
                    return err;
                });
        } else {
            this.setState({ isLoading: false });
            return;
        }
    }

    //Photo Upload Handler
    uploadProfilePic = async (picture, id) => {
        let clientId = '';
        if (id) {
            clientId = id
        } else {
            clientId = this.state.editRowDataID;
        }

        let url = `uploads?uploadPath=client&entityType=client&_id=${clientId}`
        if (picture) {
            fetchMethodRequest('POST', url, picture, 'upload').then(async (res) => {
                if (res && res.respCode) {
                    await this.setState({
                        isLoading: false,
                    });
                    showToasterMessage('Photo uploaded successfully', 'success');
                } else if (res.errorCode) {
                    showToasterMessage(res.errorCode, 'error');
                    await this.setState({ isLoading: false });
                }
            }).catch((err) => {
                return err;
            });
        } else {
            await this.setState({ isLoading: false });
        }
    }

    // Reset form
    clearFormFields = async () => {
        // to empty form
        if (this.props.loadCF) {
            this.props.loadCF({});
        }
    }

    // Close form Modal
    closeFormModal = async () => {
        await this.clearFormFields();
        await this.props.reset();
        await this.props.closeClientsFormModal();
    }

    // Onchange checkbox
    onChange = (value) => {
        if (value === 'isBuyer') {
            this.setState({
                isBuyer: !this.state.isBuyer,
            })
        }
        if (value === 'isSeller') {
            this.setState({
                isSeller: !this.state.isSeller,
            })
        }
    }

    // Handle Photo selection
    handleFile = async (e) => {
        let tmppath = URL.createObjectURL(e.files[0]);
        await this.setState({
            newfilePath: tmppath,
            newPic: e.files[0],
            pic: null,
        })
    }

    render() {
        const { tl, t } = this.props;
        const { handleSubmit } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <div style={{ display: 'flex' }}>
                <Modal
                    isOpen={this.props.isOpenClientsFormModal}
                    className={`modal-dialog-centered modal-dialog--primary  ${modalClass} formModalBorderRadius`}
                >
                    <ModalHeader className="modal__header">
                        <button
                            className="lnr lnr-cross modal__close-btn bold-text"
                            style={{ fontSize: '17px' }}
                            type="button"
                            onClick={this.closeFormModal}
                        />
                        <p
                            className="textCapitalize mt-0"
                            style={{ fontSize: '17px' }}
                        >
                            {t(this.props.formType) ? t(this.props.formType) : this.props.formType}{' '}
                            {tl['client'] ? tl['client'] : 'Client'}
                        </p>
                    </ModalHeader>
                    <ModalBody className="p-4 mb-0 " >
                        <Card className='m-0 p-0'>
                            <CardBody className='m-0 p-0'>
                                <Loader loader={this.state.isLoading} />
                                <form className='form' key={this.props.type} onSubmit={handleSubmit(this.submit)} >
                                    <div className="col-sm-6">
                                        <div className="form__form-group" >
                                            <label className="form__form-group-label text-capitalize">
                                                {tl['firstName'] ? tl['firstName'] : 'First Name'} *
                                            </label>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name={'firstName'}
                                                    component={DefaultInput}
                                                    id={'firstName'}
                                                    placeholder={tl['firstName'] ? tl['firstName'] : 'First Name'}
                                                    validate={required}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form__form-group" >
                                            <label className="form__form-group-label text-capitalize">
                                                {tl['lastName'] ? tl['lastName'] : 'Last Name'}
                                            </label>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name={'lastName'}
                                                    component={DefaultInput}
                                                    id={'lastName'}
                                                    placeholder={tl['lastName'] ? tl['lastName'] : 'Last Name'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form__form-group" >
                                            <label className="form__form-group-label text-capitalize">
                                                {tl['email'] ? tl['email'] : 'Email'}
                                            </label>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name={'email'}
                                                    component={DefaultInput}
                                                    id={'email'}
                                                    placeholder={tl['email'] ? tl['email'] : 'Email'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form__form-group" >
                                            <label className="form__form-group-label text-capitalize">
                                                {tl['phoneNumber'] ? tl['phoneNumber'] : 'Phone Number'}
                                            </label>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name={'phoneNumber'}
                                                    component={DefaultInput}
                                                    id={'phoneNumber'}
                                                    placeholder={tl['phoneNumber'] ? tl['phoneNumber'] : 'Phone Number'}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form__form-group" >
                                            <label className="form__form-group-label text-capitalize">
                                                {tl['notes'] ? tl['notes'] : 'Notes'}
                                            </label>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name={'note'}
                                                    component={DefaultTextArea}
                                                    id={'note'}
                                                    placeholder={tl['notes'] ? tl['notes'] : 'Notes'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* {this.props.formType && this.props.formType === 'edit' ? */}
                                    <div className="col-sm-6">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                {tl['selectPhoto'] ? tl['selectPhoto'] : 'Select Photo'}
                                            </span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    ref={(r) => this.clientPhotoLink = r}
                                                    name="photo"
                                                    className='clientUpload'
                                                    component={FileUpload}
                                                    acceptType="image/*"
                                                    chooseLabel={tl['select'] ? tl['select'] : 'Select'}
                                                    type='client'
                                                    mode='basic'
                                                    onSelect={this.handleFile}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* : null
                                    } */}

                                    <div className="col-sm-6">
                                        <div className="form__form-group" >
                                            <label className="form__form-group-label text-capitalize">
                                                {tl['type'] ? tl['type'] : 'Type'}
                                            </label>
                                            <div className="row mx-0 mb-4">
                                                {this.props.isFromAddProperty ?
                                                    null :
                                                    <div className="form__form-group-field col">
                                                        <CheckBox
                                                            className='row propertyCheckBox'
                                                            id={'isBuyer'}
                                                            name={'isBuyer'}
                                                            defaultChecked={true}
                                                            checked={this.state.isBuyer}
                                                            onChange={() => this.onChange('isBuyer')}
                                                            label={tl['buyer'] ? tl['buyer'] : 'Buyer'}
                                                        />
                                                    </div>
                                                }
                                                <div className="form__form-group-field col">
                                                    <CheckBox
                                                        className='row propertyCheckBox'
                                                        id={'isSeller'}
                                                        name={'isSeller'}
                                                        checked={this.state.isSeller}
                                                        onChange={() => this.onChange('isSeller')}
                                                        label={tl['seller'] ? tl['seller'] : 'Seller'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div style={{ justifyContent: 'center' }}>
                                            {(this.state.pic) ?
                                                <img
                                                    alt='Profile.jpg'
                                                    src={`${config.imgUrl}client/${this.state.pic}`}
                                                    className='profileEditImage'
                                                />
                                                : this.state.newfilePath ?
                                                    <img
                                                        alt='Profile.jpg'
                                                        src={this.state.newfilePath}
                                                        className='profileEditImage'
                                                    />
                                                    : null
                                            }
                                        </div>
                                    </div>

                                    <ButtonToolbar className=" modal__footer footerData mb-1 ml-auto col-12">
                                        <Button
                                            color='primary'
                                            type="button"
                                            onClick={this.closeFormModal}
                                            className='mr-0 mb-2'
                                        >
                                            {tl['cancel'] ? tl['cancel'] : 'Cancel'}
                                        </Button>

                                        <Button
                                            color='primary'
                                            type="submit"
                                            className='ml-2 mb-2'
                                        >
                                            {this.props.formType && this.props.formType === 'edit' ?
                                                tl['update'] ? tl['update'] : 'Update'
                                                : tl['save'] ? tl['save'] : 'Save'
                                            }
                                        </Button>
                                    </ButtonToolbar>
                                </form>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal >
                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </div >
        );
    }
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ClientsFormModal = reduxForm({
    form: "ClientForm", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(ClientsFormModal);

// You have to connect() to any reducers that you wish to connect to yourself
ClientsFormModal = connect(
    state => ({
        initialValues: state.clientFormData.data // Pull initial values from concerned reducer Data : Can cross check form App/store.js
    }),
    { loadCF: loadClientFormData } // 'loadCF' is the binding function, and loadClientFormData is concerned Reducer import 
)(ClientsFormModal);

export default withTranslation('common')(ClientsFormModal)
