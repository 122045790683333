import React from 'react';
// import { Button, Input, FormGroup, Label, Col, FormFeedback, FormText } from 'reactstrap';
import { FileUpload } from 'primereact/fileupload';
import fetchMethodRequest from '../../../config/service';
// toaster message
import showToasterMessage from '../../../containers/UI/ToasterMessage/toasterMessage';
import Loader from '../../App/Loader';
// config file
export default class ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = { picture: [], fileName: '' };

    }

    componentWillUnmount() {
        this.props.onRef(null);
    }


    componentDidMount() {
        this.props.onRef(this);
    }

    OnUpload(event) {
    }

    onSelect = async (event) => {
        let pictures = [];
        if (this.props.multiple) {
            for (let i = 0; i < event.files.length; i++) {
                pictures.push(event.files[i])
            }
            await this.setState({ pictures: pictures, files: event.files });
        } else {
            await this.setState({ picture: event.files[0], files: event.files });
        }


        if (this.props.type && this.props.type === 'bulkUpload') {
            return;
        } else if (this.props.type && this.props.type === 'propertyPhotos') {
            if (event.files.length > 1) {
                let num = event.files.length
                this.props.input.name = (`+${num - 1}`);
            }
            await this.props.savePhoto(this.state.pictures)
        } else if (this.props.type && this.props.type === 'Client') {
            await this.props.savePhoto(this.state.pictures)
        } else if (this.props.type && this.props.type === 'profile') {
            await this.props.savePhoto(this.state.picture)
        } else {
            if (event) {
                this.UploadFileToServer(event.files);
            }
        }
    }

    UploadFileToServer() {
        this.setState({ isLoading: true });
        if (this.state.picture) {
            fetchMethodRequest('POST', this.props.url, this.state.picture, 'upload')
                .then(async (response) => {
                    if (response && response.respCode) {
                        if (response.fileName || response.fineName) {
                            let fileName = response.fileName ? response.fileName : response.fineName;
                            this.setState({ fileName: fileName });
                            // call function in parent
                            if (this.props.getFileName) {
                                this.props.getFileName(fileName);
                            }
                            if (this.props.type === 'profile') {
                                if (fileName) {
                                    this.props.input.onChange(fileName);
                                }
                            }
                            showToasterMessage(response.respMessage, 'success');
                        }
                        if (response.errorCode) {
                            showToasterMessage(response.errorCode, 'error');
                        }

                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    } else {
                        if (response.sucess && response.sucess.respMessage) {
                            showToasterMessage(response.sucess.respMessage, 'success');
                        } else if (response.failure && response.failure.errorMessage) {
                            showToasterMessage(response.failure.errorMessage, 'error');
                        }
                        if (this.props.close) {
                            this.props.close();
                        }
                    }
                    this.setState({ isLoading: false });
                }).catch((err) => {
                    return err;
                });
        } else {
            return '';
        }
    }


    render() {
        return (
            <div>
                <Loader loader={this.state.isLoading} />
                <FileUpload
                    onSelect={this.onSelect}
                    mode="basic" name="file"
                    // uurl="./upload.php"
                    maxFileSize={this.props.maxFileSize ? this.props.maxFileSize : 100000}
                    auto={false}
                    chooseLabel={this.props.chooseLabel ? this.props.chooseLabel : 'Choose File'}
                    accept={this.props.acceptType}
                    multiple={this.props.multiple}
                    cancelLabel={this.props.cancelLabel ? this.props.cancelLabel : 'Cancel'}
                    uploadLabel={this.props.uploadLabel ? this.props.uploadLabel : 'Upload'}
                />
            </div>
        )
    }
}
// 1000000 maxTej