import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';

//config
import fetch from '../../../config/service';
import configMessages from '../../../config/configMessages';

// Fields
import DefaultInput from '../../../shared/components/form/DefaultInput';

// Redux
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { connect } from 'react-redux';
import validate from '../../Validations/validate';

// UI components
import Loader from '../../App/Loader';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Socket
import socket from '../../../config/Socket';

// i18 Translation Provider
import { withTranslation } from 'react-i18next';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)

class LogInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoginDisabled: false,
      isLoading: false,
    };
  }

  // Submit handler when click on signin
  submit = (values) => {
    if (values.email && values.password) {
      this.setState({
        isLoginDisabled: true,
        isLoading: true
      })
      this.handleLoginUser(values)
    }
  }

  // Signin Handler
  handleLoginUser = (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      entityType: "user",
    };
    localStorage.setItem('loginBody', JSON.stringify(userBody));

    return fetch('POST', 'auth/login', userBody)
      .then(async (response) => {
        if (response && response.respCode && response.respCode === 200) {
          this.setState({
            isLoading: true
          });
          let userDetails = response.details;
          if (userDetails && userDetails._id) {
            if (response.accessToken) {
              let tokenInfo = {
                accessToken: response.accessToken,
                refreshToken: response.refreshToken,
                tokenExpires: response.tokenExpires,
              };
              userDetails = { ...userDetails, ...tokenInfo };
              // save user credentials in storage
              localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
            }

            socket.emit('userConnected', { userId: userDetails._id });

            // save user permissions in storage
            if (response.permissions) {
              let permissions = response.permissions;
              localStorage.setItem('rolePermissions', JSON.stringify(permissions));
            }
          }
          showToasterMessage(response.respMessage, 'success');

          this.setState({
            isLoginSuccess: true,
            role: userDetails.role
          });
        } else if (response && response.errorMessage) {
          setTimeout(() => this.setState({
            isLoginDisabled: false,
            isLoading: false
          }), 2500);
          showToasterMessage(response.errorMessage, 'error');
          return true;
        }
      }).catch((err) => {
        return err;
      });
  }

  render() {
    const { handleSubmit, tl, t } = this.props;
    return (
      <form className="form" onSubmit={handleSubmit(this.submit)}>
        <Loader loader={this.state.isLoading} />
        <div className="mb-2">
          <h4>
            {tl && tl['agentLogin'] ? tl['agentLogin'] : 'Agent Login'}
          </h4>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {tl && tl['email'] ? tl['email'] : 'Email'} *
          </span>
          <div className="form__form-group-field">
            <Field
              name='email'
              component={DefaultInput}
              type="email"
              placeholder={tl && tl['email'] ? tl['email'] : 'Email'}
              validate={required}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {tl && tl['password'] ? tl['password'] : 'Password'} *
          </span>
          <div className="form__form-group-field">
            <Field
              name='password'
              component={DefaultInput}
              type="password"
              placeholder={tl && tl['password'] ? tl['password'] : 'Password'}
              validate={required}
            />
          </div>
        </div>

        <div className="form__form-group account__forgot-password text-right" >
          <Link
            className='p-0 pl-1'
            to={{ pathname: 'forgot_password', state: { tl: tl } }}
          >
            {tl && tl['forgotPassword'] ? tl['forgotPassword'] : 'Forgot Password'}{' ?'}
          </Link>
        </div>

        {/* Sign In Button*/}
        <div className="col-12 px-0 text-right">
          <Button
            className="mb-0 mt-2"
            color="primary"
            disabled={this.state.isLoginDisabled}
          >
            {tl && tl['signIn'] ? tl['signIn'] : 'Sign In'}
          </Button>
        </div>

        {/* Link To Registration Screen */}
        <div className="col-12 px-0 account__forgot-password pt-2">
          {tl && tl['notYetRegistered'] ? tl['notYetRegistered'] : 'Not Yet Registered'},
          <Link
            className='p-0 pl-1'
            to={{ pathname: 'register', state: { tl: tl } }}
          >
            {tl && tl['clickHere'] ? tl['clickHere'] : 'Click Here'}
          </Link>
        </div>

        {/* Redirect to #My_Properties after successful login */}
        {this.state.isLoginSuccess ?
          <Redirect
            to={{ pathname: '/dashboard', state: { tl: tl } }}
          /> :
          null
        }
      </form>
    );
  }
}

LogInForm = reduxForm({
  form: 'log_in_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(LogInForm);

// You have to connect() to any reducers that you wish to connect to yourself
LogInForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(LogInForm);

export default withTranslation('common')(LogInForm);